import { Course } from "../../backend/courses";


function CongressInfo() {

    return (
        <div className="details-requirement">
        <div className="requirement-content">
            <br></br><br></br>
            <div className="description" style={{"display": "flex", "flexDirection": "column", "gap": "15px"}}>
            <li>2022 1er Congreso internacional de Cirugía Fetal, Los Cabos, Baja California Sur, México, <a href="https://videospruebamfm.s3.us-east-2.amazonaws.com/pdf/Congreso2022.pdf" style={{"fontWeight": "700", "color": "#003C89"}}>Programa</a></li>
            <li>2023 II Congreso internacional de Cirugía Fetal, Los Cabos, Nuevo Vallarta, México, <a href="https://videospruebamfm.s3.us-east-2.amazonaws.com/pdf/Congreso2023.pdf" style={{"fontWeight": "700", "color": "#003C89"}}>Programa</a></li>
            </div>
        </div>
    </div>
    );
}

export default CongressInfo;