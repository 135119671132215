import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useEffect } from "react";
import { callbackify } from "util";
import GoogleMap from "../components/contact/GoogleMap";
import Loading from "../components/_main/Loading";
import "./styles/Contact.css"

function Contact() {
    const call = (tel: string) => {
        var call_app = window.open("tel:" + tel, "_self");
        call_app!.close();
    }

    useEffect(() => {
        window.document.title = "Contacto"
    }, [])

    return (
        <div className="Contact-area">
            <div>
                <Wrapper apiKey={"AIzaSyBdlxShNQWpYGFKf0moV-aEBqMN5DyRVPs"} render={render}>
                    <GoogleMap style={{ "height": "640px", "width": "calc(100% - 30px)", "marginLeft": "15px", "marginTop": "15px" }} />
                </Wrapper>
            </div>
            <div className="hospital-info-container">
                <br></br><br></br><br></br>
                <h2 className="direc-title">Dirección</h2>
                <div className="info-card-hospital">
                    <div className="hospital-info">
                        <div className="letter-hospital">A</div>
                        <div className="hospital-text">
                            <p onClick={() => window.open("https://goo.gl/maps/ywYnDSHMonnMibt1A", "_blank")} className="hospital-name">Hospital Ángeles Centro Sur</p>
                            <p className="normal-text-hospital-info">
                                Boulevard Bernardo Quintana Arrioja 9670,<br />
                                Blvd. Centro Sur 9800, Centro Sur, 76090<br />
                                Santiago de Querétaro, Qro.<br />
                                México<br />
                                Consultorio 1230<br />
                                Piso 12<br />
                            </p>
                            <p className="normal-text-hospital-info">Horario de atención telefónica 9:00am - 8:00pm </p>
                            <br></br>
                            <p onClick={() => call("+524424489371")} className="normal-text-hospital-info hospital-phone" style={{ "fontWeight": "bold" }}>
                                <i className="fa fa-phone" aria-hidden="true" />
                                (442) 448 93 71
                            </p>
                            <p onClick={() => window.open("https://wa.me/524424489371")} className="normal-text-hospital-info hospital-phone" style={{ "fontWeight": "bold" }}><i className="fa fa-whatsapp" aria-hidden="true" />(442) 448 93 71</p>
                            <p onClick={() => call("+524421957382")} className="normal-text-hospital-info hospital-phone" style={{ "fontWeight": "bold" }}>
                                <i className="fa fa-phone" aria-hidden="true" />
                                (442) 195 73 82
                            </p>
                            <p onClick={() => call("+524421957014")} className="normal-text-hospital-info hospital-phone" style={{ "fontWeight": "bold" }}>
                                <i className="fa fa-phone" aria-hidden="true" />
                                (442) 195 70 14
                            </p>
                        </div>
                    </div>
                    <div className="hospital-info">
                        <div className="letter-hospital">B</div>
                        <div className="hospital-text">
                            <p className="hospital-name">Hospital Angeles</p>
                            <p className="normal-text-hospital-info">
                                Av. Montaña Monarca 331-Norte<br />
                                Torre B, Consultorio 107-B<br />
                                Col. Desarrollo Montaña Monarca<br />
                                CP 38350<br />
                                Morelia, Mich.<br />
                                Mexico
                            </p>
                            <br></br>
                            <p onClick={() => call("+524434923305")} className="normal-text-hospital-info hospital-phone" style={{ "fontWeight": "bold" }}>
                                <i className="fa fa-phone" aria-hidden="true" />
                                (443) 492 33 05
                            </p>
                            <p className="normal-text-hospital-info">contacto@medicinafetalmexico.com</p>
                        </div>
                    </div>
                    <div className="hospital-info">
                        <div className="letter-hospital">C</div>
                        <div className="hospital-text">
                            <p className="hospital-name">Instituto Medicina Fetal México</p>
                            <p className="normal-text-hospital-info">
                                San Gabriel #333<br />
                                Col. Chapalita<br />
                                CP 45040<br />
                                Guadalajara, Jal.<br />
                                México
                            </p>
                            <p className="normal-text-hospital-info">Horario de atención telefónica 9:00am - 8:00pm </p>
                            <br></br>
                            <p onClick={() => call("+523328152200")} className="normal-text-hospital-info hospital-phone" style={{ "fontWeight": "bold" }}>
                                <i className="fa fa-phone" aria-hidden="true" />
                                Recepción. (33) 281 522 00
                            </p>
                            <p onClick={() => call("+5233434115520")} className="normal-text-hospital-info hospital-phone" style={{ "fontWeight": "bold" }}>
                                <i className="fa fa-phone" aria-hidden="true" />
                                Consulta. (33) 434 155 20
                            </p>
                            <p onClick={() => call("+52333343415525")} className="normal-text-hospital-info hospital-phone" style={{ "fontWeight": "bold" }}>
                                <i className="fa fa-phone" aria-hidden="true" />
                                Cirugía. (33) 434 155 25
                            </p>
                            <p onClick={() => window.open("https://wa.me/523328152200")} className="normal-text-hospital-info hospital-phone" style={{ "fontWeight": "bold" }}><i className="fa fa-whatsapp" aria-hidden="true" />(33) 281 522 00 </p>
                            <p className="normal-text-hospital-info">contactogdl@medicinafetalmexico.com</p>
                        </div>
                    </div>
                </div>
                {/*<h2 className="direc-title">Envíanos un mensaje</h2>
                <p style={{ "textAlign": "center", "fontSize": "15px", "animation": "3s anim-lineUp ease-out" }}>Un especialista se pondrá en contacto contigo.</p>
                <br></br>
                <div className="form-content">
                    <form className="form-contact">
                        <input className="contact-input" type="text" placeholder="Nombre (requerido)" name="nombre"></input>
                        <input className="contact-input" type="text" placeholder="Correo electrónico (requerido)" name="nombre"></input>
                        <input className="contact-input" type="text" placeholder="Asunto" name="nombre"></input>
                        <input className="contact-input" type="text" placeholder="Mensaje" name="nombre"></input>
                        <div className="contact-btn">ENVIAR</div>
                    </form>
                </div>*/}
            </div>
        </div>
    );
}

const render = (status: Status) => {
    return <Loading />;
};

export default Contact;