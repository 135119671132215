import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProfessors, Professor } from "../backend/professors";
import Specialist from "../components/specialists/Specialist";
import SpecialistsGrid from "../components/specialists/SpecialistsGrid";
import Loading from "../components/_main/Loading";

function Investigation() {
  const [visible2008, setVisible2008] = useState(false);
  const [visible2009, setVisible2009] = useState(false);
  const [visible2010, setVisible2010] = useState(false);
  const [visible2011, setVisible2011] = useState(false);
  const [visible2012, setVisible2012] = useState(false);
  const [visible2013, setVisible2013] = useState(false);
  const [visible2014, setVisible2014] = useState(false);
  const [visible2015, setVisible2015] = useState(false);
  const [visible2016, setVisible2016] = useState(false);
  const [visible2017, setVisible2017] = useState(false);
  const [visible2018, setVisible2018] = useState(false);
  const [visible2019, setVisible2019] = useState(false);
  const [visible2020, setVisible2020] = useState(false);
  const [visible2021, setVisible2021] = useState(false);
  const [visibleAll, setVisibleAll] = useState(true);
  const [visible, setVisible] = useState(false);

  useEffect(() => {

  },);
  const handleToggle2008 = () => {
    setVisible2008(true);
    setVisible2009(false);
    setVisible2010(false);
    setVisible2011(false);
    setVisible2012(false);
    setVisible2013(false);
    setVisible2014(false);
    setVisible2015(false);
    setVisible2016(false);
    setVisible2017(false);
    setVisible2018(false);
    setVisible2019(false);
    setVisible2020(false);
    setVisible2021(false);
    setVisibleAll(false);
  };
  const handleToggle2009 = () => {
    setVisible2008(false);
    setVisible2009(true);
    setVisible2010(false);
    setVisible2011(false);
    setVisible2012(false);
    setVisible2013(false);
    setVisible2014(false);
    setVisible2015(false);
    setVisible2016(false);
    setVisible2017(false);
    setVisible2018(false);
    setVisible2019(false);
    setVisible2020(false);
    setVisible2021(false);
    setVisibleAll(false);
  };
  const handleToggle2010 = () => {
    setVisible2008(false);
    setVisible2009(false);
    setVisible2010(true);
    setVisible2011(false);
    setVisible2012(false);
    setVisible2013(false);
    setVisible2014(false);
    setVisible2015(false);
    setVisible2016(false);
    setVisible2017(false);
    setVisible2018(false);
    setVisible2019(false);
    setVisible2020(false);
    setVisible2021(false);
    setVisibleAll(false);
  };
  const handleToggle2011 = () => {
    setVisible2008(false);
    setVisible2009(false);
    setVisible2010(false);
    setVisible2011(true);
    setVisible2012(false);
    setVisible2013(false);
    setVisible2014(false);
    setVisible2015(false);
    setVisible2016(false);
    setVisible2017(false);
    setVisible2018(false);
    setVisible2019(false);
    setVisible2020(false);
    setVisible2021(false);
    setVisibleAll(false);
  };
  const handleToggle2012 = () => {
    setVisible2008(false);
    setVisible2009(false);
    setVisible2010(false);
    setVisible2011(false);
    setVisible2012(true);
    setVisible2013(false);
    setVisible2014(false);
    setVisible2015(false);
    setVisible2016(false);
    setVisible2017(false);
    setVisible2018(false);
    setVisible2019(false);
    setVisible2020(false);
    setVisible2021(false);
    setVisibleAll(false);
  };
  const handleToggle2013 = () => {
    setVisible2008(false);
    setVisible2009(false);
    setVisible2010(false);
    setVisible2011(false);
    setVisible2012(false);
    setVisible2013(true);
    setVisible2014(false);
    setVisible2015(false);
    setVisible2016(false);
    setVisible2017(false);
    setVisible2018(false);
    setVisible2019(false);
    setVisible2020(false);
    setVisible2021(false);
    setVisibleAll(false);
  };
  const handleToggle2014 = () => {
    setVisible2008(false);
    setVisible2009(false);
    setVisible2010(false);
    setVisible2011(false);
    setVisible2012(false);
    setVisible2013(false);
    setVisible2014(true);
    setVisible2015(false);
    setVisible2016(false);
    setVisible2017(false);
    setVisible2018(false);
    setVisible2019(false);
    setVisible2020(false);
    setVisible2021(false);
    setVisibleAll(false);
  };
  const handleToggle2015 = () => {
    setVisible2008(false);
    setVisible2009(false);
    setVisible2010(false);
    setVisible2011(false);
    setVisible2012(false);
    setVisible2013(false);
    setVisible2014(false);
    setVisible2015(true);
    setVisible2016(false);
    setVisible2017(false);
    setVisible2018(false);
    setVisible2019(false);
    setVisible2020(false);
    setVisible2021(false);
    setVisibleAll(false);
  };
  const handleToggle2016 = () => {
    setVisible2008(false);
    setVisible2009(false);
    setVisible2010(false);
    setVisible2011(false);
    setVisible2012(false);
    setVisible2013(false);
    setVisible2014(false);
    setVisible2015(false);
    setVisible2016(true);
    setVisible2017(false);
    setVisible2018(false);
    setVisible2019(false);
    setVisible2020(false);
    setVisible2021(false);
    setVisibleAll(false);
  };
  const handleToggle2017 = () => {
    setVisible2008(false);
    setVisible2009(false);
    setVisible2010(false);
    setVisible2011(false);
    setVisible2012(false);
    setVisible2013(false);
    setVisible2014(false);
    setVisible2015(false);
    setVisible2016(false);
    setVisible2017(true);
    setVisible2018(false);
    setVisible2019(false);
    setVisible2020(false);
    setVisible2021(false);
    setVisibleAll(false);
  };
  const handleToggle2018 = () => {
    setVisible2008(false);
    setVisible2009(false);
    setVisible2010(false);
    setVisible2011(false);
    setVisible2012(false);
    setVisible2013(false);
    setVisible2014(false);
    setVisible2015(false);
    setVisible2016(false);
    setVisible2017(false);
    setVisible2018(true);
    setVisible2019(false);
    setVisible2020(false);
    setVisible2021(false);
    setVisibleAll(false);
  };
  const handleToggle2019 = () => {
    setVisible2008(false);
    setVisible2009(false);
    setVisible2010(false);
    setVisible2011(false);
    setVisible2012(false);
    setVisible2013(false);
    setVisible2014(false);
    setVisible2015(false);
    setVisible2016(false);
    setVisible2017(false);
    setVisible2018(false);
    setVisible2019(true);
    setVisible2020(false);
    setVisible2021(false);
    setVisibleAll(false);
  };
  const handleToggle2020 = () => {
    setVisible2008(false);
    setVisible2009(false);
    setVisible2010(false);
    setVisible2011(false);
    setVisible2012(false);
    setVisible2013(false);
    setVisible2014(false);
    setVisible2015(false);
    setVisible2016(false);
    setVisible2017(false);
    setVisible2018(false);
    setVisible2019(false);
    setVisible2020(true);
    setVisible2021(false);
    setVisibleAll(false);
  };
  const handleToggle2021 = () => {
    setVisible2008(false);
    setVisible2009(false);
    setVisible2010(false);
    setVisible2011(false);
    setVisible2012(false);
    setVisible2013(false);
    setVisible2014(false);
    setVisible2015(false);
    setVisible2016(false);
    setVisible2017(false);
    setVisible2018(false);
    setVisible2019(false);
    setVisible2020(false);
    setVisible2021(true);
    setVisibleAll(false);
  };
  const handleToggleAll = () => {
    setVisible2008(true);
    setVisible2009(true);
    setVisible2010(true);
    setVisible2011(true);
    setVisible2012(true);
    setVisible2013(true);
    setVisible2014(true);
    setVisible2015(true);
    setVisible2016(true);
    setVisible2017(true);
    setVisible2018(true);
    setVisible2019(true);
    setVisible2020(true);
    setVisible2021(true);
    setVisibleAll(true);
  };
  return (
    <>
      <section className="bannerSimple">
        <div className="fullBgSection">
          <picture>
            <source src="img/2.jpg" type="image/webp" />
            <source src="img/2.jpg" type="image/jpeg" />
            <img
              src="img/2.jpg"
              width="2210"
              height="800"
              alt="Líneas de Investigación"
            />
          </picture>
        </div>
        <div className="container">
          <div className="banSimpleText">
            <h2 data-wow-delay="0.25s" className="headerComp wow fadeInDown">
              <strong>Líneas de investigación</strong>
            </h2>
            <p data-wow-delay="0.25s" className="wow fadeInUp">
              Medicina Fetal México colabora en la generación de nuevo
              conocimiento en el área de Medicina Fetal. Nuestras líneas de
              investigación actual son las siguientes:
            </p>
          </div>
        </div>
      </section>
      <section className="gridBoxWithImg">
        <div className="container">
          <div className="gBoxWithImgWrap extended">
            <div className="gbWithImgLeft">
              <div data-wow-delay="0.5s" className="gbWithImgGrid wow fadeIn">
                <div className="gbWithImgEle">
                  <div className="gbWithImgEleBG">
                    <picture>
                      <source src="img/bg-card-box.png" type="image/webp" />
                      <source src="img/bg-card-box.png" type="image/png" />
                      <img
                        src="img/bg-card-box.png"
                        width="317"
                        height="231"
                        alt=""
                      />
                    </picture>
                  </div>
                  <div className="gbWithImgEleText">
                    <p>
                      1. <span>Prematuridad</span>
                    </p>
                    <p className="desc">
                      Prevención durante el embarazo y seguimiento neurológico
                      infantil.
                    </p>
                  </div>
                </div>
                <div data-wow-delay="0.65s" className="gbWithImgEle wow fadeIn">
                  <div className="gbWithImgEleBG">
                    <picture>
                      <source src="img/bg-card-box.png" type="image/webp" />
                      <source src="img/bg-card-box.png" type="image/png" />
                      <img
                        src="img/bg-card-box.png"
                        width="317"
                        height="231"
                        alt=""
                      />
                    </picture>
                  </div>
                  <div className="gbWithImgEleText">
                    <p>
                      2. <span>Restricción de Crecimiento</span>
                    </p>
                    <p className="desc">
                      Intrauterino y neurodesarrollo postnatal.
                    </p>
                  </div>
                </div>
                <div data-wow-delay="0.8s" className="gbWithImgEle wow fadeIn">
                  <div className="gbWithImgEleBG">
                    <picture>
                      <source src="img/bg-card-box.png" type="image/webp" />
                      <source src="img/bg-card-box.png" type="image/png" />
                      <img
                        src="img/bg-card-box.png"
                        width="317"
                        height="231"
                        alt=""
                      />
                    </picture>
                  </div>
                  <div className="gbWithImgEleText">
                    <p>
                      3. <span>Cirugía Fetal</span>
                    </p>
                    <p className="desc">
                      Desarrollo de nuevas técnicas de cirugía fetal de mínima
                      invasión.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="publicationsMenu noPadd">
        <div className="container">
          <div className="publiMenuWrap">
            <div className="publiMenuLeft">
              <div className="publiMenuBG">
                <picture>
                  <source src="img/bg-card-box.png" type="image/webp" />
                  <source src="img/bg-card-box.png" type="image/png" />
                  <img
                    src="img/bg-card-box.png"
                    width="317"
                    height="231"
                    alt=""
                  />
                </picture>
              </div>
              <div className="publiMenuLeftText">
                <h2 data-wow-delay="0.25s" className="h3 wow fadeInDown">
                  Publicaciones médicas
                </h2>
                <p data-wow-delay="0.5s" className="wow fadeInUp">
                  Mejoramos algunas técnicas quirúrgicas y realizamos 2
                  procedimientos nuevos en el mundo:
                </p>
              </div>
            </div>
            <div className="publiMenuRight">
              <div className="publiMenuBG">
                <picture>
                  <source src="img/bg-card-box.png" type="image/webp" />
                  <source src="img/bg-card-box.png" type="image/png" />
                  <img
                    src="img/bg-card-box.png"
                    width="317"
                    height="231"
                    alt=""
                  />
                </picture>
              </div>
              <div className="publiMenuRightText">
                <ul>
                  <li><span data-hash="y2008" onClick={handleToggle2008}>2008</span></li>
                  <li><span data-hash="y2009" onClick={handleToggle2009}>2009</span></li>
                  <li><span data-hash="y2010" onClick={handleToggle2010}>2010</span></li>
                  <li><span data-hash="y2011" onClick={handleToggle2011}>2011</span></li>
                  <li><span data-hash="y2012" onClick={handleToggle2012}>2012</span></li>
                  <li><span data-hash="y2013" onClick={handleToggle2013}>2013</span></li>
                  <li><span data-hash="y2014" onClick={handleToggle2014}>2014</span></li>
                  <li><span data-hash="y2015" onClick={handleToggle2015}>2015</span></li>
                  <li><span data-hash="y2016" onClick={handleToggle2016}>2016</span></li>
                  <li><span data-hash="y2018" onClick={handleToggle2017}>2017</span></li>
                  <li><span data-hash="y2012" onClick={handleToggle2018}>2018</span></li>
                  <li><span data-hash="y2019" onClick={handleToggle2019}>2019</span></li>
                  <li><span data-hash="y2020" onClick={handleToggle2020}>2020</span></li>
                  <li><span data-hash="y2021" onClick={handleToggle2021}>2021</span></li>
                  <li><span data-hash="all" className="active" onClick={handleToggleAll}>Todas</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="publicationsList">
        <div className="container">
          <h2 data-wow-delay="0.25s" className="h3 headerComp wow fadeInDown"><strong>Publicaciones</strong></h2>
          <div className="publiListWrap">
            <div className="publiListWrapOver">
              <ul>
                {
                  visibleAll ?
                    <>
                      <li className="all y2021"><a href="https://www.tandfonline.com/doi/abs/10.1080/14767058.2021.1892062" target="_blank">1. Ahn H, Cruz-Martinez R, Hernandez-Andrade E. Variation in the uterine arteries Doppler parameters when obtained transvaginally or transabdominally at different sampling locations. J Matern Fetal Neonatal Med. 2021 Mar 3:1-8. doi: 10.1080/14767058.2021.1892062.</a></li>
                      <li className="all y2021"><a href="https://obgyn.onlinelibrary.wiley.com/doi/abs/10.1002/pd.5946" target="_blank">2. Cruz-Martínez R, Martínez-Rodríguez M, Gámez-Varela A, Luna-García J, López-Briones H, Chávez-González E, Villalobos-Gómez R, Juárez-Martínez I. Fetoscopic urethral meatotomy in fetuses with lower urinary tract obstruction by congenital megalourethra. Prenat Diagn. 2021 Apr 1. doi: 10.1002/pd.5946.</a></li>
                      <li className="all y2021"><a href="https://pubmed.ncbi.nlm.nih.gov/33677452/" target="_blank">3. Cruz-Martínez R, Sosa Sosa C, Martínez-Rodríguez M, Gámez-Varela A, Villalobos-Gómez R, López-Briones H, Luna-García J, Chávez-González E, Juárez-Martínez I. Single Uterine Access for Bilateral Pleuroamniotic Shunting in Fetuses with Severe Hydrothorax by an Internal Rotational Maneuver: Feasibility and Outcomes between Successful and Failed Procedures. Fetal Diagn Ther. 2021;48(3):209-216. doi: 10.1159/000513748.</a></li>
                      <li className="all y2021"><a href="https://www.ajog.org/article/S0002-9378(21)00116-2/fulltext" target="_blank">4. Cruz-Martínez R, Díaz R, Martínez-Rodríguez M. Early release of amniotic bands using a 1.0-mm fetoscope. Am J Obstet Gynecol. 2021 Feb 19:S0002-9378(21)00116-2. doi: 10.1016/j.ajog.2021.02.019.</a></li>
                      <li className="all y2021"><a href="https://www.karger.com/Article/Abstract/514912" target="_blank">5. Gámez-Varela A, Martínez-Rodríguez M, López-Briones H, Luna-García J, Chávez-González E, Villalobos-Gómez R, Hernandez-Andrade E, Cruz-Martínez R. Preoperative Cervical Length Predicts the Risk of Delivery within One Week after Pleuroamniotic Shunt in Fetuses with Severe Hydrothorax. Fetal Diagn Ther. 2021;48(4):297-303. doi: 10.1159/000514912.</a></li>
                      <li className="all y2021"><a href="https://pubmed.ncbi.nlm.nih.gov/33582666/" target="_blank">6. Cruz-Martínez R, Chavelas-Ochoa F, Martínez-Rodríguez M, Aguilar-Vidales K, Gámez-Varela A, Luna-García J, López-Briones H, Chávez-Vega J, Pérez-Calatayud ÁA, Díaz-Carrillo MA, Ahumada-Angulo E, Castelo-Vargas A, Chávez-González E, Juárez-Martínez I, Villalobos-Gómez R, Rebolledo-Fernández C. Open Fetal Microneurosurgery for Intrauterine Spina Bifida Repair. Fetal Diagn Ther. 2021;48(3):163-173. doi: 10.1159/000513311.</a></li>
                      <li className="all y2021"><a href="https://obgyn.onlinelibrary.wiley.com/doi/abs/10.1002/pd.5806" target="_blank">7. Cruz-Lemini M, Nieto-Castro B, Luna-Garcia J, Juarez-Martinez I, Martínez-Rivera M, Bermudez-Rojas ML, Rebolledo-Fernández C, Cruz-Martinez R. Prenatal diagnosis of congenital heart defects: experience of the first Fetal Cardiology Unit in Mexico. J Matern Fetal Neonatal Med. 2021 May;34(10):1529-1534. doi: 10.1080/14767058.2019.1638905.</a></li>
                      <li className="all y2020"><a href="#" target="_blank">8. Rogelio Cruz-Martínez, Alma Gámez-Varela, Miguel Martínez-Rodríguez, Jonahtan Luna-García, Hugo López-Briones, Eréndira Chavez-González, Israel Juárez-Martínez, Areli Razo-Nava, Rosa Villalobos-Gómez, César Favela-Heredia. Prenatal diagnosis of laryngo-tracheo-esophageal anomalies in fetuses with congenital diaphragmatic hernia by ultrasound evaluation of the vocal cords and fetal laryngoesophagoscopy. Prenatal Diagnosis, DOI:10.1002/pd.5806, 10.1002/pd.5806.Año 2020.</a></li>
                      <li className="all y2020"><a href="https://pubmed.ncbi.nlm.nih.gov/32663828/" target="_blank">9. Marta Rial-Crestelo, Laura Garcia-Otero, Annalisa Cancemi, Mariella Giannone, Elena Escazzocchio, Alex Biterna, Elena Ferriols, Jose Luis Hernandez, Marek Lubusky, Veronika Kolarova, Ladislav Krofta, Jiri Vojtêch , Eyal Zohav, Karina Krajden, Rogelio Cruz-Martinez , Monica Cruz-Lemini, Mauro Parra-Cordero, Pamela Socias, Fàtima Crispi, Marta Lopez,Eduard Gratacós, Francesc Figueras, Ratio 37 study group. Prescriptive Reference Standards of Third-Trimester Cerebroplacental Ratio and Its Physiological Determinants.Fetal Diagnosis and therapy, DOI: 10.1159/000508366, Fetal Diagnosis and Therapy,Karger. Año 2020.</a></li>
                      <li className="all y2020"><a href="https://pubmed.ncbi.nlm.nih.gov/32959414/" target="_blank">10. R Cruz-Martínez , H López-Briones , J Luna-García , M Martínez-Rodriguez , A Gámez-Varela, E Chávez-González,R Villalobos-Gómez. Incidence and survival outcomes of monochorionic diamniotic twin pregnancies with twin-to-twin transfusion syndrome presenting without amniotic fluid discordance due to spontaneous septostomy and treated with fetoscopy.Ultrasound Obstet Gynecol, DOI: 10.1002/uog.23129. Año 2020.</a></li>
                      <li className="all y2020"><a href="https://pubmed.ncbi.nlm.nih.gov/32798234/" target="_blank">11. R Cruz-Martínez, A Gámez-Varela, M Cruz-Lemini, M Martínez-Rodríguez, J Luna-García, H López-Briones, F Chavelas-Ochoa,E Chávez-González, K Aguilar-Vidales, J Chávez-Vega, A Castelo-Vargas, P Rivera-Carrillo, E Hernández-Andrade.Doppler changes in the umbilical artery, middle cerebral artery, cerebroplacental ratio and ductus venosus during open fetal microneurosurgery for intrauterine myelomeningocele repair. Ultrasound Obstet Gynecol. DOI: 10.1002/uog.22177, Año 2020.</a></li>
                      <li className="all y2020"><a href="https://obgyn.onlinelibrary.wiley.com/doi/abs/10.1002/uog.23121" target="_blank">12. Villalobos- Gomez Rosa, J Luna-Garcia, H López-Briones, Chavez- Gonzalez, Erendira, M Martínez-Rodríguez, A Gámez-Varela, Meza Barrera, Ruben, I Juárez-Martínez, R Cruz-Martínez. Incidence and outcomes of fetuses with severe hydrothorax and ductus venosus agenesis treated with thoracoamniotic shunt. Ultrasound Obstet Gynecol; https://doi.org/10.1002/uog.23121 Año, 2020.</a></li>
                      <li className="all y2020"><a href="https://pubmed.ncbi.nlm.nih.gov/32798234/" target="_blank">13. N Docheva , R Cruz-Martinez , E Hernandez-Andrade, The vaginal azygos artery: a common unrecognized finding during transvaginal ultrasound. Ultrasound Obstet Gynecol. DOI: 10.1002/uog.22098,Año 2020.</a></li>
                      <li className="all y2020"><a href="https://obgyn.onlinelibrary.wiley.com/doi/full/10.1002/uog.20370" target="_blank">14. M Rial-Crestelo , J Morales-Roselló , E Hernández-Andrade , F Prefumo 4, D Oros , D Caffici , A Sotiriadis , E Zohav , R Cruz-Martinez , M Parra-Cordero , M Lubusky , M Kacerovsky , F Figueras. Quality assessment of fetal middle cerebral and umbilical artery Doppler images using an objective scale within an international randomized controlled trial. Ultrasound Obstet Gynecol. Ultrasound Obstet Gynecol; DOI: 10.1002/uog.20370,Año 2020.</a></li>
                      <li className="all y2020"><a href="https://obgyn.onlinelibrary.wiley.com/doi/abs/10.1002/uog.21993" target="_blank">15. R Cruz-Martínez , M Martínez-Rodríguez , A Gámez-Varela, B Nieto-Castro , J Luna-García, I Juárez-Martínez , H López-Briones , R Guadarrama-Mora , J Torres-Torres , F Coronel-Cruz , D Ibarra-Rios , R Ordorica-Flores , J Nieto-Zermeño. Survival outcome in severe left-sided congenital diaphragmatic hernia with and without fetal endoscopic tracheal occlusion in a country with suboptimal neonatal management. Ultrasound Obstet Gynecol; DOI: 10.1002/uog.21993.Año 2020.</a></li>
                      <li className="all y2019"><a href="#" target="_blank">16. Rogelio Cruz‐Martínez,Adolfo Etchegaray ,Saulo Molina‐Giraldo ,Belen Nieto‐Castro ,Enrique Gil Guevara ,Joaquin Bustillos ,Miguel Martínez‐Rodríguez ,Alma Gámez‐Varela ,Daniel Saldivar‐Rodríguez ,Erendira Chávez‐González ,Rodolfo Keller ,Ricardo Russo ,Eduardo Yepez‐García ,Fausto Coronel‐Cruz ,Johnatan Torres‐Torres ,Alejandro Rojas‐Macedo ,Daniel Ibarra‐Ríos ,Ricardo Ordorica‐Flores ,Jaime Nieto‐Zermeño ,Manuel Alcocer‐Alcocer on behalf of the Latin American CDH Study Group; A multicentre study to predict neonatal survival according to lung‐to‐head ratio and liver herniation in fetuses with left congenital diaphragmatic hernia (CDH): Hidden mortality from the Latin American CDH Study Group Registry. Prenatal Diagnosis, https://doi.org/10.1002/pd.5458.Año,2019.</a></li>
                      <li className="all y2019"><a href="#" target="_blank">17. Mónica Cruz‐Lemini, Monica Alvarado‐Guaman0., Belen Nieto‐Castro, Jonathan Luna‐Garcia, Miguel Martínez‐Rodríguez, Israel Juarez‐Martínez, Alexis Palacios‐Macedo, Rogelio Cruz‐Martínez. Outcomes of hypoplastic left heart syndrome and fetal aortic valvuloplasty in a country with suboptimal postnatal management. Prenatal Diagnosis, https://doi.org/10.1002/pd.5470. Año 2019.</a></li>
                      <li className="all y2019"><a href="#" target="_blank">18. Marta Rial-Crestelo ; Jose Morales-Roselló ; Edgar Hernández-Andrade ; Federico Prefumo ; Daniel Oros ; Daniel Caffici ; Alexandros Sotiriadis ; Eyal Zohav ; Rogelio CruzMartinez ; Mauro Parra-Cordero; Marek Lubuski; Marian Kacerovsky; Francesc Figueras. Evaluation of the quality and reliability of middle cerebral artery and umbilical artery Doppler images within an international randomized controlled trial. Accepted Article.</a></li>
                      <li className="all y2019"><a href="https://pubmed.ncbi.nlm.nih.gov/31257961/" target="_blank">19. Monica Cruz-Lemini, Belen Nieto-Castro, Jonahtan Luna-Garcia, Israel Juarez-Martinez, Magdalena Martínez-Rivera, Ma de la Luz Bermudez-Rojas, Carlos Rebolledo-Fernández &amp; Rogelio Cruz-Martínez. Prenatal diagnosis of congenital heart defects: experience of the first Fetal Cardiology Unit in Mexico. The Journal of Maternal-Fetal &amp; Neonatal Medicine, https://doi.org/10.1080/14767058.2019.1638905. Año, 2019.</a></li>
                      <li className="all y2019"><a href="#" target="_blank">20. R Cruz-Martínez; M Martínez-Rodríguez, A Gámez-Varela , B Nieto-Castro , J Luna-García , I Juárez-Martínez , H López-Briones , R Guadarrama-Mora , J Torres-Torres , F Coronel-Cruz , D Ibarra-Rios , R Ordorica-Flores , J Nieto-Zermeño. Survival outcomes in severe left diaphragmatic hernia with and without fetoscopic tracheal occlusion in a country with suboptimal neonatal management. Accepted Article.</a></li>
                      <li className="all y2019"><a href="#" target="_blank">21. Rogelio Cruz-Martínez, Prenatal diagnosis of congenital anomalies in the era of fetal surgery in Mexico. Mexican Journal of Medical Research ICSa.</a></li>
                      <li className="all y2019"><a href="https://pubmed.ncbi.nlm.nih.gov/32663828/" target="_blank">22. Marta Rial-Cresteloa,Laura Garcia-Oterob,Annalisa Cancemib, Mariella Giannoneb Elena Escazzocchioc ,Alex Biternad ,Elena Ferriolse, Jose Luis Hernandeze, Marek Lubuskyf, Veronika Kolarovaf ,Ladislav Kroftag ,Jiri Vojtêchh ,Eyal Zohavi ,Karina Krajdenj ,Rogelio Cruz-Martinez ,Monica Cruz-Lemini ,Mauro Parra-Corderol ,Pamela Sociasm Fàtima ,Crispin Marta Lopeza ,Eduard Gratacósa, Francesc Figueras. Prescriptive Reference Standards of Third-Trimester Cerebroplacental Ratio and Its Physiological Determinants. Fetal Diagnosis and Therapy, DOI: 10.1159/000508366.</a></li>
                      <li className="all y2019"><a href="https://pubmed.ncbi.nlm.nih.gov/32449263/" target="_blank">23. N. Docheva, R. Cruz-Martinez, E. Hernandez-Andrade. The vaginal azygos artery: a common unrecognized finding during transvaginal ultrasound. Ultrasound in Obstetrics y Gynecology, https://doi.org/10.1002/uog.22098.</a></li>
                      <li className="all y2019"><a href="https://pubmed.ncbi.nlm.nih.gov/32798234/" target="_blank">24. R Cruz-Martínez; A Gámez-Varela , M Martínez-Rodríguez , J Luna-García , I Juárez-Martínez , H López-Briones, Chavez- Gonzalez, Erendira, Razo- Nava, Arelli, Villalobos- Gomez , Rosa, Favela- Heredia, Cesar. Doppler changes in the umbilical artery, middie cerebral artery, cerebroplacental ratio and ductus venosus during open fetal microneurosurgery for intrauterine myelomeningocele repair. Accepted Article.</a></li>
                      <li className="all y2019"><a href="https://obgyn.onlinelibrary.wiley.com/doi/abs/10.1002/pd.5806" target="_blank">25. R Cruz-Martínez; A Gámez-Varela , Monica Cruz-Lemini ,M Martínez-Rodríguez , J Luna-García , I Juárez-Martínez , H López-Briones, Chavez- Gonzalez Erendira, Chavelas-Ochoa Felipe, Aguilar-Vidales Karla,Chavez-Vega Joel, Castelo-Vargas Andrea,Rivera- CarrilloPaola, Hernandez- Andrade Edgar.Prenatal Diagnosis of laryngo- tracheo-esophageal anomalies in fetuses with congenital diaphragmatic hernia by ultrasound evaluation of the vocal cords and fetal laryngoesophagoscopy. Accepted Article.</a></li>
                      <li className="all y2019"><a href="https://www.karger.com/Article/FullText/513311" target="_blank">26. R Cruz-Martínez; A Gámez-Varela ,M Martínez-Rodríguez , J Luna-García , I Juárez-Martínez , H López-Briones, Chavez- Gonzalez Erendira, Chavelas-Ochoa Felipe, Aguilar-Vidales Karla,Chavez-Vega Joel, Castelo-Vargas Andrea ,Angel Augusto-Perez Calatayund,Manuel Alejandro Diaz- Carrillo,Edgar Ahumada- García, Carlos Rebolledo- Feranandez.Fetal open Microneurosurgery for intrauterine spina Bifida repair. Accepted Article.</a></li>
                      <li className="all y2019"><a href="https://www.medigraphic.com/cgi-bin/new/resumen.cgi?IDARTICULO=86411" target="_blank">27. R Cruz-Martínez, Secuestro broncopulmonar. Diagnóstico prenatal, factores pronóstico y tratamiento por cirugía fetal. Ginecología y Obstetricia, 2019 - search.ebscohost.com. </a></li>
                      <li className="all y2018"><a href="http://www.scielo.org.pe/scielo.php?script=sci_abstract&amp;pid=S2304-51322018000400017&amp;lng=es&amp;nrm=iso" target="_blank">28. R Cruz-Martínez.  Cirugía fetal para hernia diafragmática congénita en América Latina. Revista Peruana de Ginecología y Obstetricia, 2018 -  2018; 64 (4) 655DOI: https://doi.org/10.31403/rpgo.v64i2141.</a></li>
                      <li className="all y2018"><a href="https://pubmed.ncbi.nlm.nih.gov/30511776/" target="_blank">29. Rogelio Cruz‐Martínez, Miguel Martínez‐Rodríguez, Belén Nieto‐Castro, Alma Gámez‐Varela, Monica Cruz‐Lemini, Jonahtan Luna‐García, Israel Juárez‐Martínez. Longitudinal changes in lung size and intrapulmonary‐artery Doppler during the second half of pregnancy in fetuses with congenital diaphragmatic hernia. Prenatal Diagnosis. DOI: 10.1002/pd.540,Año 2018.</a></li>
                      <li className="all y2018"><a href="https://pubmed.ncbi.nlm.nih.gov/29572873/" target="_blank">30. Cruz-Lemini M, Cruz-Martinez R. Characterizing cardiac dysfunction in fetuses with left congenital diaphragmatic hernia. Prenatal Diagnosis 2018 May; 38(6):422-427. doi: 10.1002.</a></li>
                      <li className="all y2018"><a href="https://www.sciencedirect.com/science/article/abs/pii/S0736574817302800" target="_blank">31. Cubero-Rego L, Corsi-Cabrera M, Ricardo-Garcell J, Cruz-Martínez R, Harmony T. Visual evoked potentials are similar in polysomnographically defined quiet and active sleep in healthy newborns. Int J Dev Neurosci. 2018 Apr 23; 68:26-34.  doi: 10.1016/j.ijdevneu.2018.04.007.</a></li>
                      <li className="all y2018"><a href="https://www.karger.com/Article/Abstract/481170" target="_blank">32. Cruz-Martinez R, Nieto-Castro B, Martinez-Rodriguez M, Gámez-Varela A, Ahumada-Angulo E, Luna-García J, Pineda-Pérez  MJ, Rebolledo-Fernández C. Thoracic changes after full laser ablation of the feeding artery in fetuses with bronchopulmonary sequestration. Fetal Diagn Ther 2018, (Epub ahead of print).  doi: 10.1159/000481170</a></li>
                      <li className="all y2017"><a href="https://pubmed.ncbi.nlm.nih.gov/28890038/" target="_blank">33. Cubero-Rego L, Ricardo-Garcell J, Corsi-Cabrera M, Cruz-Martinez R, Rebolledo-Fernández C, Otero-Ojeda G, Harmony T. Improving the efficiency of Auditory Brainstem Responses in newborns, using a 60 clicks/s stimulation rate. J Clin Neurosci. 2017 Nov; 45:299-304. doi: 10.1016/j.jocn.2017.08.044</a></li>
                      <li className="all y2017"><a href="https://bmjopen.bmj.com/content/7/6/e014835" target="_blank">34. Figueras F, Gratacos E, Rial M, Gull I, Krofta L, Lubusky M, Cruz-Martínez R, Cruz-Lemini M, Martinez-Rodriguez M, Socias P, Aleuanlli C, Cordero MCP. Revealed versus concealed criteria for placental insufficiency in an unselected obstetric population in late pregnancy (RATIO37): randomised controlled trial study protocol. BMJ Open. 2017 Jun 15; 7(6):e014835. doi: 10.1136/bmjopen-2016-014835.</a></li>
                      <li className="all y2017"><a href="https://pubmed.ncbi.nlm.nih.gov/28573772/" target="_blank">35. Cruz-Martinez R. Reply. Percutaneous laser ablation of the feeding vessel in pulmonary sequestration or hybrid lesions. Ultrasound Obstet Gynecol. 2017 Jun; 49(6):811. doi: 10.1002/uog.17507. </a></li>
                      <li className="all y2017"><a href="https://pubmed.ncbi.nlm.nih.gov/28342715/" target="_blank">36. Palacio M, Bonet-Carne E, Cobo T, Perez-Moreno A, Sabrià J, Richter J, Kacerovsky M, Jacobsson B, García-Posada RA, Bugatto F, Santisteve R, Vives À, Parra-Cordero M, Hernandez-Andrade E, Bartha JL, Carretero-Lucena P, Tan KL, Cruz-Martínez R, Burke M, Vavilala S, Iruretagoyena I, Delgado JL, Schenone M, Vilanova J, Botet F, Yeo GS, Hyett J, Deprest J, Romero R, Gratacos E; Fetal Lung Texture Team. Prediction of Neonatal Respiratory Morbidity by Quantitative Ultrasound Lung Texture Analysis: A Multicenter Study. Am J Obstet Gynecol. 2017 Aug; 217(2):196.e1-196.e14. doi: 10.1016/j.ajog.2017.03.016 </a></li>
                      <li className="all y2017"><a href="https://obgyn.onlinelibrary.wiley.com/doi/full/10.1002/uog.16011" target="_blank">37. Cruz-Martinez R, Martínez-Rodríguez M, Bermúdez-Rojas M, Magaña-Abarca C, Narváez-Domínguez V, Rojas-Macedo A, Bautista-García N, Alcocer-Alcocer M. Fetal laser ablation of feeding artery of cystic lung lesions with systemic arterial blood supply. Ultrasound Obstet Gynecol. 2017 Jun; 49(6):744-750. doi: 10.1002/uog 16011</a></li>
                      <li className="all y2016"><a href="https://obgyn.onlinelibrary.wiley.com/doi/10.1002/pd.4771" target="_blank">38. Sananes N, Cruz-Martinez R, Favre R, Ordorica-Flores R, Moog R, Zaloszy A, Giron AM, Ruano R. Two-year outcomes after diagnostic and therapeutic fetal cystoscopy for lower urinary tract obstruction. Prenat Diagn. 2016 Apr; 36(4):297-303. doi: 10.1002/pd.4771. Epub 2016 Feb 17.</a></li>
                      <li className="all y2016"><a href="https://www.karger.com/Article/Abstract/441026" target="_blank">39. Cruz-Martinez R, Cruz-Lemini M, Mendez A, Illa M, Martinez JM, Gratacos E. Learning curve for intrapulmonary artery Doppler in fetuses with congenital diaphragmatic hernia. Fetal Diagn Ther 2016; 39(4):256-60. doi: 10.1159/000441026</a></li>
                      <li className="all y2015"><a href="https://obgyn.onlinelibrary.wiley.com/doi/10.1002/uog.14920" target="_blank">40. Cruz-Martinez R, Tenorio V, Padilla N, Crispi F, Figueras F, Gratacos E. Risk of ultrasound-detected neonatal brain abnormalities in intrauterine growth restricted fetuses born between 28 and 34 weeks: relationship with gestational age at birth and fetal Doppler parameters. Ultrasound Obstet Gynecol. 2015 Oct; 46(4):452-9. doi: 10.1002/uog.14920</a></li>
                      <li className="all y2015"><a href="https://pubmed.ncbi.nlm.nih.gov/26046453/" target="_blank">41. Cruz-Martinez R, Méndez A, Rebolledo-Fernández C, Dueñas-Riaño J, Ordorica-Flores R, Nieto-Zermeño J, Malagón-Salazar P, Medina-Noyola C. Fetal laser surgery prevents fetal death and avoids the need for neonatal sequestrectomy in cases with bronchopulmonary sequestration. Ultrasound Obstet Gynecol. 2015 Nov; 46(5):627-8. doi: 10.1002/uog.14921</a></li>
                      <li className="all y2015"><a href="https://www.karger.com/Article/Abstract/362387" target="_blank">42. Cruz-Martinez R, Moreno-Alvarez O, Garcia M, Pineda H, Cruz MA, Martinez-Morales C. Fetal Endoscopic Tracheal Intubation: A New Fetoscopic Procedure to Ensure Extrauterine Tracheal Permeability in a Case with Congenital Cervical Teratoma. Fetal Diagn Ther 2015; 38(2):154-8. doi: 10.1159/000362387</a></li>
                      <li className="all y2015"><a href="https://pubmed.ncbi.nlm.nih.gov/25346413/" target="_blank">43. Cruz-Martinez R, Savchev S, Cruz-Lemini M, Mendez A, Gratacos E and Figueras F. Clinical utility of third trimester uterine artery Doppler in the prediction of brain hemodynamic deterioration and adverse perinatal outcome in small-for-gestational-age fetuses. Ultrasound Obstet Gynecol 2015 Mar; 45(3):273-8. doi: 10.1002/uog.14706</a></li>
                      <li className="all y2015"><a href="https://www.medigraphic.com/cgi-bin/new/resumenI.cgi?IDARTICULO=55477" target="_blank">44. Cruz-Martinez R, Mendez A, Pineda-Alemán H, Rebolledo-Fernández C. The EXIT procedure: Indications, limitations, risks and progress to the fetal endoscopical tracheal intubation. Gynecol Obstet Mex 2015; 83:58-65.</a></li>
                      <li className="all y2015"><a href="https://www.karger.com/Article/Abstract/361015" target="_blank">45. Cruz-Martinez R, Méndez A, Perez-Garcilita O, Monroy A, Aguilar-Vidales K, Cruz-Martinez MA, Martinez-Morales C. Fetal Bronchoscopy as a Useful Procedure in a Case with Prenatal Diagnosis of Congenital Microcystic Adenomatoid Malformation.  Fetal Diagn Ther. 2015; 37(1):75-80. doi: 10.1159/000361015</a></li>
                      <li className="all y2014"><a href="https://pubmed.ncbi.nlm.nih.gov/25449115/" target="_blank">46. Cruz-Martinez R. Re: The influence of parity on fetal haemodynamics and amniotic fluid volume at term. Prior T, Mullins E, Bennett P, Kumar S. Ultrasound Obstet Gynecol 2014 Dec; 44(6):631. doi: 10.1002/uog.14709.</a></li>
                      <li className="all y2014"><a href="https://www.medigraphic.com/pdfs/ginobsmex/gom-2014/gom145f.pdf" target="_blank">47. Cruz-Martínez R, Gratacos E. Endoscopic Fetal Surgery. Gynecol Obstet Mex 2014 May; 82(5):325-36.</a></li>
                      <li className="all y2014"><a href="https://www.nature.com/articles/pr2013244" target="_blank">48. Prat Ortells J, Albert A, Tarrado X, Krauel L, Cruz R, Moreno-Álvarez Ó, Fuste V, Castañón M. Airway and vascular maturation stimulated by tracheal occlusion do not correlate in the rabbit model of diaphragmatic hernia. Pediatr Res. 2014 Apr; 75(4):487-92. doi: 10.1038</a></li>
                      <li className="all y2014"><a href="https://pubmed.ncbi.nlm.nih.gov/24293263/" target="_blank">49. Benavides-Serralde JA, Hernandez-Andrade E, Cruz-Martinez R, Cruz-Lemini M, Scheier M, Figueras F, Mancilla J, Gratacos E. Doppler evaluation of the posterior cerebral artery in normally grown and growth restricted fetuses. Prenatal Diagnosis 2014 Feb; 34(2):115-20. doi: 10.1002/pd.4265</a></li>
                      <li className="all y2013"><a href="https://pubmed.ncbi.nlm.nih.gov/23303527/" target="_blank">50. Savchev S, Sanz-Cortes M, Cruz-Martinez R, Arranz A, Botet F, Gratacos E, Figueras F. Neurodevelopment outcome of full-term small-for gestational-age infants with normal placental function. Ultrasound Obstet Gynecol 2013 Aug; 42(2):201-6. doi: 10.1002/uog.12391</a></li>
                      <li className="all y2013"><a href="https://pubmed.ncbi.nlm.nih.gov/22689226/" target="_blank">51. Cruz-Martinez R, Castañon M, Moreno-Alvarez O, Acosta-Rojas R, Martinez JM, Gratacos E. Usefulness of lung-to-head ratio and intrapulmonary Doppler in predicting neonatal morbidity in fetuses with congenital diaphragmatic hernia treated with fetoscopic tracheal occlusion. Ultrasound Obstet Gynecol 2013 Jan; 41(1):59-65. doi: 10.1002/uog.11212</a></li>
                      <li className="all y2012"><a href="https://www.karger.com/Article/Abstract/333001" target="_blank">52. Cruz-Lemini M, Crispi F, Van Mieghem T, Pedraza T, Cruz-Martínez R, Acosta-Rojas R, Figueras F, Parra-Cordero M, Deprest J, Gratacos E. Risk of perinatal death in early-onset intrauterine growth restriction according to gestational age and cardiovascular Doppler indices: a multicenter study. Fetal Diagn Ther 2012 Jul; 32(1-2):116-22. doi: 10.1159/000333001</a></li>
                      <li className="all y2012"><a href="https://www.karger.com/Article/Abstract/330798" target="_blank">53. Cruz-Martinez R, Figueras F, Bennasar M, Garcia-Posadas R, Crispi F, Hernández-Andrade E, Gratacos E. Normal reference ranges from 11 to 41 weeks’ gestation of fetal left modified myocardial performance index by conventional Doppler with the use of stringent criteria for delimitation of the time periods. Fetal Diagn Ther 2012 Jul; 32(1-2):79-86. doi: 10.1159/000330798</a></li>
                      <li className="all y2012"><a href="https://www.karger.com/Article/Fulltext/330792" target="_blank">54. Hernandez-Andrade E, Benavides-Serralde JA, Cruz-Martinez R, Welsh A, Mancilla-Ramirez J. Evaluation of Conventional Doppler Fetal Cardiac Function Parameters: E/A Ratios, Outflow Tracts, and Myocardial Performance Index. Fetal Diagn Ther 2012 Jul; 32(1-2):22-9. doi: 10.1159/000330792</a></li>
                      <li className="all y2012"><a href="https://pubmed.ncbi.nlm.nih.gov/22102177/" target="_blank">55. Savchev S, Figueras F, Cruz-Martinez R, Illa M, Botet F, Gratacos E. Estimated fetal weight percentile predicts perinatal outcome in term, small-for-gestational age fetuses with normal umbilical, uterine and brain Doppler. Ultrasound Obstet Gynecol 2012 March; 39(3):299-303. doi: 10.1002/uog.10150</a></li>
                      <li className="all y2012"><a href="https://obgyn.onlinelibrary.wiley.com/doi/10.1002/pd.2913" target="_blank">56. Hernández-Andrade E, Benavides Serralde A, Cruz-Martínez R. Can anomalies of fetal brain circulation be useful in the management of intrauterine growth restriction? Prenatal Diagnosis 2012 Feb; 32(2):103-12. doi: 10.1002/pd.2913</a></li>
                      <li className="all y2012"><a href="https://pubmed.ncbi.nlm.nih.gov/21910146/" target="_blank">57. Van Mieghem T, Cruz-Martinez R, Allegaert K, Dekoninck P, Castañon M, Sandaite I, Claus F, Devlieger R, Gratacos E, Deprest J. Outcomes of fetuses with congenital diaphragmatic hernia and associated intra-fetal fluid effusions managed in the era of fetal surgery. Ultrasound Obstet Gynecol 2012 Jan; 39(1):50-5. doi: 10.1002/uog.10097</a></li>
                      <li className="all y2011"><a href="https://pubmed.ncbi.nlm.nih.gov/21620362/" target="_blank">58. Comas M, Crispi F, Cruz-Martínez R, Martinez JM, Figueras F, Gratacos E. Tissue Doppler echocardiographic markers of cardiac dysfunction in small-for-gestational age fetuses. Am J Obstet Gynecol 2011 Jul; 205(1):57.e1-6. doi: 10.1016/j.ajog.2011.03.010 </a></li>
                      <li className="all y2011"><a href="https://obgyn.onlinelibrary.wiley.com/doi/full/10.1002/uog.8976" target="_blank">59. Cruz-Martinez R, Figueras F, Hernández-Andrade E, Gratacos E. Changes in myocardial performance index, aortic isthmus and ductus venosus in term, small-for-gestational age fetuses with normal umbilical artery Doppler. Ultrasound Obstet Gynecol 2011 Oct; 38(4):400-5. doi: 10.1002/uog.8976</a></li>
                      <li className="all y2011"><a href="https://obgyn.onlinelibrary.wiley.com/doi/10.1002/uog.9041" target="_blank">60. Figueras F, Cruz-Martinez R, Sanz-Cortes M, Arranz A, Botet F, Gratacos E. Neurobehavioral outcomes in preterm, growth-restricted infants with and without prenatal advanced signs of brainsparing. Ultrasound Obstet Gynecol 2011 Sep; 38(3):288-94. doi: 10.1002/uog.9041</a></li>
                      <li className="all y2011"><a href="https://obgyn.onlinelibrary.wiley.com/doi/full/10.1002/uog.8922" target="_blank">61. Cruz-Martinez R, Van Mieghem T, Lewi L, Eixarch E, Martinez JM, Deprest J, Gratacós E. Incidence and clinical implications of acute unintended septostomy after fetoscopic laser therapy for twin-twin transfusion syndrome. Ultrasound Obstet Gynecol 2011 Apr; 37(4):458-62. doi: 10.1002/uog.8922</a></li>
                      <li className="all y2011"><a href="https://www.karger.com/Article/Abstract/324102" target="_blank">62. Rodrigues HC, Deprest J, Cruz-Martínez R, van den Berg PP. Use of Data from Predictive Tests following Fetoscopic Endoluminal Tracheal Occlusion for Congenital Diaphragmatic Hernia. Fetal Diagn Ther 2011 Apr; 29(3):261-2. doi: 10.1159/000324102</a></li>
                      <li className="all y2011"><a href="https://pubmed.ncbi.nlm.nih.gov/21154784/" target="_blank">63. Cruz-Martinez R, Figueras F, Benavides-Serralde A, Crispi F, Hernández-Andrade E, Gratacos E. Sequence of changes in myocardial performance index, aortic isthmus and ductus venosus Doppler in fetuses with early-onset intrauterine growth restriction. Ultrasound Obstet Gynecol 2011 Aug; 38(2):179-84. doi: 10.1002/uog.8903</a></li>
                      <li className="all y2011"><a href="https://pubmed.ncbi.nlm.nih.gov/21346314/" target="_blank">64. Benavides-Serralde A, Sheier M, Cruz-Martinez R, Crispi F, Figueras F, Gratacos E, Hernandez-Andrade E. Changes in central and peripheral circulation in intrauterine growth-restricted fetuses at different stages of umbilical artery flow deterioration: New fetal cardiac and brain parameters. Gynecol Obstet Invest 2011 Jun; 71(4):274-80. doi: 10.1159/000323548</a></li>
                      <li className="all y2011"><a href="https://pubmed.ncbi.nlm.nih.gov/21343765/" target="_blank">65. Cruz-Martínez R, Figueras F, Hernández-Andrade E, Oros D, Gratacos E. Fetal brain Doppler to predict cesarean delivery for nonreassuring fetal status in term small-for-gestational-age fetuses. Obstet Gynecol 2011 Mar; 117(3):618-26. doi: 10.1097/AOG.0b013e31820b0884</a></li>
                      <li className="all y2011"><a href="https://pubmed.ncbi.nlm.nih.gov/20922780/" target="_blank">66. Cruz-Martinez R, Figueras F, Mendez A, Jaramillo J, Meler E, Hernández-Andrade E, Gratacos E. Learning curve for Doppler calculation of the fetal myocardial performance index. Ultrasound Obstet Gynecol 2011 Feb; 37(2):158-62. doi: 10.1002/uog.7765</a></li>
                      <li className="all y2011"><a href="https://www.karger.com/Article/Abstract/320249" target="_blank">67. Cruz-Martinez R, Hernández-Andrade E, Moreno-Alvarez O, Done E, Deprest J, Gratacos E. Prognostic value of pulmonary Doppler to predict response to tracheal occlusion in fetuses with congenital diaphragmatic hernia. Fetal Diagn Ther 2011 Feb; 29(1):18-24. doi: 10.1159/000320249</a></li>
                      <li className="all y2011"><a href="https://www.karger.com/Article/Abstract/295262" target="_blank">68. Cruz-Martinez R, Moreno-Alvarez O, Hernandez-Andrade E, Castañon M, Done E, Deprest J, Gratacos E. Lung tissue perfusion in prediction of survival in fetuses with congenital diaphragmatic hernia treated with fetal endoscopic tracheal occlusion (FETO). Fetal Diagn and Ther 2011 Feb; 29(1):101-7. doi: 10.1159/000295262</a></li>
                      <li className="all y2011"><a href="https://pubmed.ncbi.nlm.nih.gov/20617509/" target="_blank">69. Oros D, Figueras F, Cruz-Martinez R, Muntmany M, Arranz A, Gratacos E. Longitudinal changes in uterine, umbilical and fetal cerebral Doppler indices in late-onset small-for-gestational age fetuses. Ultrasound Obstet Gynecol 2011 Feb;37(2):191-5.doi: 10.1002/uog.7738</a></li>
                      <li className="all y2011"><a href="https://obgyn.onlinelibrary.wiley.com/doi/full/10.1002/uog.7722" target="_blank">70. Cruz-Martinez R, Figueras F, Hernández-Andrade E, Benavides-Serralde A, Gratacos E. Normal references ranges of fetal regional cerebral blood perfusion using power Doppler ultrasound as measured by Fractional Moving Blood Volume. Ultrasound Obstet Gynecol 2011 Feb;37(2):196-201. doi: 10.1002/uog.7722</a></li>
                      <li className="all y2011"><a href="https://www.karger.com/Article/Abstract/320605" target="_blank">71. Claus F, Sandaite I, Dekoninck P, Moreno O, Cruz Martinez R, Van Mieghem T, Gucciardo L, Richter J, Michielsen K, Decraene J, Devlieger R, Gratacos E, Deprest JA. Prenatal Anatomical Imaging in Fetuses with Congenital Diaphragmatic Hernia. Fetal Diagn Ther 2011 Feb; 29(1):88-100. doi: 10.1159/000320605</a></li>
                      <li className="all y2010"><a href="https://obgyn.onlinelibrary.wiley.com/doi/full/10.1002/pd.2651" target="_blank">72. Srisupundit K, Brady PD, Devriendt K, Fryns JP, Cruz-Martinez R, Gratacos E, Deprest JA, Vermeesch JR. Targeted array comparative genomic hybridisation (array CGH) identifies genomic imbalances associated with isolated CDH. Prenatal Diagnosis 2010 Dec;30(12-13):1198-206.doi: 10.1002/pd.2651</a></li>
                      <li className="all y2010"><a href="https://pubmed.ncbi.nlm.nih.gov/20451892/" target="_blank">73. Comas M, Crispi F, Cruz-Martínez R, Martinez JM, Figueras F, Gratacos E. Usefulness of myocardial tissue Doppler versus conventional echocardiography in the evaluation of cardiac dysfunction in early-onset intrauterine growth restriction. Am J Obstet Gynecol 2010 Jul; 203 (1):45.e1-7. Doi: 10.1016/j.ajog.2010.02.044</a></li>
                      <li className="all y2010"><a href="https://pubmed.ncbi.nlm.nih.gov/20435282/" target="_blank">74. Cruz-Martinez R, Figueras F, Oros D, Meler E, Hernández-Andrade E, Gratacos E. Longitudinal brain perfusion changes in near-term small-for-gestational-age fetuses as measured by spectral Doppler indices or by Fractional Moving Blood Volume. Am J Obstet Gynecol 2010 Jul; 203 (1):42.e1-6. Doi: 10.1016/j.ajog.2010.02.049</a></li>
                      <li className="all y2010"><a href="https://pubmed.ncbi.nlm.nih.gov/20131334/" target="_blank">75. Cruz-Martinez R, Figueras F, Moreno-Alvarez O, Gomez O, Martinez JM, Gratacos E. Learning curve of the lung area to head circumference ratio measurement in fetuses with congenital diaphragmatic hernia. Ultrasound Obstet Gynecol 2010 Jul;36(1):32-6. Doi: 10.1002/uog.7577</a></li>
                      <li className="all y2010"><a href="https://obgyn.onlinelibrary.wiley.com/doi/full/10.1002/uog.7593" target="_blank">76. Cruz-Martinez R, Moreno-Alvarez O, Castañon M, Done E, Hernandez-Andrade E, Deprest J, Gratacos E. Contribution of lung Doppler in prediction of survival in fetuses with congenital diaphragmatic hernia treated with tracheal occlusion. Ultrasound Obstet Gynecol 2010 May; 35(5):572-577. </a></li>
                      <li className="all y2010"><a href="https://pubmed.ncbi.nlm.nih.gov/20178113/" target="_blank">77. Moreno-Alvarez O, Cruz-Martinez R, Done E, Gómez O, Hernandez-Andrade E, Deprest J, Gratacos E. Lung tissue perfusion in congenital diaphragmatic hernia and association with the lung to head ratio and intrapulmonary pulsed Doppler. Ultrasound Obstet Gynecol 2010 May; 35:578-582.</a></li>
                      <li className="all y2010"><a href="https://pubmed.ncbi.nlm.nih.gov/20178115/" target="_blank">78. Oros D, Figueras F, Cruz-Martinez R, Meler E, Hernández-Andrade E, Gratacos E. Middle versus anterior cerebral artery for the prediction of adverse outcome in term small-for-gestational-age fetuses. Ultrasound Obstet Gynecol 2010 Apr; 35:456-461. </a></li>
                      <li className="all y2009"><a href="https://www.karger.com/Article/Abstract/254485" target="_blank">79. Cruz-Martinez R, Moreno-Alvarez O, Prat J, Krauel L, Tarrado X, Castañón M, Hernandez-Andrade E, Albert A, Gratacos E. Lung tissue blood perfusion changes induced by in utero tracheal occlusion in a rabbit model of congenital diaphragmatic hernia. Fetal Diagn Ther 2009 Dec; 26(3):137-142.</a></li>
                      <li className="all y2009"><a href="https://pubmed.ncbi.nlm.nih.gov/19767241/" target="_blank">80. Cruz-Martinez R, Figueras F. Role of Doppler and Placental screening in intrauterine growth restriction. Best Pract Res Clin Obstet Gynecol 2009 Dec; 23(6):845-55.</a></li>
                      <li className="all y2009"><a href="https://pubmed.ncbi.nlm.nih.gov/19631930/" target="_blank">81. Cruz-Martinez R, Figueras F, Oros D, Meler E, Padilla N, Hernández-Andrade E, Gratacos E. Cerebral blood perfusion and neurobehavioral performance in full term small for gestational age fetuses. Am J Obstet Gynecol 2009 Nov; 201(5):474.e1-7.</a></li>
                      <li className="all y2009"><a href="https://pubmed.ncbi.nlm.nih.gov/19858151/" target="_blank">82. Figueras F, Oros D, Cruz-Martinez R, Padilla N, Botet, Costas M, Gratacos E. Neurobehavior in term small-for-gestational age infants with normal placental function. Pediatrics 2009 Nov; 124(5):e934-41.</a></li>
                      <li className="all y2009"><a href="https://pubmed.ncbi.nlm.nih.gov/19658113/" target="_blank">83. Jani J, Nicolaides KH, Gratacos E, Valencia C, Done E, Cruz R, Deprest JA. Severe diaphragmatic hernia treated with fetal endoscopic tracheal occlusion. Ultrasound Obstet Gynecol 2009 Sep; 34:304-310.</a></li>
                      <li className="all y2008"><a href="https://www.medigraphic.com/cgi-bin/new/resumen.cgi?IDARTICULO=19520" target="_blank">84. Cruz Martínez R, García N, Manzanares G. Embarazo Angular reporte de un caso y revisión bibliográfica. Ginecol Obstet Mex 2008 Jul; 76(7):417-20.</a></li>
                    </>
                    : <></>
                }

                {visible2021 ?
                  <>
                    <li className="all y2021"><a href="https://www.tandfonline.com/doi/abs/10.1080/14767058.2021.1892062" target="_blank">1. Ahn H, Cruz-Martinez R, Hernandez-Andrade E. Variation in the uterine arteries Doppler parameters when obtained transvaginally or transabdominally at different sampling locations. J Matern Fetal Neonatal Med. 2021 Mar 3:1-8. doi: 10.1080/14767058.2021.1892062.</a></li>
                    <li className="all y2021"><a href="https://obgyn.onlinelibrary.wiley.com/doi/abs/10.1002/pd.5946" target="_blank">2. Cruz-Martínez R, Martínez-Rodríguez M, Gámez-Varela A, Luna-García J, López-Briones H, Chávez-González E, Villalobos-Gómez R, Juárez-Martínez I. Fetoscopic urethral meatotomy in fetuses with lower urinary tract obstruction by congenital megalourethra. Prenat Diagn. 2021 Apr 1. doi: 10.1002/pd.5946.</a></li>
                    <li className="all y2021"><a href="https://pubmed.ncbi.nlm.nih.gov/33677452/" target="_blank">3. Cruz-Martínez R, Sosa Sosa C, Martínez-Rodríguez M, Gámez-Varela A, Villalobos-Gómez R, López-Briones H, Luna-García J, Chávez-González E, Juárez-Martínez I. Single Uterine Access for Bilateral Pleuroamniotic Shunting in Fetuses with Severe Hydrothorax by an Internal Rotational Maneuver: Feasibility and Outcomes between Successful and Failed Procedures. Fetal Diagn Ther. 2021;48(3):209-216. doi: 10.1159/000513748.</a></li>
                    <li className="all y2021"><a href="https://www.ajog.org/article/S0002-9378(21)00116-2/fulltext" target="_blank">4. Cruz-Martínez R, Díaz R, Martínez-Rodríguez M. Early release of amniotic bands using a 1.0-mm fetoscope. Am J Obstet Gynecol. 2021 Feb 19:S0002-9378(21)00116-2. doi: 10.1016/j.ajog.2021.02.019.</a></li>
                    <li className="all y2021"><a href="https://www.karger.com/Article/Abstract/514912" target="_blank">5. Gámez-Varela A, Martínez-Rodríguez M, López-Briones H, Luna-García J, Chávez-González E, Villalobos-Gómez R, Hernandez-Andrade E, Cruz-Martínez R. Preoperative Cervical Length Predicts the Risk of Delivery within One Week after Pleuroamniotic Shunt in Fetuses with Severe Hydrothorax. Fetal Diagn Ther. 2021;48(4):297-303. doi: 10.1159/000514912.</a></li>
                    <li className="all y2021"><a href="https://pubmed.ncbi.nlm.nih.gov/33582666/" target="_blank">6. Cruz-Martínez R, Chavelas-Ochoa F, Martínez-Rodríguez M, Aguilar-Vidales K, Gámez-Varela A, Luna-García J, López-Briones H, Chávez-Vega J, Pérez-Calatayud ÁA, Díaz-Carrillo MA, Ahumada-Angulo E, Castelo-Vargas A, Chávez-González E, Juárez-Martínez I, Villalobos-Gómez R, Rebolledo-Fernández C. Open Fetal Microneurosurgery for Intrauterine Spina Bifida Repair. Fetal Diagn Ther. 2021;48(3):163-173. doi: 10.1159/000513311.</a></li>
                    <li className="all y2021"><a href="https://obgyn.onlinelibrary.wiley.com/doi/abs/10.1002/pd.5806" target="_blank">7. Cruz-Lemini M, Nieto-Castro B, Luna-Garcia J, Juarez-Martinez I, Martínez-Rivera M, Bermudez-Rojas ML, Rebolledo-Fernández C, Cruz-Martinez R. Prenatal diagnosis of congenital heart defects: experience of the first Fetal Cardiology Unit in Mexico. J Matern Fetal Neonatal Med. 2021 May;34(10):1529-1534. doi: 10.1080/14767058.2019.1638905.</a></li>
                  </>
                  : <></>
                }

                {visible2020 ?
                  <>
                    <li className="all y2020"><a href="#" target="_blank">8. Rogelio Cruz-Martínez, Alma Gámez-Varela, Miguel Martínez-Rodríguez, Jonahtan Luna-García, Hugo López-Briones, Eréndira Chavez-González, Israel Juárez-Martínez, Areli Razo-Nava, Rosa Villalobos-Gómez, César Favela-Heredia. Prenatal diagnosis of laryngo-tracheo-esophageal anomalies in fetuses with congenital diaphragmatic hernia by ultrasound evaluation of the vocal cords and fetal laryngoesophagoscopy. Prenatal Diagnosis, DOI:10.1002/pd.5806, 10.1002/pd.5806.Año 2020.</a></li>
                    <li className="all y2020"><a href="https://pubmed.ncbi.nlm.nih.gov/32663828/" target="_blank">9. Marta Rial-Crestelo, Laura Garcia-Otero, Annalisa Cancemi, Mariella Giannone, Elena Escazzocchio, Alex Biterna, Elena Ferriols, Jose Luis Hernandez, Marek Lubusky, Veronika Kolarova, Ladislav Krofta, Jiri Vojtêch , Eyal Zohav, Karina Krajden, Rogelio Cruz-Martinez , Monica Cruz-Lemini, Mauro Parra-Cordero, Pamela Socias, Fàtima Crispi, Marta Lopez,Eduard Gratacós, Francesc Figueras, Ratio 37 study group. Prescriptive Reference Standards of Third-Trimester Cerebroplacental Ratio and Its Physiological Determinants.Fetal Diagnosis and therapy, DOI: 10.1159/000508366, Fetal Diagnosis and Therapy,Karger. Año 2020.</a></li>
                    <li className="all y2020"><a href="https://pubmed.ncbi.nlm.nih.gov/32959414/" target="_blank">10. R Cruz-Martínez , H López-Briones , J Luna-García , M Martínez-Rodriguez , A Gámez-Varela, E Chávez-González,R Villalobos-Gómez. Incidence and survival outcomes of monochorionic diamniotic twin pregnancies with twin-to-twin transfusion syndrome presenting without amniotic fluid discordance due to spontaneous septostomy and treated with fetoscopy.Ultrasound Obstet Gynecol, DOI: 10.1002/uog.23129. Año 2020.</a></li>
                    <li className="all y2020"><a href="https://pubmed.ncbi.nlm.nih.gov/32798234/" target="_blank">11. R Cruz-Martínez, A Gámez-Varela, M Cruz-Lemini, M Martínez-Rodríguez, J Luna-García, H López-Briones, F Chavelas-Ochoa,E Chávez-González, K Aguilar-Vidales, J Chávez-Vega, A Castelo-Vargas, P Rivera-Carrillo, E Hernández-Andrade.Doppler changes in the umbilical artery, middle cerebral artery, cerebroplacental ratio and ductus venosus during open fetal microneurosurgery for intrauterine myelomeningocele repair. Ultrasound Obstet Gynecol. DOI: 10.1002/uog.22177, Año 2020.</a></li>
                    <li className="all y2020"><a href="https://obgyn.onlinelibrary.wiley.com/doi/abs/10.1002/uog.23121" target="_blank">12. Villalobos- Gomez Rosa, J Luna-Garcia, H López-Briones, Chavez- Gonzalez, Erendira, M Martínez-Rodríguez, A Gámez-Varela, Meza Barrera, Ruben, I Juárez-Martínez, R Cruz-Martínez. Incidence and outcomes of fetuses with severe hydrothorax and ductus venosus agenesis treated with thoracoamniotic shunt. Ultrasound Obstet Gynecol; https://doi.org/10.1002/uog.23121 Año, 2020.</a></li>
                    <li className="all y2020"><a href="https://pubmed.ncbi.nlm.nih.gov/32798234/" target="_blank">13. N Docheva , R Cruz-Martinez , E Hernandez-Andrade, The vaginal azygos artery: a common unrecognized finding during transvaginal ultrasound. Ultrasound Obstet Gynecol. DOI: 10.1002/uog.22098,Año 2020.</a></li>
                    <li className="all y2020"><a href="https://obgyn.onlinelibrary.wiley.com/doi/full/10.1002/uog.20370" target="_blank">14. M Rial-Crestelo , J Morales-Roselló , E Hernández-Andrade , F Prefumo 4, D Oros , D Caffici , A Sotiriadis , E Zohav , R Cruz-Martinez , M Parra-Cordero , M Lubusky , M Kacerovsky , F Figueras. Quality assessment of fetal middle cerebral and umbilical artery Doppler images using an objective scale within an international randomized controlled trial. Ultrasound Obstet Gynecol. Ultrasound Obstet Gynecol; DOI: 10.1002/uog.20370,Año 2020.</a></li>
                    <li className="all y2020"><a href="https://obgyn.onlinelibrary.wiley.com/doi/abs/10.1002/uog.21993" target="_blank">15. R Cruz-Martínez , M Martínez-Rodríguez , A Gámez-Varela, B Nieto-Castro , J Luna-García, I Juárez-Martínez , H López-Briones , R Guadarrama-Mora , J Torres-Torres , F Coronel-Cruz , D Ibarra-Rios , R Ordorica-Flores , J Nieto-Zermeño. Survival outcome in severe left-sided congenital diaphragmatic hernia with and without fetal endoscopic tracheal occlusion in a country with suboptimal neonatal management. Ultrasound Obstet Gynecol; DOI: 10.1002/uog.21993.Año 2020.</a></li>
                  </>
                  : <></>
                }

                {visible2019 ?
                  <>
                    <li className="all y2019"><a href="#" target="_blank">16. Rogelio Cruz‐Martínez,Adolfo Etchegaray ,Saulo Molina‐Giraldo ,Belen Nieto‐Castro ,Enrique Gil Guevara ,Joaquin Bustillos ,Miguel Martínez‐Rodríguez ,Alma Gámez‐Varela ,Daniel Saldivar‐Rodríguez ,Erendira Chávez‐González ,Rodolfo Keller ,Ricardo Russo ,Eduardo Yepez‐García ,Fausto Coronel‐Cruz ,Johnatan Torres‐Torres ,Alejandro Rojas‐Macedo ,Daniel Ibarra‐Ríos ,Ricardo Ordorica‐Flores ,Jaime Nieto‐Zermeño ,Manuel Alcocer‐Alcocer on behalf of the Latin American CDH Study Group; A multicentre study to predict neonatal survival according to lung‐to‐head ratio and liver herniation in fetuses with left congenital diaphragmatic hernia (CDH): Hidden mortality from the Latin American CDH Study Group Registry. Prenatal Diagnosis, https://doi.org/10.1002/pd.5458.Año,2019.</a></li>
                    <li className="all y2019"><a href="#" target="_blank">17. Mónica Cruz‐Lemini, Monica Alvarado‐Guaman0., Belen Nieto‐Castro, Jonathan Luna‐Garcia, Miguel Martínez‐Rodríguez, Israel Juarez‐Martínez, Alexis Palacios‐Macedo, Rogelio Cruz‐Martínez. Outcomes of hypoplastic left heart syndrome and fetal aortic valvuloplasty in a country with suboptimal postnatal management. Prenatal Diagnosis, https://doi.org/10.1002/pd.5470. Año 2019.</a></li>
                    <li className="all y2019"><a href="#" target="_blank">18. Marta Rial-Crestelo ; Jose Morales-Roselló ; Edgar Hernández-Andrade ; Federico Prefumo ; Daniel Oros ; Daniel Caffici ; Alexandros Sotiriadis ; Eyal Zohav ; Rogelio CruzMartinez ; Mauro Parra-Cordero; Marek Lubuski; Marian Kacerovsky; Francesc Figueras. Evaluation of the quality and reliability of middle cerebral artery and umbilical artery Doppler images within an international randomized controlled trial. Accepted Article.</a></li>
                    <li className="all y2019"><a href="https://pubmed.ncbi.nlm.nih.gov/31257961/" target="_blank">19. Monica Cruz-Lemini, Belen Nieto-Castro, Jonahtan Luna-Garcia, Israel Juarez-Martinez, Magdalena Martínez-Rivera, Ma de la Luz Bermudez-Rojas, Carlos Rebolledo-Fernández &amp; Rogelio Cruz-Martínez. Prenatal diagnosis of congenital heart defects: experience of the first Fetal Cardiology Unit in Mexico. The Journal of Maternal-Fetal &amp; Neonatal Medicine, https://doi.org/10.1080/14767058.2019.1638905. Año, 2019.</a></li>
                    <li className="all y2019"><a href="#" target="_blank">20. R Cruz-Martínez; M Martínez-Rodríguez, A Gámez-Varela , B Nieto-Castro , J Luna-García , I Juárez-Martínez , H López-Briones , R Guadarrama-Mora , J Torres-Torres , F Coronel-Cruz , D Ibarra-Rios , R Ordorica-Flores , J Nieto-Zermeño. Survival outcomes in severe left diaphragmatic hernia with and without fetoscopic tracheal occlusion in a country with suboptimal neonatal management. Accepted Article.</a></li>
                    <li className="all y2019"><a href="#" target="_blank">21. Rogelio Cruz-Martínez, Prenatal diagnosis of congenital anomalies in the era of fetal surgery in Mexico. Mexican Journal of Medical Research ICSa.</a></li>
                    <li className="all y2019"><a href="https://pubmed.ncbi.nlm.nih.gov/32663828/" target="_blank">22. Marta Rial-Cresteloa,Laura Garcia-Oterob,Annalisa Cancemib, Mariella Giannoneb Elena Escazzocchioc ,Alex Biternad ,Elena Ferriolse, Jose Luis Hernandeze, Marek Lubuskyf, Veronika Kolarovaf ,Ladislav Kroftag ,Jiri Vojtêchh ,Eyal Zohavi ,Karina Krajdenj ,Rogelio Cruz-Martinez ,Monica Cruz-Lemini ,Mauro Parra-Corderol ,Pamela Sociasm Fàtima ,Crispin Marta Lopeza ,Eduard Gratacósa, Francesc Figueras. Prescriptive Reference Standards of Third-Trimester Cerebroplacental Ratio and Its Physiological Determinants. Fetal Diagnosis and Therapy, DOI: 10.1159/000508366.</a></li>
                    <li className="all y2019"><a href="https://pubmed.ncbi.nlm.nih.gov/32449263/" target="_blank">23. N. Docheva, R. Cruz-Martinez, E. Hernandez-Andrade. The vaginal azygos artery: a common unrecognized finding during transvaginal ultrasound. Ultrasound in Obstetrics y Gynecology, https://doi.org/10.1002/uog.22098.</a></li>
                    <li className="all y2019"><a href="https://pubmed.ncbi.nlm.nih.gov/32798234/" target="_blank">24. R Cruz-Martínez; A Gámez-Varela , M Martínez-Rodríguez , J Luna-García , I Juárez-Martínez , H López-Briones, Chavez- Gonzalez, Erendira, Razo- Nava, Arelli, Villalobos- Gomez , Rosa, Favela- Heredia, Cesar. Doppler changes in the umbilical artery, middie cerebral artery, cerebroplacental ratio and ductus venosus during open fetal microneurosurgery for intrauterine myelomeningocele repair. Accepted Article.</a></li>
                    <li className="all y2019"><a href="https://obgyn.onlinelibrary.wiley.com/doi/abs/10.1002/pd.5806" target="_blank">25. R Cruz-Martínez; A Gámez-Varela , Monica Cruz-Lemini ,M Martínez-Rodríguez , J Luna-García , I Juárez-Martínez , H López-Briones, Chavez- Gonzalez Erendira, Chavelas-Ochoa Felipe, Aguilar-Vidales Karla,Chavez-Vega Joel, Castelo-Vargas Andrea,Rivera- CarrilloPaola, Hernandez- Andrade Edgar.Prenatal Diagnosis of laryngo- tracheo-esophageal anomalies in fetuses with congenital diaphragmatic hernia by ultrasound evaluation of the vocal cords and fetal laryngoesophagoscopy. Accepted Article.</a></li>
                    <li className="all y2019"><a href="https://www.karger.com/Article/FullText/513311" target="_blank">26. R Cruz-Martínez; A Gámez-Varela ,M Martínez-Rodríguez , J Luna-García , I Juárez-Martínez , H López-Briones, Chavez- Gonzalez Erendira, Chavelas-Ochoa Felipe, Aguilar-Vidales Karla,Chavez-Vega Joel, Castelo-Vargas Andrea ,Angel Augusto-Perez Calatayund,Manuel Alejandro Diaz- Carrillo,Edgar Ahumada- García, Carlos Rebolledo- Feranandez.Fetal open Microneurosurgery for intrauterine spina Bifida repair. Accepted Article.</a></li>
                    <li className="all y2019"><a href="https://www.medigraphic.com/cgi-bin/new/resumen.cgi?IDARTICULO=86411" target="_blank">27. R Cruz-Martínez, Secuestro broncopulmonar. Diagnóstico prenatal, factores pronóstico y tratamiento por cirugía fetal. Ginecología y Obstetricia, 2019 - search.ebscohost.com. </a></li>
                  </>
                  : <></>
                }

                {visible2018 ?
                  <>
                    <li className="all y2018"><a href="http://www.scielo.org.pe/scielo.php?script=sci_abstract&amp;pid=S2304-51322018000400017&amp;lng=es&amp;nrm=iso" target="_blank">28. R Cruz-Martínez.  Cirugía fetal para hernia diafragmática congénita en América Latina. Revista Peruana de Ginecología y Obstetricia, 2018 -  2018; 64 (4) 655DOI: https://doi.org/10.31403/rpgo.v64i2141.</a></li>
                    <li className="all y2018"><a href="https://pubmed.ncbi.nlm.nih.gov/30511776/" target="_blank">29. Rogelio Cruz‐Martínez, Miguel Martínez‐Rodríguez, Belén Nieto‐Castro, Alma Gámez‐Varela, Monica Cruz‐Lemini, Jonahtan Luna‐García, Israel Juárez‐Martínez. Longitudinal changes in lung size and intrapulmonary‐artery Doppler during the second half of pregnancy in fetuses with congenital diaphragmatic hernia. Prenatal Diagnosis. DOI: 10.1002/pd.540,Año 2018.</a></li>
                    <li className="all y2018"><a href="https://pubmed.ncbi.nlm.nih.gov/29572873/" target="_blank">30. Cruz-Lemini M, Cruz-Martinez R. Characterizing cardiac dysfunction in fetuses with left congenital diaphragmatic hernia. Prenatal Diagnosis 2018 May; 38(6):422-427. doi: 10.1002.</a></li>
                    <li className="all y2018"><a href="https://www.sciencedirect.com/science/article/abs/pii/S0736574817302800" target="_blank">31. Cubero-Rego L, Corsi-Cabrera M, Ricardo-Garcell J, Cruz-Martínez R, Harmony T. Visual evoked potentials are similar in polysomnographically defined quiet and active sleep in healthy newborns. Int J Dev Neurosci. 2018 Apr 23; 68:26-34.  doi: 10.1016/j.ijdevneu.2018.04.007.</a></li>
                    <li className="all y2018"><a href="https://www.karger.com/Article/Abstract/481170" target="_blank">32. Cruz-Martinez R, Nieto-Castro B, Martinez-Rodriguez M, Gámez-Varela A, Ahumada-Angulo E, Luna-García J, Pineda-Pérez  MJ, Rebolledo-Fernández C. Thoracic changes after full laser ablation of the feeding artery in fetuses with bronchopulmonary sequestration. Fetal Diagn Ther 2018, (Epub ahead of print).  doi: 10.1159/000481170</a></li>
                  </>
                  : <></>
                }
                {visible2017 ?
                  <>
                    <li className="all y2017"><a href="https://pubmed.ncbi.nlm.nih.gov/28890038/" target="_blank">33. Cubero-Rego L, Ricardo-Garcell J, Corsi-Cabrera M, Cruz-Martinez R, Rebolledo-Fernández C, Otero-Ojeda G, Harmony T. Improving the efficiency of Auditory Brainstem Responses in newborns, using a 60 clicks/s stimulation rate. J Clin Neurosci. 2017 Nov; 45:299-304. doi: 10.1016/j.jocn.2017.08.044</a></li>
                    <li className="all y2017"><a href="https://bmjopen.bmj.com/content/7/6/e014835" target="_blank">34. Figueras F, Gratacos E, Rial M, Gull I, Krofta L, Lubusky M, Cruz-Martínez R, Cruz-Lemini M, Martinez-Rodriguez M, Socias P, Aleuanlli C, Cordero MCP. Revealed versus concealed criteria for placental insufficiency in an unselected obstetric population in late pregnancy (RATIO37): randomised controlled trial study protocol. BMJ Open. 2017 Jun 15; 7(6):e014835. doi: 10.1136/bmjopen-2016-014835.</a></li>
                    <li className="all y2017"><a href="https://pubmed.ncbi.nlm.nih.gov/28573772/" target="_blank">35. Cruz-Martinez R. Reply. Percutaneous laser ablation of the feeding vessel in pulmonary sequestration or hybrid lesions. Ultrasound Obstet Gynecol. 2017 Jun; 49(6):811. doi: 10.1002/uog.17507. </a></li>
                    <li className="all y2017"><a href="https://pubmed.ncbi.nlm.nih.gov/28342715/" target="_blank">36. Palacio M, Bonet-Carne E, Cobo T, Perez-Moreno A, Sabrià J, Richter J, Kacerovsky M, Jacobsson B, García-Posada RA, Bugatto F, Santisteve R, Vives À, Parra-Cordero M, Hernandez-Andrade E, Bartha JL, Carretero-Lucena P, Tan KL, Cruz-Martínez R, Burke M, Vavilala S, Iruretagoyena I, Delgado JL, Schenone M, Vilanova J, Botet F, Yeo GS, Hyett J, Deprest J, Romero R, Gratacos E; Fetal Lung Texture Team. Prediction of Neonatal Respiratory Morbidity by Quantitative Ultrasound Lung Texture Analysis: A Multicenter Study. Am J Obstet Gynecol. 2017 Aug; 217(2):196.e1-196.e14. doi: 10.1016/j.ajog.2017.03.016 </a></li>
                    <li className="all y2017"><a href="https://obgyn.onlinelibrary.wiley.com/doi/full/10.1002/uog.16011" target="_blank">37. Cruz-Martinez R, Martínez-Rodríguez M, Bermúdez-Rojas M, Magaña-Abarca C, Narváez-Domínguez V, Rojas-Macedo A, Bautista-García N, Alcocer-Alcocer M. Fetal laser ablation of feeding artery of cystic lung lesions with systemic arterial blood supply. Ultrasound Obstet Gynecol. 2017 Jun; 49(6):744-750. doi: 10.1002/uog 16011</a></li>
                  </>
                  : <></>
                }

                {visible2016 ?
                  <>
                    <li className="all y2016"><a href="https://obgyn.onlinelibrary.wiley.com/doi/10.1002/pd.4771" target="_blank">38. Sananes N, Cruz-Martinez R, Favre R, Ordorica-Flores R, Moog R, Zaloszy A, Giron AM, Ruano R. Two-year outcomes after diagnostic and therapeutic fetal cystoscopy for lower urinary tract obstruction. Prenat Diagn. 2016 Apr; 36(4):297-303. doi: 10.1002/pd.4771. Epub 2016 Feb 17.</a></li>
                    <li className="all y2016"><a href="https://www.karger.com/Article/Abstract/441026" target="_blank">39. Cruz-Martinez R, Cruz-Lemini M, Mendez A, Illa M, Martinez JM, Gratacos E. Learning curve for intrapulmonary artery Doppler in fetuses with congenital diaphragmatic hernia. Fetal Diagn Ther 2016; 39(4):256-60. doi: 10.1159/000441026</a></li>
                  </>
                  : <></>
                }

                {visible2015 ?
                  <>
                    <li className="all y2015"><a href="https://obgyn.onlinelibrary.wiley.com/doi/10.1002/uog.14920" target="_blank">40. Cruz-Martinez R, Tenorio V, Padilla N, Crispi F, Figueras F, Gratacos E. Risk of ultrasound-detected neonatal brain abnormalities in intrauterine growth restricted fetuses born between 28 and 34 weeks: relationship with gestational age at birth and fetal Doppler parameters. Ultrasound Obstet Gynecol. 2015 Oct; 46(4):452-9. doi: 10.1002/uog.14920</a></li>
                    <li className="all y2015"><a href="https://pubmed.ncbi.nlm.nih.gov/26046453/" target="_blank">41. Cruz-Martinez R, Méndez A, Rebolledo-Fernández C, Dueñas-Riaño J, Ordorica-Flores R, Nieto-Zermeño J, Malagón-Salazar P, Medina-Noyola C. Fetal laser surgery prevents fetal death and avoids the need for neonatal sequestrectomy in cases with bronchopulmonary sequestration. Ultrasound Obstet Gynecol. 2015 Nov; 46(5):627-8. doi: 10.1002/uog.14921</a></li>
                    <li className="all y2015"><a href="https://www.karger.com/Article/Abstract/362387" target="_blank">42. Cruz-Martinez R, Moreno-Alvarez O, Garcia M, Pineda H, Cruz MA, Martinez-Morales C. Fetal Endoscopic Tracheal Intubation: A New Fetoscopic Procedure to Ensure Extrauterine Tracheal Permeability in a Case with Congenital Cervical Teratoma. Fetal Diagn Ther 2015; 38(2):154-8. doi: 10.1159/000362387</a></li>
                    <li className="all y2015"><a href="https://pubmed.ncbi.nlm.nih.gov/25346413/" target="_blank">43. Cruz-Martinez R, Savchev S, Cruz-Lemini M, Mendez A, Gratacos E and Figueras F. Clinical utility of third trimester uterine artery Doppler in the prediction of brain hemodynamic deterioration and adverse perinatal outcome in small-for-gestational-age fetuses. Ultrasound Obstet Gynecol 2015 Mar; 45(3):273-8. doi: 10.1002/uog.14706</a></li>
                    <li className="all y2015"><a href="https://www.medigraphic.com/cgi-bin/new/resumenI.cgi?IDARTICULO=55477" target="_blank">44. Cruz-Martinez R, Mendez A, Pineda-Alemán H, Rebolledo-Fernández C. The EXIT procedure: Indications, limitations, risks and progress to the fetal endoscopical tracheal intubation. Gynecol Obstet Mex 2015; 83:58-65.</a></li>
                    <li className="all y2015"><a href="https://www.karger.com/Article/Abstract/361015" target="_blank">45. Cruz-Martinez R, Méndez A, Perez-Garcilita O, Monroy A, Aguilar-Vidales K, Cruz-Martinez MA, Martinez-Morales C. Fetal Bronchoscopy as a Useful Procedure in a Case with Prenatal Diagnosis of Congenital Microcystic Adenomatoid Malformation.  Fetal Diagn Ther. 2015; 37(1):75-80. doi: 10.1159/000361015</a></li>
                  </>
                  : <></>
                }

                {visible2014 ?
                  <>
                    <li className="all y2014"><a href="https://pubmed.ncbi.nlm.nih.gov/25449115/" target="_blank">46. Cruz-Martinez R. Re: The influence of parity on fetal haemodynamics and amniotic fluid volume at term. Prior T, Mullins E, Bennett P, Kumar S. Ultrasound Obstet Gynecol 2014 Dec; 44(6):631. doi: 10.1002/uog.14709.</a></li>
                    <li className="all y2014"><a href="https://www.medigraphic.com/pdfs/ginobsmex/gom-2014/gom145f.pdf" target="_blank">47. Cruz-Martínez R, Gratacos E. Endoscopic Fetal Surgery. Gynecol Obstet Mex 2014 May; 82(5):325-36.</a></li>
                    <li className="all y2014"><a href="https://www.nature.com/articles/pr2013244" target="_blank">48. Prat Ortells J, Albert A, Tarrado X, Krauel L, Cruz R, Moreno-Álvarez Ó, Fuste V, Castañón M. Airway and vascular maturation stimulated by tracheal occlusion do not correlate in the rabbit model of diaphragmatic hernia. Pediatr Res. 2014 Apr; 75(4):487-92. doi: 10.1038</a></li>
                    <li className="all y2014"><a href="https://pubmed.ncbi.nlm.nih.gov/24293263/" target="_blank">49. Benavides-Serralde JA, Hernandez-Andrade E, Cruz-Martinez R, Cruz-Lemini M, Scheier M, Figueras F, Mancilla J, Gratacos E. Doppler evaluation of the posterior cerebral artery in normally grown and growth restricted fetuses. Prenatal Diagnosis 2014 Feb; 34(2):115-20. doi: 10.1002/pd.4265</a></li>
                  </>
                  : <></>
                }

                {visible2013 ?
                  <>
                    <li className="all y2013"><a href="https://pubmed.ncbi.nlm.nih.gov/23303527/" target="_blank">50. Savchev S, Sanz-Cortes M, Cruz-Martinez R, Arranz A, Botet F, Gratacos E, Figueras F. Neurodevelopment outcome of full-term small-for gestational-age infants with normal placental function. Ultrasound Obstet Gynecol 2013 Aug; 42(2):201-6. doi: 10.1002/uog.12391</a></li>
                    <li className="all y2013"><a href="https://pubmed.ncbi.nlm.nih.gov/22689226/" target="_blank">51. Cruz-Martinez R, Castañon M, Moreno-Alvarez O, Acosta-Rojas R, Martinez JM, Gratacos E. Usefulness of lung-to-head ratio and intrapulmonary Doppler in predicting neonatal morbidity in fetuses with congenital diaphragmatic hernia treated with fetoscopic tracheal occlusion. Ultrasound Obstet Gynecol 2013 Jan; 41(1):59-65. doi: 10.1002/uog.11212</a></li>
                  </>
                  : <></>
                }


                {visible2012 ?
                  <>
                    <li className="all y2012"><a href="https://www.karger.com/Article/Abstract/333001" target="_blank">52. Cruz-Lemini M, Crispi F, Van Mieghem T, Pedraza T, Cruz-Martínez R, Acosta-Rojas R, Figueras F, Parra-Cordero M, Deprest J, Gratacos E. Risk of perinatal death in early-onset intrauterine growth restriction according to gestational age and cardiovascular Doppler indices: a multicenter study. Fetal Diagn Ther 2012 Jul; 32(1-2):116-22. doi: 10.1159/000333001</a></li>
                    <li className="all y2012"><a href="https://www.karger.com/Article/Abstract/330798" target="_blank">53. Cruz-Martinez R, Figueras F, Bennasar M, Garcia-Posadas R, Crispi F, Hernández-Andrade E, Gratacos E. Normal reference ranges from 11 to 41 weeks’ gestation of fetal left modified myocardial performance index by conventional Doppler with the use of stringent criteria for delimitation of the time periods. Fetal Diagn Ther 2012 Jul; 32(1-2):79-86. doi: 10.1159/000330798</a></li>
                    <li className="all y2012"><a href="https://www.karger.com/Article/Fulltext/330792" target="_blank">54. Hernandez-Andrade E, Benavides-Serralde JA, Cruz-Martinez R, Welsh A, Mancilla-Ramirez J. Evaluation of Conventional Doppler Fetal Cardiac Function Parameters: E/A Ratios, Outflow Tracts, and Myocardial Performance Index. Fetal Diagn Ther 2012 Jul; 32(1-2):22-9. doi: 10.1159/000330792</a></li>
                    <li className="all y2012"><a href="https://pubmed.ncbi.nlm.nih.gov/22102177/" target="_blank">55. Savchev S, Figueras F, Cruz-Martinez R, Illa M, Botet F, Gratacos E. Estimated fetal weight percentile predicts perinatal outcome in term, small-for-gestational age fetuses with normal umbilical, uterine and brain Doppler. Ultrasound Obstet Gynecol 2012 March; 39(3):299-303. doi: 10.1002/uog.10150</a></li>
                    <li className="all y2012"><a href="https://obgyn.onlinelibrary.wiley.com/doi/10.1002/pd.2913" target="_blank">56. Hernández-Andrade E, Benavides Serralde A, Cruz-Martínez R. Can anomalies of fetal brain circulation be useful in the management of intrauterine growth restriction? Prenatal Diagnosis 2012 Feb; 32(2):103-12. doi: 10.1002/pd.2913</a></li>
                    <li className="all y2012"><a href="https://pubmed.ncbi.nlm.nih.gov/21910146/" target="_blank">57. Van Mieghem T, Cruz-Martinez R, Allegaert K, Dekoninck P, Castañon M, Sandaite I, Claus F, Devlieger R, Gratacos E, Deprest J. Outcomes of fetuses with congenital diaphragmatic hernia and associated intra-fetal fluid effusions managed in the era of fetal surgery. Ultrasound Obstet Gynecol 2012 Jan; 39(1):50-5. doi: 10.1002/uog.10097</a></li>
                  </>
                  : <></>
                }

                {visible2011 ?
                  <>
                    <li className="all y2011"><a href="https://pubmed.ncbi.nlm.nih.gov/21620362/" target="_blank">58. Comas M, Crispi F, Cruz-Martínez R, Martinez JM, Figueras F, Gratacos E. Tissue Doppler echocardiographic markers of cardiac dysfunction in small-for-gestational age fetuses. Am J Obstet Gynecol 2011 Jul; 205(1):57.e1-6. doi: 10.1016/j.ajog.2011.03.010 </a></li>
                    <li className="all y2011"><a href="https://obgyn.onlinelibrary.wiley.com/doi/full/10.1002/uog.8976" target="_blank">59. Cruz-Martinez R, Figueras F, Hernández-Andrade E, Gratacos E. Changes in myocardial performance index, aortic isthmus and ductus venosus in term, small-for-gestational age fetuses with normal umbilical artery Doppler. Ultrasound Obstet Gynecol 2011 Oct; 38(4):400-5. doi: 10.1002/uog.8976</a></li>
                    <li className="all y2011"><a href="https://obgyn.onlinelibrary.wiley.com/doi/10.1002/uog.9041" target="_blank">60. Figueras F, Cruz-Martinez R, Sanz-Cortes M, Arranz A, Botet F, Gratacos E. Neurobehavioral outcomes in preterm, growth-restricted infants with and without prenatal advanced signs of brainsparing. Ultrasound Obstet Gynecol 2011 Sep; 38(3):288-94. doi: 10.1002/uog.9041</a></li>
                    <li className="all y2011"><a href="https://obgyn.onlinelibrary.wiley.com/doi/full/10.1002/uog.8922" target="_blank">61. Cruz-Martinez R, Van Mieghem T, Lewi L, Eixarch E, Martinez JM, Deprest J, Gratacós E. Incidence and clinical implications of acute unintended septostomy after fetoscopic laser therapy for twin-twin transfusion syndrome. Ultrasound Obstet Gynecol 2011 Apr; 37(4):458-62. doi: 10.1002/uog.8922</a></li>
                    <li className="all y2011"><a href="https://www.karger.com/Article/Abstract/324102" target="_blank">62. Rodrigues HC, Deprest J, Cruz-Martínez R, van den Berg PP. Use of Data from Predictive Tests following Fetoscopic Endoluminal Tracheal Occlusion for Congenital Diaphragmatic Hernia. Fetal Diagn Ther 2011 Apr; 29(3):261-2. doi: 10.1159/000324102</a></li>
                    <li className="all y2011"><a href="https://pubmed.ncbi.nlm.nih.gov/21154784/" target="_blank">63. Cruz-Martinez R, Figueras F, Benavides-Serralde A, Crispi F, Hernández-Andrade E, Gratacos E. Sequence of changes in myocardial performance index, aortic isthmus and ductus venosus Doppler in fetuses with early-onset intrauterine growth restriction. Ultrasound Obstet Gynecol 2011 Aug; 38(2):179-84. doi: 10.1002/uog.8903</a></li>
                    <li className="all y2011"><a href="https://pubmed.ncbi.nlm.nih.gov/21346314/" target="_blank">64. Benavides-Serralde A, Sheier M, Cruz-Martinez R, Crispi F, Figueras F, Gratacos E, Hernandez-Andrade E. Changes in central and peripheral circulation in intrauterine growth-restricted fetuses at different stages of umbilical artery flow deterioration: New fetal cardiac and brain parameters. Gynecol Obstet Invest 2011 Jun; 71(4):274-80. doi: 10.1159/000323548</a></li>
                    <li className="all y2011"><a href="https://pubmed.ncbi.nlm.nih.gov/21343765/" target="_blank">65. Cruz-Martínez R, Figueras F, Hernández-Andrade E, Oros D, Gratacos E. Fetal brain Doppler to predict cesarean delivery for nonreassuring fetal status in term small-for-gestational-age fetuses. Obstet Gynecol 2011 Mar; 117(3):618-26. doi: 10.1097/AOG.0b013e31820b0884</a></li>
                    <li className="all y2011"><a href="https://pubmed.ncbi.nlm.nih.gov/20922780/" target="_blank">66. Cruz-Martinez R, Figueras F, Mendez A, Jaramillo J, Meler E, Hernández-Andrade E, Gratacos E. Learning curve for Doppler calculation of the fetal myocardial performance index. Ultrasound Obstet Gynecol 2011 Feb; 37(2):158-62. doi: 10.1002/uog.7765</a></li>
                    <li className="all y2011"><a href="https://www.karger.com/Article/Abstract/320249" target="_blank">67. Cruz-Martinez R, Hernández-Andrade E, Moreno-Alvarez O, Done E, Deprest J, Gratacos E. Prognostic value of pulmonary Doppler to predict response to tracheal occlusion in fetuses with congenital diaphragmatic hernia. Fetal Diagn Ther 2011 Feb; 29(1):18-24. doi: 10.1159/000320249</a></li>
                    <li className="all y2011"><a href="https://www.karger.com/Article/Abstract/295262" target="_blank">68. Cruz-Martinez R, Moreno-Alvarez O, Hernandez-Andrade E, Castañon M, Done E, Deprest J, Gratacos E. Lung tissue perfusion in prediction of survival in fetuses with congenital diaphragmatic hernia treated with fetal endoscopic tracheal occlusion (FETO). Fetal Diagn and Ther 2011 Feb; 29(1):101-7. doi: 10.1159/000295262</a></li>
                    <li className="all y2011"><a href="https://pubmed.ncbi.nlm.nih.gov/20617509/" target="_blank">69. Oros D, Figueras F, Cruz-Martinez R, Muntmany M, Arranz A, Gratacos E. Longitudinal changes in uterine, umbilical and fetal cerebral Doppler indices in late-onset small-for-gestational age fetuses. Ultrasound Obstet Gynecol 2011 Feb;37(2):191-5.doi: 10.1002/uog.7738</a></li>
                    <li className="all y2011"><a href="https://obgyn.onlinelibrary.wiley.com/doi/full/10.1002/uog.7722" target="_blank">70. Cruz-Martinez R, Figueras F, Hernández-Andrade E, Benavides-Serralde A, Gratacos E. Normal references ranges of fetal regional cerebral blood perfusion using power Doppler ultrasound as measured by Fractional Moving Blood Volume. Ultrasound Obstet Gynecol 2011 Feb;37(2):196-201. doi: 10.1002/uog.7722</a></li>
                    <li className="all y2011"><a href="https://www.karger.com/Article/Abstract/320605" target="_blank">71. Claus F, Sandaite I, Dekoninck P, Moreno O, Cruz Martinez R, Van Mieghem T, Gucciardo L, Richter J, Michielsen K, Decraene J, Devlieger R, Gratacos E, Deprest JA. Prenatal Anatomical Imaging in Fetuses with Congenital Diaphragmatic Hernia. Fetal Diagn Ther 2011 Feb; 29(1):88-100. doi: 10.1159/000320605</a></li>
                  </>
                  : <></>
                }

                {visible2010 ?
                  <>
                    <li className="all y2010"><a href="https://obgyn.onlinelibrary.wiley.com/doi/full/10.1002/pd.2651" target="_blank">72. Srisupundit K, Brady PD, Devriendt K, Fryns JP, Cruz-Martinez R, Gratacos E, Deprest JA, Vermeesch JR. Targeted array comparative genomic hybridisation (array CGH) identifies genomic imbalances associated with isolated CDH. Prenatal Diagnosis 2010 Dec;30(12-13):1198-206.doi: 10.1002/pd.2651</a></li>
                    <li className="all y2010"><a href="https://pubmed.ncbi.nlm.nih.gov/20451892/" target="_blank">73. Comas M, Crispi F, Cruz-Martínez R, Martinez JM, Figueras F, Gratacos E. Usefulness of myocardial tissue Doppler versus conventional echocardiography in the evaluation of cardiac dysfunction in early-onset intrauterine growth restriction. Am J Obstet Gynecol 2010 Jul; 203 (1):45.e1-7. Doi: 10.1016/j.ajog.2010.02.044</a></li>
                    <li className="all y2010"><a href="https://pubmed.ncbi.nlm.nih.gov/20435282/" target="_blank">74. Cruz-Martinez R, Figueras F, Oros D, Meler E, Hernández-Andrade E, Gratacos E. Longitudinal brain perfusion changes in near-term small-for-gestational-age fetuses as measured by spectral Doppler indices or by Fractional Moving Blood Volume. Am J Obstet Gynecol 2010 Jul; 203 (1):42.e1-6. Doi: 10.1016/j.ajog.2010.02.049</a></li>
                    <li className="all y2010"><a href="https://pubmed.ncbi.nlm.nih.gov/20131334/" target="_blank">75. Cruz-Martinez R, Figueras F, Moreno-Alvarez O, Gomez O, Martinez JM, Gratacos E. Learning curve of the lung area to head circumference ratio measurement in fetuses with congenital diaphragmatic hernia. Ultrasound Obstet Gynecol 2010 Jul;36(1):32-6. Doi: 10.1002/uog.7577</a></li>
                    <li className="all y2010"><a href="https://obgyn.onlinelibrary.wiley.com/doi/full/10.1002/uog.7593" target="_blank">76. Cruz-Martinez R, Moreno-Alvarez O, Castañon M, Done E, Hernandez-Andrade E, Deprest J, Gratacos E. Contribution of lung Doppler in prediction of survival in fetuses with congenital diaphragmatic hernia treated with tracheal occlusion. Ultrasound Obstet Gynecol 2010 May; 35(5):572-577. </a></li>
                    <li className="all y2010"><a href="https://pubmed.ncbi.nlm.nih.gov/20178113/" target="_blank">77. Moreno-Alvarez O, Cruz-Martinez R, Done E, Gómez O, Hernandez-Andrade E, Deprest J, Gratacos E. Lung tissue perfusion in congenital diaphragmatic hernia and association with the lung to head ratio and intrapulmonary pulsed Doppler. Ultrasound Obstet Gynecol 2010 May; 35:578-582.</a></li>
                    <li className="all y2010"><a href="https://pubmed.ncbi.nlm.nih.gov/20178115/" target="_blank">78. Oros D, Figueras F, Cruz-Martinez R, Meler E, Hernández-Andrade E, Gratacos E. Middle versus anterior cerebral artery for the prediction of adverse outcome in term small-for-gestational-age fetuses. Ultrasound Obstet Gynecol 2010 Apr; 35:456-461. </a></li>
                  </>
                  : <></>
                }

                {visible2009 ?
                  <>
                    <li className="all y2009"><a href="https://www.karger.com/Article/Abstract/254485" target="_blank">79. Cruz-Martinez R, Moreno-Alvarez O, Prat J, Krauel L, Tarrado X, Castañón M, Hernandez-Andrade E, Albert A, Gratacos E. Lung tissue blood perfusion changes induced by in utero tracheal occlusion in a rabbit model of congenital diaphragmatic hernia. Fetal Diagn Ther 2009 Dec; 26(3):137-142.</a></li>
                    <li className="all y2009"><a href="https://pubmed.ncbi.nlm.nih.gov/19767241/" target="_blank">80. Cruz-Martinez R, Figueras F. Role of Doppler and Placental screening in intrauterine growth restriction. Best Pract Res Clin Obstet Gynecol 2009 Dec; 23(6):845-55.</a></li>
                    <li className="all y2009"><a href="https://pubmed.ncbi.nlm.nih.gov/19631930/" target="_blank">81. Cruz-Martinez R, Figueras F, Oros D, Meler E, Padilla N, Hernández-Andrade E, Gratacos E. Cerebral blood perfusion and neurobehavioral performance in full term small for gestational age fetuses. Am J Obstet Gynecol 2009 Nov; 201(5):474.e1-7.</a></li>
                    <li className="all y2009"><a href="https://pubmed.ncbi.nlm.nih.gov/19858151/" target="_blank">82. Figueras F, Oros D, Cruz-Martinez R, Padilla N, Botet, Costas M, Gratacos E. Neurobehavior in term small-for-gestational age infants with normal placental function. Pediatrics 2009 Nov; 124(5):e934-41.</a></li>
                    <li className="all y2009"><a href="https://pubmed.ncbi.nlm.nih.gov/19658113/" target="_blank">83. Jani J, Nicolaides KH, Gratacos E, Valencia C, Done E, Cruz R, Deprest JA. Severe diaphragmatic hernia treated with fetal endoscopic tracheal occlusion. Ultrasound Obstet Gynecol 2009 Sep; 34:304-310.</a></li>
                  </>
                  : <></>
                }

                {visible2008 ?
                  <li className="all y2008"><a href="https://www.medigraphic.com/cgi-bin/new/resumen.cgi?IDARTICULO=19520" target="_blank">84. Cruz Martínez R, García N, Manzanares G. Embarazo Angular reporte de un caso y revisión bibliográfica. Ginecol Obstet Mex 2008 Jul; 76(7):417-20.</a></li>
                  : <></>
                }
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section id="fuve" className="downloadApp">
        <div className="fullBgSection">
          <picture>
            <source src="img/bg-download-app.webp" type="image/webp" />
            <source src="img/bg-download-app.jpg" type="image/jpeg" />
            <img
              src="img/bg-download-app.jpg"
              width="1951"
              height="565"
              alt="Ventajas y Beneficios"
            />
          </picture>
        </div>
        <div className="container">
          <div className="downAppWrap">
            <div className="downAppImg">
              <picture>
                <source src="img/img-phone-app2.webp" type="image/webp" />
                <source src="img/img-phone-app2.png" type="image/png" />
                <img
                  src="img/img-phone-app2.png"
                  width="278"
                  height="451"
                  alt=""
                />
              </picture>
            </div>
            <div className="downAppText">
              <h2 data-wow-delay="0.25s" className="headerComp wow fadeInDown">
                <span>&nbsp;</span>
                <strong>FUVE APP</strong>
              </h2>
              <p data-wow-delay="0.25s" className="wow fadeInUp">
                FUVE es una nueva herramienta que permitirá reducir la curva de
                aprendizaje maximizando tus conocimientos en ultrasonido y
                emitir así un diagnostico preciso para tus pacientes.
              </p>
              <br></br>
              <a
                href="https://www.fuve.app/"
                target="_blank"
                data-wow-delay="0.75s"
                className="btn wow fadeInUp"
                style={{
                  visibility: "visible",
                  animationDelay: "0.75s",
                  animationName: "fadeInUp",
                }}
              >
                FUVE
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Investigation;
