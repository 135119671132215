import { useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Course } from '../../backend/courses';
import CourseBlocks from './CourseBlocks';
import CourseContact from './CourseContact';
import CoursePrice from './CoursePrice';
import CourseProgram from './CourseProgram';
import CourseRequirements from './CourseRequirements';
import { useEffect, useState } from "react";
import CongressInfo from './CongressInfo';
import CoursePhotos from './CoursePhotos';

type props = {
    course?: Course;
}

function CourseInfo(props: props) {
    const { courseid } = useParams();
    const [congress, setCongress] = useState(false);
    const [form, setForm] = useState(false);

    useEffect(() => {
        if (window.location.href == "http://localhost:3000/vc/courses/aTL2HT3ibBBFmm0xZ8Z2" || window.location.href == "https://new.medicinafetalmexico.com/vc/courses/aTL2HT3ibBBFmm0xZ8Z2") {
            setCongress(true);
        }
        else {
            setCongress(false);
        }

        if (props.course?.signupForm == "") {
            setForm(false);
        }
        else {
            setForm(true);
        }
        console.log("url:" + props.course?.signupForm);

    }, [window.location.href, props.course?.signupForm]);

    return (
        <div className="course-info">
            <div className='course-info-container'>
                <Tabs defaultIndex={1} className="main-tabs">
                    <TabList>
                        <Tab className='tab' key='Detalles'><span color="#003C89">Información</span></Tab>
                        <Tab className='tab' key='Programa'><span color="#003C89">Programa</span></Tab>
                        {/*<Tab className='tab' key='Requisitos'><span color="#003C89">Requisitos</span></Tab>*/}
                        {/* <Tab className='tab' key='Precio'><span color="#003C89">Precio</span></Tab> */}
                        <Tab className='tab' key='Precio'><span color="#003C89">Contacto</span></Tab>
                        {
                            congress ?
                                <Tab className='tab' key='Precio'><span color="#003C89">Congresos Pasados</span></Tab>
                                :
                                <>
                                </>
                        }
                        {
                            congress ?
                                <Tab className='tab' key='Precio'><span color="#003C89">Fotos</span></Tab>
                                :
                                <>
                                </>
                        }
                        {
                            form ?
                                <div onClick={() => window.open(`${props.course?.signupForm}`)} className='tab' key='Convocatoria'><span color="#003C89">Inscripción</span></div>
                                :
                                <></>
                        }
                        {/* <div onClick={() => window.open(`https://www.virtualcampusmfm.com/courses/${courseid}`)} className='tab' key='Convocatoria'><span color="#003C89">Inscripción</span></div> */}

                    </TabList>
                    <TabPanel>
                        <CourseBlocks course={props.course} />
                    </TabPanel>
                    <TabPanel>
                        <CourseProgram levels={props.course?.levels || []} />
                    </TabPanel>
                    {
                        /* <TabPanel>
                        <CoursePrice course={props.course} />
                    </TabPanel>*/
                    }
                    <TabPanel>
                        <CourseContact />
                    </TabPanel>
                    <TabPanel>
                        <CongressInfo />
                    </TabPanel>
                    <TabPanel>
                        <CoursePhotos />
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    );
}

export default CourseInfo;