import { useNavigate } from "react-router-dom";
import { CourseShort } from "../../backend/courses";
import { scrollToTop } from "../../tools/Scroll";

type props = {
    courses: CourseShort[],
    coursesToDraw: string[],
    title: string
    width?: string
    height?: string
    maxWidth?: string
}

function CoursesGroup(props: props) {
    const navigate = useNavigate();
    return (
        <div className="courses-group">
            <div className="suggest-grid-container" style={{"marginBottom": "20px"}}>
                { 
                    props.courses.filter(course => props.coursesToDraw.includes(course.id)).map((suggestedCourse, index) => (
                    <div key={"suggest-" + index} className="suggest-img-container" style={{"width": props.width, "height": props.height, "maxWidth": props.maxWidth, "borderRadius": "30px"}} onClick={() => { navigate("/vc/courses/" + suggestedCourse.id); scrollToTop(); }}>
                        <img src={suggestedCourse.thumbnail} height="233px"/>
                        <div className="suggest-text-container">{suggestedCourse.title}</div>
                    </div>
                    ))
                }
            </div>
        </div>
    );
}

export default CoursesGroup;