import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProfessors, Professor } from "../backend/professors";
import Specialist from "../components/specialists/Specialist";
import SpecialistsGrid from "../components/specialists/SpecialistsGrid";
import Loading from "../components/_main/Loading";

function Specialists() {
    const { professorid } = useParams();
    const [professors, setProfessors] = useState<Professor[]>([]);
    const [done, setDone] = useState(false);

    const downloadData = async () => {
        setProfessors(await getProfessors());
        setDone(true);
    }

    useEffect(() => {
        if (!done) {
            downloadData();
        }
    }, [professors, done]);

    return ( 
        <>
            {(done) ? (professorid)
                ? <Specialist professor={professors.filter(professor => professor.id === professorid)[0]}/>
                : <SpecialistsGrid professors={professors}/>
            : <Loading/>}
        </>
     );
}

export default Specialists;