import './styles/Footer.css'
import { useEffect, useState } from "react";

function Footer() {

    const [logo, setLogo] = useState("");
    const [tel, setTel] = useState("");
    const [contact, setContact] = useState("");
    const [information, setInformation] = useState("");

    const call = (tel: string) => {
        console.log("tel:" + tel);
        var call_app = window.open("tel:" + tel, "_self");
        call_app!.close();
    }

    useEffect(() => {
        if(window.location.href == "http://localhost:3000/contact" || window.location.href == "https://new.medicinafetalmexico.com/contact" || window.location.href == "https://new.medicinafetalmexico.com/privacy" || window.location.href == "http://localhost:3000/privacy")
        {
            setLogo("/img/medicinafetal-white.png");
            setTel("(33) 281 522 00");
            setContact("+523328152200");
            setInformation("https://medicinafetalmexico.com/contacto");
        }    
        else
        {
            setLogo("/img/logo-mfm-blanco.png");
            setTel("(442) 473 92 04");
            setContact("+524424739204");
            setInformation("/information");
        }   
        
    }, [window.location.href]);

    return (
        <div className="footer">
            <div className="footer-schedule">
                <div className='footer-schedule-container-text'>
                    <i
                        data-wow-delay="0.25s"
                        className="icon-mfm5 wow fadeInLeft"
                        style={{ "visibility": "visible", "animationDelay": "0.25s", "animationName": "fadeInLeft", "fontSize": "3.3333333rem", "marginRight": "40px" }}></i>
                    <div className='footer-text-doubt'>¿Tienes algunas dudas?, Acércate</div>
                    <a href={information} className='footer-btn'>INFORMACIÓN</a>
                </div>
            </div>
            <div className="footer-info">
                <div className="footer-info-container">
                    <div className="footer-info-text">
                        <div className='social-media'>
                            <div className='img-mfm'>
                                <img width="250px" height="75px" src={process.env.PUBLIC_URL + logo} />
                            </div>
                            <div className='social-button-container'>
                                <div className='social-button'>
                                    <a href='https://www.instagram.com/medicinafetalmexico.educacion/'><img className='social-icon' width="22px" src={process.env.PUBLIC_URL + "/img/instagram.jpg"} /></a>
                                </div>
                                <div className='social-button'>
                                    <a href='https://es-la.facebook.com/medicinafetalmexico.educacion/'><img className='social-icon' width="22px" src={process.env.PUBLIC_URL + "/img/facebook.png"} /></a>
                                </div>
                            </div>
                        </div>
                        <div className='footer-text-container'>
                            <div className='footer-text-paragraph'>
                                <div className='footer-title'>Acerca de</div>
                                <div className='paragraph'>
                                    <div>
                                        <a href='https://medicinafetalmexico.com/que-es-la-medicina-fetal'><div className='footer-text'>¿Qué es MFM?</div></a>
                                        <a href='/contact'><div className='footer-text'>Ubicación</div></a>
                                        <a href='https://medicinafetalmexico.com/especialistas'><div className='footer-text'>Especialistas</div></a>
                                    </div>
                                    <div>
                                        <a href='https://medicinafetalmexico.com/testimonios'><div className='footer-text'>Testimonios</div></a>
                                        <a href='https://cirugiafetalmexico.com.mx/investigacion'><div className='footer-text'>Investigación</div></a>
                                        <a href='/privacy'><div className='footer-text'>Privacidad</div></a>
                                    </div>
                                </div>
                            </div>
                            <div className='footer-text-paragraph'>
                                <div className='footer-title'>Enlaces</div>
                                <div className='paragraph'>
                                    <div>
                                        <a href='https://ipregnancymfm.com.mx/'><div className='footer-text'>iPregnancy</div></a>
                                        <a href='https://cirugiafetalmexico.com.mx/'><div className='footer-text'>Patologías fetales</div></a>
                                        <a><div className='footer-text'>Calculadoras Médicas</div></a>
                                        <a href='https://cirugiafetalmexico.com.mx/testimonios'><div className='footer-text'>Casos de éxito</div></a>
                                    </div>
                                    <div>
                                        <a href='/courses'><div className='footer-text'>Cursos</div></a>
                                        <a href='https://medicinafetalmexico.com/colaboraciones'><div className='footer-text'>Publicaciones</div></a>
                                    </div>
                                </div>
                            </div>
                            <div className='footer-text-paragraph'>
                                <div className='footer-title'>Información de contacto</div>
                                <div className='paragraph'>
                                    <div>
                                        <div className='footer-text'>
                                            Instituto Medicina Fetal México,
                                            San Gabriel #333,
                                            Col. Chapalita,
                                            CP 45040,
                                            Guadalajara, Jal.
                                            México
                                        </div>
                                        <div onClick={() => call(contact)} className='footer-text'> <i className="fa fa-phone footer-phone" aria-hidden="true" />{tel}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <div className="footer-copyright-container">
                    Medicina Fetal México © 2022. Todos los Derechos Reservados
                </div>
            </div>
        </div>
    );
}

export default Footer;