import './App.css';
import './styles.css';
import {
  Routes,
  Route
} from "react-router-dom";
import MFM from './pages/MFM';
import VC_Course from './pages/VC_Course';
import BannerTemplate from './templates/BannerTemplate'
import MainTemplate from './templates/MainTemplate';
import Specialists from './pages/Specialists';
import Docentes from './pages/Docentes';
import Courses from './pages/Courses';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import Contact from './pages/Contact';
import { useState } from 'react';
import Rotations from './pages/Rotations';
import Investigation from './pages/Investigation';
import Collaborations from './pages/Collaborations';
import Fellowship from './pages/Fellowship';
import Observership from './pages/Observership';
import Information from './pages/Information';
import Privacy from './pages/Privacy';

function App() {
  const [paypalOptions, setPaypalOptions] = useState({
    "client-id": "AW-B7zk45OX7A25B2XKt49EVLtot2NQ_0pCu70WkidummZ9woDhasUSVj8iXoHLp7EwaivbYUNvmQoDa",
    components: "buttons",
    currency: "MXN"
  });
  return (
    <div className="App">
      <>
        <PayPalScriptProvider options={paypalOptions}>
          <Routes>
            <Route path='/' element={<MainTemplate><MFM /></MainTemplate>}></Route>
            <Route path='/courses' element={
              <MainTemplate>
                <Courses/>
              </MainTemplate>
            } />
            <Route path='/vc/courses/:courseid' element={
              <MainTemplate>
                <VC_Course />
              </MainTemplate>
            } />
            <Route path='/specialists' element={
              <MainTemplate banner={<BannerTemplate src={process.env.PUBLIC_URL + "/img/banner-page-especialistas.jpg"} title="Tu bebé en manos de expertos especialistas" />}>
                <Specialists />
              </MainTemplate>
            } />
            <Route path='/specialists/:professorid' element={
              <MainTemplate>
                <Specialists />
              </MainTemplate>
            } />
            <Route path='/contact' element={
              <MainTemplate banner={<BannerTemplate src={process.env.PUBLIC_URL + "/img/banner-page-contacto.jpg"} title="Contacto" />}>
                <Contact />
              </MainTemplate>
            } />
            <Route path='/teachers' element={
              <MainTemplate banner={<BannerTemplate src={process.env.PUBLIC_URL + "/img/banner-page-especialistas.jpg"} title="Profesorado" />}>
                <Docentes />
              </MainTemplate>
            } />
            <Route path='/rotations' element={
              <MainTemplate banner={<BannerTemplate src={process.env.PUBLIC_URL + "/img/banner-page-rotaciones.jpg"} title="Estancias de rotación" subtitle="El número de plazas es limitado. Para optar a una plaza es necesario realizar la preinscipción rellenando el formulario." />}>
                <Rotations />
              </MainTemplate>
            } />
            <Route path='/investigations' element={
              <MainTemplate banner={<BannerTemplate src={process.env.PUBLIC_URL + "/img/banner-page-investigacion-05.jpg"} title="Investigación" />}>
                <Investigation />
              </MainTemplate>
            } />
            <Route path='/information' element={
              <MainTemplate banner={<BannerTemplate src={process.env.PUBLIC_URL + "/img/information.png"} title="Contáctanos" />}>
                <Information />
              </MainTemplate>
            } />
            <Route path='/collaborations' element={
              <MainTemplate banner={<BannerTemplate src={process.env.PUBLIC_URL + "/img/3.jpg"} title="Aumentamos el alcance de la Medicina Fetal con nuestros cursos de actualización." />}>
                <Collaborations />
              </MainTemplate>
            } />
            <Route path='/fellowship' element={
              <MainTemplate banner={<BannerTemplate src={process.env.PUBLIC_URL + "/img/3.jpg"} title="Estancias de rotación" subtitle="El número de plazas es limitado. Para optar a una plaza es necesario realizar la preinscipción rellenando el formulario." />}>
                <Fellowship />
              </MainTemplate>
            } />
            <Route path='/observership' element={
              <MainTemplate banner={<BannerTemplate src={process.env.PUBLIC_URL + "/img/3.jpg"} title="Estancias de rotación" subtitle="El número de plazas es limitado. Para optar a una plaza es necesario realizar la preinscipción rellenando el formulario." />}>
                <Observership />
              </MainTemplate>
            } />
            <Route path='/privacy' element={
              <MainTemplate>
                <Privacy/>
              </MainTemplate>
            } />
          </Routes>
        </PayPalScriptProvider>
      </>
    </div>
  );
}

export default App;