import { useEffect, useState } from "react";
import { CourseShort, getAllCourses } from "../backend/courses";
import CoursesGroup from "../components/courses/CoursesGroup";
import Loading from "../components/_main/Loading";
import { Carousel } from 'react-responsive-carousel';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles/Course.css";
import "./styles/Education.css";

function Courses() {
  const [courses, setCourses] = useState<CourseShort[]>([]);
  const [done, setDone] = useState(false);

  const downloadData = async () => {
    setCourses(await getAllCourses());
    setDone(true);
  };

  useEffect(() => {
    if (!done) {
      downloadData();
    }
  }, [courses, done]);

  return (
    <div>
      <Carousel className='carousel-slides' showArrows={false} showThumbs={false} showStatus={false} infiniteLoop autoPlay>
        <img src="img/01.jpg" />
        <img src="img/02.jpg" />
        <img src="img/03.jpg" />
        <img src="img/04.jpg" />
        <img src="img/05.jpg" />
        <img src="img/06.jpg" />
        <img src="img/07.jpg" />
        <img src="img/08.jpg" />
      </Carousel>
      <section className="introSimpleText minWidth">
        <div className="fullBgSection">
          <picture>
            <source src="img/bg-carousel-ipregnancy2.webp" type="image/webp" />
            <source src="img/bg-carousel-ipregnancy2.jpg" type="image/jpeg" />
            <img
              src="img/bg-carousel-ipregnancy2.jpg"
              width="1210"
              height="782"
              alt=""
            />
          </picture>
        </div>
        <div className="container">
          <div className="introSimpleTextCont">
            <h2
              data-wow-delay="0.25s"
              className="h3 hCenter headerComp wow fadeInDown"
            >
              <span>Cursos de Formación</span>
              <strong>
                Medicina Fetal México, como parte de su programa docente, ofrece
                una serie de cursos online y presenciales de formación básica y
                avanzada en el área de la Medicina Fetal.{" "}
              </strong>
            </h2>
            <p data-wow-delay="0.5s" className="wow fadeInUp">
              Todos nuestros cursos se orientan hacia un enfoque clínico:
              partiendo de una base teórica se sigue un guión lógico para
              encadenar conceptos y entender la sistemática de exploración y de
              clasificación de los posibles defectos.
            </p>
            <p data-wow-delay="0.5s" className="wow fadeInUp">
              Además de los cursos tienen un componente intensivo de casos
              prácticos con autoevaluación para que los alumnos consoliden
              conceptos y su capacidad para identificar imágenes hasta el nivel
              marcado en los objetivos. Nuestra oferta educativa va dirigida a
              ginecoobstetras, médicos materno fetales, radiologos médicos
              ultrasonografistas, residentes de ginecoobstetricia, residentes de
              medicina materno fetal y todo aquel interesado en formarse y
              actualizarse en la Medicina Materno fetal.
            </p>
          </div>
          <div className="threeBoxMenu">
            <div className="threeBoxMenuWrap">
              <a
                href="/courses"
                data-wow-delay="0.25s"
                className="threeBox wow fadeInUp"
              >
                <div className="threeBoxBG">
                  <picture>
                    <source src="img/bg-card-box.png" type="image/webp" />
                    <source src="img/bg-card-box.png" />
                    <img
                      src="img/bg-card-box.png"
                      width="317"
                      height="231"
                      alt=""
                    />
                  </picture>
                </div>
                <div className="threeBoxText">
                  <div className="threeBoxTextTitle">
                    <i className="icon-mfm10"></i>
                    <h3 className="h5">CURSOS, DIPLOMADOS Y POSGRADOS</h3>
                  </div>
                </div>
              </a>
              <a
                href="/collaborations"
                data-wow-delay="0.45s"
                className="threeBox wow fadeInUp"
              >
                <div className="threeBoxBG">
                  <picture>
                    <source src="img/bg-card-box.png" type="image/webp" />
                    <source src="img/bg-card-box.png" />
                    <img
                      src="img/bg-card-box.png"
                      width="317"
                      height="231"
                      alt=""
                    />
                  </picture>
                </div>
                <div className="threeBoxText">
                  <div className="threeBoxTextTitle">
                    <i className="icon-mfm13"></i>
                    <h3 className="h5">COLABORACIONES ACADÉMICAS</h3>
                  </div>
                </div>
              </a>
              <a
                href="/rotations"
                data-wow-delay="0.65s"
                className="threeBox wow fadeInUp"
              >
                <div className="threeBoxBG">
                  <picture>
                    <source src="img/bg-card-box.png" type="image/webp" />
                    <source src="img/bg-card-box.png" />
                    <img
                      src="img/bg-card-box.png"
                      width="317"
                      height="231"
                      alt=""
                    />
                  </picture>
                </div>
                <div className="threeBoxText">
                  <div className="threeBoxTextTitle">
                    <i className="icon-mfm11"></i>
                    <h3 className="h5">ESTANCIAS DE ROTACIÓN</h3>
                  </div>
                </div>
              </a>
              <a
                href="/investigations"
                data-wow-delay="0.65s"
                className="threeBox wow fadeInUp"
              >
                <div className="threeBoxBG">
                  <picture>
                    <source src="img/bg-card-box.png" type="image/webp" />
                    <source src="img/bg-card-box.png" />
                    <img
                      src="img/bg-card-box.png"
                      width="317"
                      height="231"
                      alt=""
                    />
                  </picture>
                </div>
                <div className="threeBoxText">
                  <div className="threeBoxTextTitle">
                    <i className="icon-mfm92"></i>
                    <h3 className="h5">INVESTIGACIÓN</h3>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* 
      
      <section className="webApp light" style={{ backgroundColor: "white" }}>
        <div className="container">
          <div className="webAppWrap">
            <div className="webAppLeft">
              <h2
                data-wow-delay="0.25s"
                className="h3 headerComp wow fadeInDown"
              >
                <span>4to Congreso Internacional</span>
                <strong>Medicina y Cirugía Fetal</strong>
              </h2>
              <p data-wow-delay="0.5s" className="wow fadeInUp">
                El Congreso tiene el principal objetivo de dar a conocer las
                nuevas técnicas de Cirugía Fetal que han sido recientemente
                desarrolladas, y discutir los resultados de los nuevos proyectos
                de investigación relacionados a esta área de la Medicina.
              </p>
            </div>

            <div data-wow-delay="0.25s" className="webAppRight wow fadeIn">
              <div className="webAppImg">
                <a href="/vc/courses/aTL2HT3ibBBFmm0xZ8Z2">
                  <picture>
                    <source src="img/Congreso_Cirugia.jpg" type="image/webp" />
                    <source src="img/Congreso_Cirugia.jpg" />
                    <img
                      src="img/Congreso.jpg"
                      width="800"
                      height="416"
                      alt="Web App"
                    />
                  </picture>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      */}

      {done ? (
        <div
          className="courses-groups-container"
          style={{ paddingBottom: "60px" }}
        >
          <h1 className="courses-group-title">Alta Especialidad</h1>
          <div style={{ display: "flex", gap: "25px" }}>
            <div style={{ width: "100%" }}>
              <CoursesGroup
                courses={courses}
                title="Alta Especialidad"
                coursesToDraw={premiumCourses}
                width="100%"
                height="auto"
                maxWidth="550px"
              />
            </div>
            {
              /*
              <a href="https://portal.medicinafetalbarcelona.org/">
                <div className="courses-group">
                  <div
                    className="suggest-grid-container"
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="suggest-img-container">
                      <img src="img/barcelona.jpg" height="233px" />
                      <div className="suggest-text-container">
                        Posgrado en Medicina Fetal
                      </div>
                    </div>
                  </div>
                </div>
                </a>
              */
            }
          </div>
          <br></br>
          <br></br>
          <br></br>
          <h1 className="courses-group-title">Cursos y Diplomados Master</h1>
          <CoursesGroup
            courses={courses}
            title="Cursos y Diplomados Master"
            coursesToDraw={onlineCourses}
          />
          <br></br>
          <br></br>
          <br></br>
          <h1 className="courses-group-title">Cursos Online</h1>
          <div className="courses-group">
            <CoursesGroup
              courses={courses}
              title="Cursos y Diplomados Master"
              coursesToDraw={masterCourses}
            />
            <a href="https://www.fuve.app/courses/6jPqbIFShqacV65aI312">
              <div className="courses-group">
                <div
                  className="suggest-grid-container"
                  style={{ marginBottom: "20px" }}
                >
                  <div className="suggest-img-container" style={{ "borderRadius": "30px" }}>
                    <img src="img/Fondo_Fuve.png" height="233px" />
                    <div className="suggest-text-container">
                      Curso de Ecografía Morfología
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <a href="https://www.fuve.app/courses/cn8rc85TNHwWnpjFcUQz">
              <div className="courses-group">
                <div
                  className="suggest-grid-container"
                  style={{ marginBottom: "20px" }}
                >
                  <div className="suggest-img-container" style={{ "borderRadius": "30px" }}>
                    <img src="img/Fondo_Fuve.png" height="233px" />
                    <div className="suggest-text-container">
                      Curso de Simulación en Patología Fetal
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      ) : (
        <Loading />
      )}

      <section className="webApp light">
        <div className="container">
          <div className="webAppWrap">
            <div className="webAppLeft">
              <h2
                data-wow-delay="0.25s"
                className="h3 headerComp wow fadeInDown"
              >
                <span>Visita nuestra web de</span>
                <strong>Campus Virtual</strong>
              </h2>
              <p data-wow-delay="0.5s" className="wow fadeInUp">
                Adaptando nuestros métodos de enseñanza, logramos posicionar
                nuestros cursos en una nueva plataforma de Campus Virtual
                diseñada principalmente para la educación continua a distancia.
                Prevenir, detectar y corregir posibles Afecciones.
              </p>
              <a
                href="https://www.virtualcampusmfm.com/"
                target="_blank"
                data-wow-delay="0.75s"
                className="btn wow fadeInUp"
              >
                Da click para ingresar
              </a>
            </div>
            <div data-wow-delay="0.25s" className="webAppRight wow fadeIn">
              <div className="webAppImg">
                <picture>
                  <source src="img/img-campus-virtual.webp" type="image/webp" />
                  <source src="img/img-campus-virtual.jpg" />
                  <img
                    src="img/img-campus-virtual.jpg"
                    width="800"
                    height="416"
                    alt="Web App"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <section id="fuve" className="downloadApp">
        <div className="fullBgSection">
          <picture>
            <source src="img/bg-download-app.webp" type="image/webp" />
            <source src="img/bg-download-app.jpg" type="image/jpeg" />
            <img
              src="img/bg-download-app.jpg"
              width="1951"
              height="565"
              alt="Ventajas y Beneficios"
            />
          </picture>
        </div>
        <div className="container">
          <div className="downAppWrap">
            <div className="downAppImg">
              <picture>
                <source src="img/img-phone-app2.webp" type="image/webp" />
                <source src="img/img-phone-app2.png" />
                <img
                  src="img/img-phone-app2.png"
                  width="278"
                  height="451"
                  alt=""
                />
              </picture>
            </div>
            <div className="downAppText">
              <h2 data-wow-delay="0.25s" className="headerComp wow fadeInDown">
                <span>&nbsp;</span>
                <strong>FUVE APP</strong>
              </h2>
              <p data-wow-delay="0.25s" className="wow fadeInUp">
                FUVE es una nueva herramienta que permitirá reducir la curva de
                aprendizaje maximizando tus conocimientos en ultrasonido y
                emitir así un diagnostico preciso para tus pacientes.
              </p>
              <br></br>
              <a
                href="https://www.fuve.app/"
                target="_blank"
                data-wow-delay="0.75s"
                className="btn wow fadeInUp"
                style={{
                  visibility: "visible",
                  animationDelay: "0.75s",
                  animationName: "fadeInUp",
                }}
              >
                FUVE
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="webApp light">
        <div className="container">
          <div className="webAppWrap">
            <div className="webAppLeft">
              <h2
                data-wow-delay="0.25s"
                className="h3 headerComp wow fadeInDown"
              >
                <span>Visita nuestra web de</span>
                <strong>ORVO</strong>
              </h2>
              <p data-wow-delay="0.5s" className="wow fadeInUp">
                Este software realiza procedimientos realistas utilizando
                modelos de simulación quirúrgica avanzada. Una oportunidad
                novedosa para adquirir las habilidades necesarias para completar
                la curva de aprendizaje con una experiencia "práctica".
              </p>
              <a
                href="https://www.orvoscopy.com/"
                target="_blank"
                data-wow-delay="0.75s"
                className="btn wow fadeInUp"
              >
                ORVO
              </a>
            </div>
            <div data-wow-delay="0.25s" className="webAppRight wow fadeIn">
              <div className="webAppImg">
                <picture>
                  <source src="img/orvo.jpg" type="image/webp" />
                  <source src="img/orvo.jpg" />
                  <img src="img/orvo.jpg" width="278" height="251px" alt="" />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

const premiumCourses = [
  "Q8vLxwjGO3Q0KoE74t3v",
  "FEat48AooOnuskFGwA85"
];

const onlineCourses = [

  "hD8gUSXsS4ETtU5WH1Te",
  "iqmiqeGOFJYdSMTmKwDW",
  "Mpu4XSRwKnvtukQMtlCy",
  "KveAc8g3V55zluipAznG",
  "1Yar9OSIvNcsRElscQpV",
  "3qtgdWNnQNst4bM36WNW",
  "qkq1dLRZqHqJQ7dVI1b7",
  "yjZ4tKmW6zCRDlhphLpK"
];

const masterCourses = ["9BXvjg5tDGhF43bZK7dn"];

export default Courses;
