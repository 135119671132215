import { useState } from "react"
import Carousel from "../pages/Carousel"

type props = {
    src: string,
    title?: string
    subtitle?: string
    carousel?: boolean
}
function BannerTemplate(props: props) {

    const [carousel, setCarousel] = useState(props.carousel);
    return (
        <>
            {!carousel ? (
                <div style={{ "width": "100%", "maxHeight": "700px", "overflow": "hidden", "position": "relative", "marginTop": "0px" }} className="banner">
                    <img style={{ "aspectRatio": "auto 1920 / 540", "width": "100%" }} src={props.src} />
                    <h1 style={{ "position": "absolute", "bottom": "30%", "margin": "0 60px", "maxWidth": "500px" }}>{props.title}</h1>
                    <p style={{ "position": "absolute", "bottom": "15%", "margin": "0 45px", "maxWidth": "500px" }}>{props.subtitle}</p>
                </div>
            ) :
                <Carousel></Carousel>
            }
        </>
    );
}

export default BannerTemplate;