import { useEffect, useState } from "react";
import { Course } from "../../backend/courses";
import { currencies } from "../../backend/payment";
import PayPalCustomButton from "../paypal/PayPalCustomButton";

type props = {
    course: Course
}

function CoursePayment(props: props) {
    const [currency, SetCurrency] = useState("MXN");

    useEffect(() => {
        SetCurrency(currencies[props.course.currency]);
        console.log(props.course.currency);
    }, [props.course]);

    return (
        <div className="course-payment">
            <div className="course-payment-container">
                <h3>REALIZA TU PAGO</h3>
                {/*<div className="course-paypal-container">
                    {props.course.currency !== undefined && <PayPalCustomButton showSpinner={false} currency={currency} amount={props.course.price.toString()}/>}
    </div>*/}
            </div>
            <div className="course-payment-container">
                <div className="title-payment"> DATOS DE FACTURACIÓN </div>
                <div className="input-container-payment">
                    <input type="text" placeholder="RFC:" />
                    <input type="text" placeholder="Razón Social:" />
                    <input type="text" placeholder="Email:" />
                </div>
                <div className="subtitle-payment"> DESPUÉS DEL PAGO, SUBIR EL COMPROBANTE </div>
                <div className="course-payment-area">
                    <div className="course-payment-area-left">
                        <div className="text-payment"> ESCANEO O FOTOGRAFÍA [FORMATO .JPG, .PDF] </div>
                        <div className="submit-payment-btn" >
                            <input type="file"></input>
                            <div>SUBIR</div>
                        </div>
                    </div>                    
                    <div className="send-payment-btn"> ENVIAR </div>
                </div>
                
            </div>
            
        </div>
    );
}

export default CoursePayment;