import { Level } from "../../backend/courses";

type props = {
    levels: Level[];
}

function CourseProgram(props: props) {
    console.log(props.levels.length);
    return (
        <div className="course-program">
            {props.levels.filter(level => level != null).length >= 4 ? Array.apply(null, Array(Math.trunc(props.levels.filter(level => level != null).length / 4))).map((_void, columnIndex) => (
                <div key={"program-col-" + columnIndex} className="course-program-column">
                    {props.levels.filter(level => level != null).slice(columnIndex * 4, columnIndex * 4 + 4).map((level, index) => (
                        <div key={"module-" + columnIndex + "-" + index} className="course-program-module">{level?.title}</div>
                    ))}
                </div>
            )) : (
                <div key={"program-col-0"} className="course-program-column">
                    {props.levels.filter(level => level != null).map((level, index) => (
                        <div key={"module-" + index} className="course-program-module">{level?.title}</div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default CourseProgram;