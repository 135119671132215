export default function CourseContact() {
    return (
        <div className="details-requirement">
            <div className="requirement-content">
                <div className="subtitle">E-mail:</div>
                <div className="description">
                    educacion@medicinafetalmexico.com
                </div>
            </div>
        </div>
    )
}
