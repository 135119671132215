import * as React from 'react';



function Privacy() {
    return (
        <div style={{ "display": "flex", "justifyContent": "left", "textAlign": "left", "margin": "35px 35px", "paddingTop": "200px", "flexDirection": "column" }}>

            <div className="introSimpleTextCont">
                <h2 data-wow-delay="0.25s" className="h3 hCenter headerComp wow fadeInDown"><h2 data-wow-delay="0.25s" className="h3 hCenter headerComp wow fadeInDown"><span>Aviso de Privacidad</span></h2><strong>Identidad y Domicilio del Responsable</strong></h2>
                <p data-wow-delay="0.5s" className="wow fadeInUp">De conformidad con lo dispuesto por la Ley Federal de Protección de Datos Personales enPosesión de los Particulares (LFPDPPP),CENTRO DEDIAGNÓSTICO PRENATALAVANZADO Y CIRUGÍA FETAL, MEDICINA FETAL MÉXICOestácomprometida enmanejar los datos personales que usted le proporcione, observando en todo momento losprincipios de licitud, consentimiento, información, calidad, finalidad, lealtad yproporcionalidad previstos en la LFPDPPP y conforme al presente Aviso de Privacidad.</p>
                <p data-wow-delay="0.5s" className="wow fadeInUp">CENTRO DE DIAGNÓSTICO PRENATAL AVANZADO Y CIRUGÍA FETAL, MEDICINAFETAL MÉXICO, mejor conocido como MEDICINA FETAL MÉXICO, con domicilio en calle eSAN GABRIEL 333, colonia Chapalita, ciudad Guadalajara, municipio o delegaciónGuadalajara, c.p. 44550, en la entidad de Jalisco, país México, y portal de internethttps://medicinafetalmexico.com/, es el responsable del uso y protección de sus datospersonales, y al respecto le informamos lo siguiente:</p>
                <h2 data-wow-delay="0.25s" className="h3 hCenter headerComp wow fadeInDown"><span>¿Para qué fines utilizaremos sus datos personales?</span></h2>
                <p data-wow-delay="0.5s" className="wow fadeInUp">De manera adicional, utilizaremos su información personal para las siguientes finalidadessecundarias que no son necesarias para el servicio solicitado, pero que nos permiten yfacilitan brindarle una mejor atención:</p>
                <ol>
                    <li>1.  Dar cumplimiento a las obligaciones legales, fiscales y requerimientos de laautoridad competente</li>
                    <li>2.  Dar respuesta a sus dudas o comentarios y/o proporcionarle la información quesolicite</li>
                    <li>3.  Informar alguna modificación en los servicios contratados</li>
                    <li>4.  Enviar información de promociones sobre nuestros productos o serviciosrelacionados a los servicios contratados</li>
                    <li>5.  Proteger la Propiedad, derechos y personal del responsable</li>
                    <li>6.  Otorgar Seguridad en nuestras instalaciones</li>
                    <li>7.  Uso de imagen para redes sociales</li>
                    <li>8.  Campañas de Marketing</li>
                    <li>9.  Uso de imagen para material POP</li>
                    <li>10. Registro de Clientes para proveer los servicios que haya contratado</li>
                    <li>11. Mercadotecnia o publicitaria</li>
                    <li>12. Prospección comercial</li>
                </ol>
                <p data-wow-delay="0.5s" className="wow fadeInUp">En caso de que no desee que sus datos personales se utilicen para estos fines secundarios,indíquelo a continuación:</p>
                <p data-wow-delay="0.5s" className="wow fadeInUp">No consiento que mis datos personales se utilicen para los siguientes fines:</p>
                <ol>
                    <li>[] Dar cumplimiento a las obligaciones legales, fiscales y requerimientos de la autoridadcompetente</li>
                    <li>[] Dar respuesta a sus dudas o comentarios y/o proporcionarle la información que solicite</li>
                    <li>[] Informar alguna modificación en los servicios contratados</li>
                    <li>[] Enviar información de promociones sobre nuestros productos o servicios relacionados alos servicios contratados</li>
                    <li>[] Proteger la Propiedad, derechos y personal del responsable</li>
                    <li>[] Otorgar Seguridad en nuestras instalaciones</li>
                    <li>[] Uso de imagen para redes sociales</li>
                    <li>[] Campañas de Marketing</li>
                    <li>[] Uso de imagen para material POP</li>
                    <li>[] Registro de Clientes para proveer los servicios que haya contratado</li>
                    <li>[] Mercadotecnia o publicitaria</li>
                    <li>[] Prospección comercial</li>
                </ol>
                <p data-wow-delay="0.5s" className="wow fadeInUp">La negativa para el uso de sus datos personales para estas finalidades no podrá ser unmotivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.</p>
                <h2 data-wow-delay="0.25s" className="h3 hCenter headerComp wow fadeInDown"><span>¿Qué datos personales utilizaremos para estos fines?</span></h2>
                <p data-wow-delay="0.5s" className="wow fadeInUp">Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremoslos siguientes datos personales:</p>
                <ol>
                    <li>● Datos de identificación</li>
                    <li>● Datos de contacto</li>
                    <li>● Datos biométricos</li>
                    <li>● Datos académicos</li>
                </ol>
                <p data-wow-delay="0.5s" className="wow fadeInUp">Además de los datos personales mencionados anteriormente, para las finalidadesinformadas en el presente aviso de privacidad utilizaremos los siguientes datos personalesconsiderados como sensibles, que requieren de especial protección:</p>
                <ol>
                    <li>● Datos de salud</li>
                    <li>● Datos sobre vida sexual</li>
                </ol>
                <h2 data-wow-delay="0.25s" className="h3 hCenter headerComp wow fadeInDown"><span>¿Con quién compartimos su información personal y para qué fines?</span></h2>
                <p data-wow-delay="0.5s" className="wow fadeInUp">Le informamos que sus datos personales son compartidos dentro del país con las siguientespersonas, empresas, organizaciones o autoridades distintas a nosotros, para los siguientesfines: Sociedades Controladoras, filiales y/o subsidiarias: con la finalidad de resguardoCentralizado de la Información, control de altas, bajas y/o modificaciones, Informe deactividades y Registro de Clientes; Sociedad Médicas Certificadas dedicadas a laInvestigación, Autoridades, Organismos o entidades gubernamentales: Para el cumplimientoa las obligaciones señaladas en la legislación aplicable en Materia de Salud y/ocumplimiento a requerimientos de la misma autoridad y/o investigación en Materia de Salud;Cobranza: Para la gestión de cobranza de cuentas por cobrar en el ámbito judicial oextrajudicial y; Clientes y/o Proveedores: Para el cumplimiento de obligacionescontractuales y comerciales entre el responsable y sus clientes y/o proveedores.</p>
                <h2 data-wow-delay="0.25s" className="h3 hCenter headerComp wow fadeInDown"><span>¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a suuso?</span></h2>
                <p data-wow-delay="0.5s" className="wow fadeInUp">Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué losutilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derechosolicitar la corrección de su información personal en caso de que esté desactualizada, seainexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases dedatos cuando considere que la misma no está siendo utilizada adecuadamente(Cancelación); así como oponerse al uso de sus datos personales para fines específicos(Oposición). Estos derechos se conocen como derechos ARCO.</p>
                <p data-wow-delay="0.5s" className="wow fadeInUp">Para el ejercicio de cualquiera de los derechos ARCO, usted deberá ponerse en contactocon nuestro Departamento de Datos Personales a través de la dirección de correoelectrónico __________________ donde le será proporcionado el formato de solicitud,requisitos y tiempo de respuesta.</p>
                <ol>
                    <li>1.  Nombre completo, domicilio completo y correo electrónico para poder comunicarle larespuesta a la Solicitud;</li>
                    <li>2.  Adjuntar documentación que acredite la identidad (copia de credencial para votar,pasaporte o cualquier otra identificación oficial vigente), en caso de serrepresentado, el representante adicionalmente deberá́ identificarse y acreditar supersonalidad, adjuntando los documentos que lo amparen, (Identificación oficialvigente del representante, Poder en Escritura Pública o Carta Poder Ratificada anteNotario);</li>
                    <li>3.  Tipo de Derecho ARCO que se pretende ejercer. (Acceso, Rectificación, Cancelaciónu Oposición);</li>
                    <li>4.  Una descripción clara y precisa de lo que pretende ejercer sobre sus DatosPersonales;</li>
                    <li>5.  Adjuntar cualquier documento o información que facilite la localización de sus datospersonales;</li>
                    <li>6.  En caso de solicitar una rectificación de datos personales, deberá de indicartambién, las modificaciones a realizarse y aportar la documentación que sustente supetición (donde los datos estén correctos) y;</li>
                    <li>7.  La indicación del lugar donde podremos revisar los originales de la documentaciónque acompañe.</li>
                </ol>
                <p data-wow-delay="0.5s" className="wow fadeInUp">Para conocer a detalle el procedimiento y requisitos para el ejercicio de sus Derechos, ustedpodrá ponerse en contacto con al Departamento de Datos Personales al correo electrónico_______________, quien dará tramite a las solicitudes para el ejercicio de estos derechos yatenderá cualquier duda que pudiera tener respecto al tratamiento de sus datos personales.</p>
                <p data-wow-delay="0.5s" className="wow fadeInUp">En caso de que la información proporcionada sea errónea, insuficiente o no se acompañenlos documentos de acreditación correspondiente, el responsable podrá requerir que aportela información necesaria para dar trámite a su solicitud, contando con un plazo de 5 (cinco)días hábiles para subsanar el error o la omisión contados a partir del requerimiento delresponsable, en caso de que usted no de aporte la información solicitada en dicho plazo setendrá por no presentada la solicitud y deberá iniciar de nueva cuenta su solicitud.</p>
                <p data-wow-delay="0.5s" className="wow fadeInUp">El ejercicio de los Derechos será gratuito, previa acreditación de su identidad, con laexcepción de que usted deberá de cubrir los gastos justificados de envío o el costo dereproducción en copias u otros formatos.</p>
                <h2 data-wow-delay="0.25s" className="h3 hCenter headerComp wow fadeInDown"><span>¿Cómo puede limitar el uso o divulgación de su información personal?</span></h2>
                <p data-wow-delay="0.5s" className="wow fadeInUp">Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para eltratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta queno en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata,ya que es posible que por alguna obligación legal queramos seguir tratando sus datospersonales. Asimismo, usted deberá considerar que, para ciertos fines, la revocación de suconsentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, ola conclusión de su relación con nosotros.Para revocar su consentimiento, usted deberá́ ponerse en contacto con nuestroDepartamento de Datos Personales a través de la dirección de correo electrónico_______________ donde le será proporcionado el formato de solicitud, requisitos y tiempode respuesta.</p>
                <p data-wow-delay="0.5s" className="wow fadeInUp">De conformidad con la Legislación vigente en materia de Datos Personales su formato desolicitud deberá́ estar firmada, contener y adjuntar la siguiente información del Titular de losDatos Personales:</p>
                <ol>
                    <li>1. Nombre completo, domicilio completo y correo electrónico para poder comunicarle larespuesta a la Solicitud;</li>
                    <li>2. Adjuntar documentación que acredite la identidad (copias de credencial para votar,pasaporte o cualquier otra identificación oficial vigente), en caso de serrepresentado, el representante adicionalmente deberá identificarse y acreditar supersonalidad, adjuntando los documentos que lo amparen, (Identificación oficialvigente del representante, Poder en Escritura Pública o Carta Poder Ratificada anteNotario);</li>
                    <li>3. Una descripción clara y precisa de los datos personales respecto de los cualesbusca revocar su consentimiento;</li>
                    <li>4. Adjuntar cualquier documento o información que facilite la localización de sus datospersonales y;</li>
                    <li>5. La indicación del lugar donde podremos revisar los originales de la documentaciónque acompañe.</li>
                </ol>
                <p data-wow-delay="0.5s" className="wow fadeInUp">Su solicitud será contestada mediante correo electrónico por parte del Departamento deDatos Personales en un plazo máximo de 20 (veinte) días hábiles contados desde el día enque se haya recibido. En caso de que la solicitud se conteste de manera positiva oprocedente, la petición se podrá hacer efectiva en un plazo máximo de 15 (quince) díashábiles. Los plazos señalados en este párrafo podrán prorrogarse por el responsable en unasola ocasión y por un periodo igual en caso de ser necesario.</p>
                <p data-wow-delay="0.5s" className="wow fadeInUp">En caso de que la información proporcionada sea errónea, insuficiente o no se acompañen
                    los documentos de acreditación correspondiente, el responsable podrá requerir que aporte
                    la información necesaria para dar trámite a su solicitud, contando con un plazo de 5 (cinco)
                    días hábiles para subsanar el error o la omisión contados a partir del requerimiento del
                    responsable, en caso de que usted no de aporte la información solicitada en dicho plazo se
                    tendrá por no presentada la solicitud y deberá iniciar de nueva cuenta su solicitud.
                    El ejercicio de los Derechos será ́ gratuito, previa acreditación de su identidad, con la
                    excepción de que usted deberá ́ de cubrir los gastos justificados de envió ́ o el costo de
                    reproducción en copias u otros formatos.
                </p>
                <h2 data-wow-delay="0.25s" className="h3 hCenter headerComp wow fadeInDown"><span>Usted puede revocar su consentimiento para el uso de sus datos personales</span></h2>
                <p data-wow-delay="0.5s" className="wow fadeInUp">Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el
                    tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que
                    no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata,
                    ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos
                    personales. Asimismo, usted deberá considerar que, para ciertos fines, la revocación de su
                    consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o
                    la conclusión de su relación con nosotros</p>
                <p data-wow-delay="0.5s" className="wow fadeInUp">Para revocar su consentimiento, usted deberá́ ponerse en contacto con nuestro
                    Departamento de Datos Personales a través de la dirección de correo electrónico
                    _______________ donde le será proporcionado el formato de solicitud, requisitos y tiempo
                    de respuesta.
                </p>
                <p data-wow-delay="0.5s" className="wow fadeInUp">De conformidad con la Legislación vigente en materia de Datos Personales su formato de
                    solicitud deberá́ estar firmada, contener y adjuntar la siguiente información del Titular de los
                    Datos Personales:
                </p>
                <ol>
                    <li>1. Nombre completo, domicilio completo y correo electrónico para poder comunicarle la respuesta a la Solicitud;</li>
                    <li>2. Adjuntar documentación que acredite la identidad (copias de credencial para votar, pasaporte o cualquier otra identificación oficial vigente), en caso de ser representado, el representante adicionalmente deberá identificarse y acreditar su personalidad, adjuntando los documentos que lo amparen, (Identificación oficial vigente del representante, Poder en Escritura Pública o Carta Poder Ratificada ante Notario);</li>
                    <li>3. Una descripción clara y precisa de los datos personales respecto de los cuales busca revocar su consentimiento;</li>
                    <li>4. Adjuntar cualquier documento o información que facilite la localización de sus datos personales y;</li>
                    <li>5. La indicación del lugar donde podremos revisar los originales de la documentación que acompañe</li>
                </ol>
            </div>
            <p data-wow-delay="0.5s" className="wow fadeInUp">Su solicitud será contestada mediante correo electrónico por parte del Departamento de
                Datos Personales en un plazo máximo de 20 (veinte) días hábiles contados desde el día en
                que se haya recibido. En caso de que la solicitud se conteste de manera positiva o
                procedente, la petición se podrá́ hacer efectiva en un plazo máximo de 15 (quince) días
                hábiles. Los plazos señalados en este párrafo podrán prorrogarse por el responsable en una
                sola ocasión y por un periodo igual en caso de ser necesario.</p>
            <p data-wow-delay="0.5s" className="wow fadeInUp">En caso de que la información proporcionada sea errónea, insuficiente o no se acompañen
                los documentos de acreditación correspondiente, el responsable podrá requerir que aporte
                la información necesaria para dar trámite a su solicitud, contando con un plazo de 5 (cinco)
                días hábiles para subsanar el error o la omisión contados a partir del requerimiento del
                responsable, en caso de que usted no de aporte la información solicitada en dicho plazo se
                tendrá por no presentada la solicitud y deberá iniciar de nueva cuenta su solicitud.
            </p>
            <p data-wow-delay="0.5s" className="wow fadeInUp">El ejercicio de los Derechos será gratuito, previa acreditación de su identidad, con la
                excepción de que usted deberá de cubrir los gastos justificados de envió o el costo de
                reproducción en copias u otros formatos.</p>
            <h2 data-wow-delay="0.25s" className="h3 hCenter headerComp wow fadeInDown"><span>¿Cómo puede limitar el uso o divulgación de su información personal?</span></h2>
            <p data-wow-delay="0.5s" className="wow fadeInUp">Para limitar el uso y/o divulgación de sus Datos Personales, usted deberá ponerse en
                contacto con nuestro Departamento de Datos Personales a través de la dirección de correo
                electrónico ______________ donde le será́ proporcionado el formato de solicitud, requisitos
                y tiempo de respuesta.
            </p>
            <h2 data-wow-delay="0.25s" className="h3 hCenter headerComp wow fadeInDown"><span>El uso de tecnologías de rastreo en nuestro portal de internet</span></h2>
            <p data-wow-delay="0.5s" className="wow fadeInUp">Le informamos que en nuestra página de internet utilizamos cookies, web beacons u otras
                tecnologías, a través de las cuales es posible monitorear su comportamiento como usuario
                de internet, así como brindarle un mejor servicio y experiencia al navegar en nuestra página.
                Los datos personales que recabamos a través de estas tecnologías, los utilizaremos para
                los siguientes fines:
            </p>
            <p data-wow-delay="0.5s" className="wow fadeInUp">personalizar y mejorar el contenido de nuestro sitio de internet, evaluar el uso del mismo, y
                simplificar su experiencia cuando vuelva a visitarlo.</p>
            <p data-wow-delay="0.5s" className="wow fadeInUp">Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes:</p>
            <ol>
                <li>1. Idioma preferido por el usuario</li>
                <li>2. Región en la que se encuentra el usuario</li>
                <li>3. Tipo de navegador del usuario</li>
                <li>4. Tipo de sistema operativo del usuario</li>
                <li>5. Fecha y hora del inicio y final de una sesión de un usuario</li>
                <li>6. Páginas web visitadas por un usuario</li>
                <li>7. Búsquedas realizadas por un usuario</li>
            </ol>
            <p data-wow-delay="0.5s" className="wow fadeInUp">Estas tecnologías podrán deshabilitarse siguiendo los siguientes pasos: Ir al menú
                “Herramientas”; dar clic en “borrar datos de navegación”, seleccionar la opción “eliminar
                cookies y otros datos de sitios y de complementos”, dar clic en “aceptar”. Al deshabilitar o
                bloquear estos mecanismos se puede ocasionar que el sitio no funcione correctamente</p>
            <h2 data-wow-delay="0.25s" className="h3 hCenter headerComp wow fadeInDown"><span>¿Cómo puede conocer los cambios en este aviso de privacidad?</span></h2>
            <p data-wow-delay="0.5s" className="wow fadeInUp">El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones
                derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los
                productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en
                nuestro modelo de negocio, o por otras causas.</p>
            <p data-wow-delay="0.5s" className="wow fadeInUp">Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el
                presente aviso de privacidad, a través de: https://medicinafetalmexico.com/</p>
            <p data-wow-delay="0.5s" className="wow fadeInUp">El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o
                actualizaciones al presente aviso de privacidad es el siguiente:
            </p>
            <p data-wow-delay="0.5s" className="wow fadeInUp">https://medicinafetalmexico.com/</p>
            <p data-wow-delay="0.5s" className="wow fadeInUp">Última actualización: 14/02/2023</p>




            <br></br><br></br><br></br>
        </div>
    );
}

export default Privacy;