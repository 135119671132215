import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProfessors, Professor } from "../backend/professors";
import Specialist from "../components/specialists/Specialist";
import SpecialistsGrid from "../components/specialists/SpecialistsGrid";
import Loading from "../components/_main/Loading";
import "./styles/Carousel.css";
import "./styles/Education.css";

function Carousel() {

    return (
        <></>
    );
}

export default Carousel;