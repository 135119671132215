import { useEffect } from "react";
import { Professor } from "../../backend/professors";
import "./styles/Specialist.css"

type props = {
    professor?: Professor
}

function Specialist(props: props) {
    useEffect(() => {
        if (props.professor) window.document.title = props.professor.name;
    }, [props.professor]);
    return (
        <div className="specialist-container">
            <div className="specialist-container-bg">
                <div className="specialist-top-text">
                    
                    <h1 data-wow-delay="0.25s" className="h2 headerComp wow fadeInDown" style={{"marginBottom": "0"}}>
                        <span className="specialist-sintesis">Síntesis Curricular</span>
                    </h1>
                    <h1 data-wow-delay="0.25s" className="h2 headerComp wow fadeInDown" style={{"marginTop": "10px", "marginBottom": "0"}}>
                        <strong>{props.professor?.name}</strong>
                    </h1>
                    <p data-wow-delay="0.5s" className="wow fadeInUp">{props.professor?.special}</p>
                </div>
            </div>
            <div className="specialist-container-down">
                <div className="specialist-container-left">
                    <div className="specialist-container-text">
                        {props.professor?.sinapsis}
                    </div>
                </div>
                <div className="specialist-container-right">
                    <div className="specialist-card">
                        <div className="specialist-photo-container">
                            <img className="specialist-photo" src={props.professor?.thumbnail}/>
                        </div>
                        <a href={"mailto:"} className="email-link">pendiente@medicinafetalmexico.com</a>
                        <div className="specialist-phone">
                            <i className="fa fa-phone"></i>
                        </div>
                        <div className="specialist-social">
                            <i className="fa fa-facebook"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Specialist;