import { CSSProperties } from "react";
import { Course } from "../../backend/courses";

interface props {
    course: Course
}

function CourseHQs(props: props) {
    const { course } = props;

    return (
        <div style={backgroundStyle}>
            <img src={process.env.PUBLIC_URL + "/img/orange-points.png"} style={{"position": "absolute", "top": "0", "left": "0"}}/>
            <img src={process.env.PUBLIC_URL + "/img/orange-circle.png"} style={{"position": "absolute", "bottom": "0", "right": "0"}}/>
            <div style={containerStyle}>
            {course.headquarters?.map(headquater => (
                <div style={{"position": "relative", "display": "flex", "flexDirection": "column", "alignItems": "center", "gap": "10px", "flex": "1 2"}}>
                    <div style={circleStyle} className="items-center">
                        <div 
                            className="flex items-center justify-center"
                            style={{"borderRadius": "50%", "overflow": "hidden", "height": "95%", "width": "95%"}}>
                            <img src={`https://media.virtualcampusmfm.com/Thumbnails/Countries/Mexico/${headquater.entity}.png`}
                                className="h-full w-auto object-cover"/>
                        </div>
                    </div>
                    <div style={textBackgroundStlye}>{mxEntities[headquater.entity]}</div>
                </div>
            ))}
            </div>
        </div>
    );
}

const backgroundStyle: CSSProperties = {
    backgroundColor: "#112E4F",
    width: "100%",
    minHeight: "400px",
    position: "relative"
}

const containerStyle: CSSProperties = {
    color: "white", 
    position: "relative", 
    display: "grid", 
    gap: "40px", 
    flexWrap: "wrap",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    margin: "100px 200px"
}

const textBackgroundStlye: CSSProperties = {
    backgroundColor: "white",
    color: "#112E4F",
    borderRadius: "15px",
    padding: "5px",
    width: "calc(100% - 10px)",
    fontFamily: "WhyteInktrap",
    display: "flex",
    justifyContent: "center",
    fontSize: "28px"
}

const circleStyle: CSSProperties = {
    backgroundColor: "white",
    //minWidth: "100px",
    width: "100%",
    maxWidth: "300px",
    aspectRatio: "1 / 1",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center"
}

const mxEntities = [
    "Aguascalientes",
    "Baja California",
    "Baja California Sur",
    "Campeche",
    "Chiapas",
    "Chihuahua",
    "CDMX",
    "Coahuila",
    "Colima",
    "Durango",
    "Estado de México",
    "Guanajuato",
    "Guerrero",
    "Hidalgo",
    "Jalisco",
    "Michoacán",
    "Morelos",
    "Nayarit",
    "Nuevo León",
    "Oaxaca",
    "Puebla",
    "Querétaro",
    "Quintana Roo",
    "San Luis Potosí",
    "Sinaloa",
    "Sonora",
    "Tabasco",
    "Tamaulipas",
    "Tlaxcala",
    "Veracruz",
    "Yucatán",
    "Zacatecas"
]

export default CourseHQs;