import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProfessors, Professor } from "../backend/professors";
import Specialist from "../components/specialists/Specialist";
import SpecialistsGrid from "../components/specialists/SpecialistsGrid";
import Loading from "../components/_main/Loading";

function Docentes() {
    return (
        <div>
            <section className="specialistList">
                <div className="container">
                    <ul data-wow-delay="0.25s" className="specialistFilter wow fadeInUp">
                        <li data-filter="1"><span>Nacionales</span></li>
                        <li data-filter="2"><span>Internacionales</span></li>
                    </ul>
                    <div className="specialistListWrap">
                        <div data-type="$classification" data-wow-delay="0.25s" className="specialistBox wow fadeInUp"><a
                            href="https://medicinafetalmexico.com/professor/see/1" className="specialistImgCircle">
                            <picture><img src="img/img-doctor-rogelio-martinez.png" width="600" height="600" alt="$name" /></picture>
                        </a>
                            <div className="specialistInfo">
                                <h2 className="h5">Dr. Rogelio Cruz Martínez</h2>
                                <p>Medicina Fetal</p>
                            </div>
                            <ul className="specialistContact">
                                <li><a href="https://www.facebook.com/rogelio.cruz" target="_blnak"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="https://www.instagram.com/rogeliocruz/" target="_blnak"><i className="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>
                        <div data-type="$classification" data-wow-delay="0.25s" className="specialistBox wow fadeInUp"><a
                            href="https://medicinafetalmexico.com/especialista-miguel-angel-martinez-rodriguez" className="specialistImgCircle">
                            <picture><img src="img/img-doctor-miguel-martinez.png" width="600" height="600" alt="$name" /></picture>
                        </a>
                            <div className="specialistInfo">
                                <h2 className="h5">Dr. Miguel Ángel Martínez Rodríguez</h2>
                                <p>Medicina y Neurosonografía Fetal</p>
                            </div>
                        </div>
                        <div data-type="$classification" data-wow-delay="0.25s" className="specialistBox wow fadeInUp"><a
                            href="https://medicinafetalmexico.com/especialista-israel-juarez-martinez" className="specialistImgCircle">
                            <picture><img src="img/img-doctor-israel-juarez.png" width="600" height="600" alt="$name" /></picture>
                        </a>
                            <div className="specialistInfo">
                                <h2 className="h5">Dr. Israel Juárez Martínez</h2>
                                <p>Genetista Perinatal </p>
                            </div>
                            <ul className="specialistContact">
                                <li><a href="https://instagram.com/drjuamar?igshid=1brnke4gh7w76" target="_blnak"><i
                                    className="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>
                        <div data-type="$classification" data-wow-delay="0.25s" className="specialistBox wow fadeInUp"><a className="specialistImgCircle">
                            <picture><img src="img/img-doctor-hugo-lopez.png" width="600" height="600" alt="$name" style={{ "top": "0px" }} /></picture>
                        </a>
                            <div className="specialistInfo">
                                <h2 className="h5">Dr. Hugo Pineda Alemán</h2>
                                <p>Medicina Materno Fetal</p>
                            </div>
                            <ul className="specialistContact">
                            </ul>
                        </div>
                        <div data-type="$classification" data-wow-delay="0.25s" className="specialistBox wow fadeInUp"><a
                            className="specialistImgCircle">
                            <picture><img src="img/grimaldo.jpg" width="600" height="600" alt="$name" style={{ "top": "0px" }} /></picture>
                        </a>
                            <div className="specialistInfo">
                                <h2 className="h5">Dr. Pedro Grimaldo</h2>
                            </div>
                            <ul className="specialistContact">
                            </ul>
                        </div>
                        <div data-type="$classification" data-wow-delay="0.25s" className="specialistBox wow fadeInUp"><a
                            className="specialistImgCircle">
                            <picture><img src="img/gaona.jpg" width="600" height="600" alt="$name"
                                style={{ "top": "0px" }} /></picture>
                        </a>
                            <div className="specialistInfo">
                                <h2 className="h5">Dra. Carmen Julia Gaona Tapia</h2>
                            </div>
                            <ul className="specialistContact">
                            </ul>
                        </div>
                        <div data-type="$classification" data-wow-delay="0.25s" className="specialistBox wow fadeInUp"><a
                            className="specialistImgCircle">
                            <picture><img src="img/marquez.jpg" width="600" height="600" alt="$name"
                                style={{ "top": "0px" }} /></picture>
                        </a>
                            <div className="specialistInfo">
                                <h2 className="h5">Dra. Alejandra Márquez Dávila</h2>
                            </div>
                            <ul className="specialistContact">
                            </ul>
                        </div>
                        <div data-type="$classification" data-wow-delay="0.25s" className="specialistBox wow fadeInUp"><a
                            className="specialistImgCircle">
                            <picture><img src="img/gaston.jpg" width="600" height="600" alt="$name"
                                style={{ "top": "0px" }} /></picture>
                        </a>
                            <div className="specialistInfo">
                                <h2 className="h5">Dr. Gastón Eduardo Estudillo Jiménez</h2>
                            </div>
                            <ul className="specialistContact">
                            </ul>
                        </div>
                        <div data-type="$classification" data-wow-delay="0.25s" className="specialistBox wow fadeInUp"><a
                            href="https://medicinafetalmexico.com/especialista-rosa-helena-villalobos-gomez" className="specialistImgCircle">
                            <picture><img src="img/img-doctor-rosa-villalobos.png" width="600" height="600"
                                alt="$name" /></picture>
                        </a>
                            <div className="specialistInfo">
                                <h2 className="h5">Dra. Rosa Helena Villalobos Gómez</h2>
                                <p>Medicina Materno Fetal</p>
                            </div>
                            <ul className="specialistContact">
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Docentes;