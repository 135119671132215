import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./styles/UpperBar.css";
import "./styles/HeaderStyle.css";

export default function UpperBar() {
  const navigate = useNavigate();
  const [logo, setLogo] = useState("");
  const [tel, setTel] = useState("");
  const [contact, setContact] = useState("");
  const [education, setEducation] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const call = (tel: string) => {
    var call_app = window.open("tel:" + tel, "_self");
    call_app!.close();
  };

  useEffect(() => {
    if (
      window.location.href == "http://localhost:3000/contact" ||
      window.location.href == "https://new.medicinafetalmexico.com/contact" ||
      window.location.href == "https://new.medicinafetalmexico.com/privacy" ||
      window.location.href == "http://localhost:3000/privacy"
    ) {
      setLogo("/img/mfm-blue.png");
      setTel("(33) 281 522 00");
      setContact("+523328152200");
      setEducation(true);
    } else {
      setLogo("/img/mfm-copyright.png");
      setTel("(442) 473 92 04");
      setContact("+524424739204");
      setEducation(false);
    }
  }, [window.location.href]);

  const navbar: HTMLElement | null = document.querySelector('#NavBar');
  const handleScroll = () => {
    const height: number = navbar?.offsetTop || 0;
    setIsSticky(window.scrollY > height)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });
  return (
    <>
      {education ? (
        <div className="upperBar-costume">
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%", height: "auto", backgroundColor: "rgba(255, 255, 255, 0.95)", position: "fixed", top: "0", left: "0", padding: "10px 20px", }} className="upperBar" >
            <div className="upperBar-contact-info">
              <div onClick={() => call(contact)}>
                <i className="fa fa-phone" style={{ marginRight: "5px" }} aria-hidden="true"
                />
                {tel}
              </div>
              <img
                onClick={() => window.open("https://wa.me/523328152200")}
                className="social-icon-bar"
                width="24px"
                src={process.env.PUBLIC_URL + "/img/whats.png"}
              />
              <p> | </p>
              <a href="https://www.instagram.com/medicinafetalmexico/?hl=es">
                <img
                  className="social-icon-bar"
                  width="15px"
                  src={process.env.PUBLIC_URL + "/img/instagram.jpg"}
                />
              </a>
              <a href="https://www.facebook.com/people/Medicina-Fetal-M%C3%A9xico/100087722166533/">
                <img
                  className="social-icon-bar"
                  width="15px"
                  src={process.env.PUBLIC_URL + "/img/facebook.png"}
                />
              </a>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="upperBar-img">
                <a href="https://medicinafetalmexico.com/">
                  <img
                    className="upperBar-logo"
                    style={{ cursor: "pointer", width: "300px" }}
                    src={process.env.PUBLIC_URL + logo}
                  />
                </a>
              </div>
              <div className="menu-upperBar">
                <div className="menu-upperBar-text">
                  <ul className="dropdown">
                    <li>
                      <a href="https://medicinafetalmexico.com/">Inicio</a>
                      <ul>
                        <li>
                          <a href="https://medicinafetalmexico.com/que-es-la-medicina-fetal">
                            ¿Qué es la Medicina Fetal
                          </a>
                        </li>
                        <li>
                          <a href="https://medicinafetalmexico.com/quienes-somos">
                            Somos - Medicina Fetal México
                          </a>
                        </li>
                        <li>
                          <a href="https://medicinafetalmexico.com/testimonios">
                            Testimonios
                          </a>
                        </li>
                        <li>
                          <a href="https://medicinafetalmexico.com/tecnologia">
                            Tecnología
                          </a>
                        </li>
                        <li>
                          <a onClick={() => navigate("/specialists")}>
                            Especialistas CV
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <ul className="dropdown">
                    <li>
                      <a href="https://ipregnancymfm.com.mx/">iPregnancy</a>
                      <ul>
                        <li>
                          <a href="#">
                            Servicios
                            <img
                              className="social-icon-bar"
                              width="6px"
                              src={process.env.PUBLIC_URL + "/img/arrow.png"}
                              style={{ opacity: "20%" }}
                            />
                          </a>
                          <ul>
                            <li>
                              <a href="https://ipregnancymfm.com.mx/diagnostico-prenatal-avanzado">
                                Diagnóstico Prenatal Avanzado
                              </a>
                            </li>
                            <li>
                              <a href="https://ipregnancymfm.com.mx/doppler-fetal-basico-y-avanzado">
                                Doppler Fetal Básico y Avanzado
                              </a>
                            </li>
                            <li>
                              <a href="https://ipregnancymfm.com.mx/ecocardiografia-fetal">
                                Ecocardiografía Fetal
                              </a>
                            </li>
                            <li>
                              <a href="https://ipregnancymfm.com.mx/neurosonografia-fetal">
                                Neurosonografía Fetal
                              </a>
                            </li>
                            <li>
                              <a href="https://ipregnancymfm.com.mx/procedimientos-invasivos">
                                Procedimientos invasivos de alta<br></br>
                                complejidad
                              </a>
                            </li>
                            <li>
                              <a href="https://ipregnancymfm.com.mx/prueba-de-madurez-pulmonar">
                                Prueba de Madurez Pulmonar
                              </a>
                            </li>
                            <li>
                              <a href="https://ipregnancymfm.com.mx/monitorizacion-cardiaca-fetal">
                                Monitorización Cardiaca Fetal a Distancia
                              </a>
                            </li>
                            <li>
                              <a href="https://ipregnancymfm.com.mx/clinica-de-embarazo-gemelar">
                                Clínica de Embarazo Gemelar
                              </a>
                            </li>
                            <li>
                              <a href="https://ipregnancymfm.com.mx/neurodesarrollo">
                                Neurodesarrollo
                              </a>
                            </li>
                            <li>
                              <a href="https://ipregnancymfm.com.mx/psicologia">
                                Psicología
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="https://ipregnancymfm.com.mx/tecnologia">
                            Tecnología
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            Centros
                            <img
                              className="social-icon-bar"
                              width="6px"
                              src={process.env.PUBLIC_URL + "/img/arrow.png"}
                              style={{ opacity: "20%" }}
                            />
                          </a>
                          <ul>
                            <li>
                              <a href="https://production.d34ndqfwbcb776.amplifyapp.com/contact">
                                Centro Guadalajara
                              </a>
                            </li>
                            <li>
                              <a href="https://medicinafetalmexico.com/centro-queretaro">
                                Centro Querétaro
                              </a>
                            </li>
                            <li>
                              <a href="https://production.d34ndqfwbcb776.amplifyapp.com/contact">
                                Centro Morelia
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="https://production.d34ndqfwbcb776.amplifyapp.com/contact">
                            Contacto
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <ul className="dropdown">
                    <li>
                      <a href="https://cirugiafetalmexico.com.mx/">
                        Cirugía Fetal
                      </a>
                      <ul>
                        <li>
                          <a href="#">
                            Patologías fetales
                            <img
                              className="social-icon-bar"
                              width="6px"
                              src={process.env.PUBLIC_URL + "/img/arrow.png"}
                              style={{ opacity: "20%" }}
                            />
                          </a>
                          <ul>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/espina-bifida">
                                Espina Bífida
                              </a>
                            </li>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/anemia-fetal">
                                Anemia fetal
                              </a>
                            </li>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/megavejiga">
                                Megavejiga
                              </a>
                            </li>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/malformacion-adenomatoidea-quistica">
                                Masas Pulmonares
                              </a>
                            </li>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/secuestro-broncopulmonar">
                                Secuestro Broncopulmonar
                              </a>
                            </li>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/hernia-diafragmatica-congenita">
                                Hernia diafragmática congénita
                              </a>
                            </li>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/hidrotorax">
                                Hidro tórax
                              </a>
                            </li>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/bandas-amnioticas">
                                Bandas Amnióticas
                              </a>
                            </li>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/sindrome-transfusion">
                                Sindrome de transfusión feto - fetal (STFF)
                              </a>
                            </li>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/restriccion-crecimiento">
                                Restricción de crecimiento selectrivo (RCIUs)
                              </a>
                            </li>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/malformacion-discordante-en-gemelos">
                                Malformación discordante en gemelos
                              </a>
                            </li>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/secuencia-trap">
                                Secuencia TRAP
                              </a>
                            </li>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/atresia-bronquial">
                                Atresia laríngea o bronquial (CHAOS)
                              </a>
                            </li>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/cardiopatias-congenitas">
                                Cardiopatías congénitas (Estenosis aórtica y{" "}
                                <br></br> pulmonar)
                              </a>
                            </li>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/teratoma-sacrococcigeo">
                                Teratoma Sacrococcigeo (TSC)
                              </a>
                            </li>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/tumor-cervical">
                                Tumor cervical (TC)
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="#">
                            Casos de éxito
                            <img
                              className="social-icon-bar"
                              width="6px"
                              src={process.env.PUBLIC_URL + "/img/arrow.png"}
                              style={{ opacity: "20%" }}
                            />
                          </a>
                          <ul>
                            <li>
                              <a href="https://cirugiafetalmexico.com.mx/testimonios">
                                Testimonio
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="https://cirugiafetalmexico.com.mx/investigacion">
                            Investigación
                          </a>
                        </li>
                        <li>
                          <a href="https://cirugiafetalmexico.com.mx/agenda-tu-cita">
                            Agenda tu cita
                          </a>
                        </li>
                        <li>
                          <a href="https://cirugiafetalmexico.com.mx/comparte-tu-historia">
                            Comparte tu historia
                          </a>
                        </li>
                        <li>
                          <a href="https://cirugiafetalmexico.com.mx/rotaciones">
                            Rotaciones
                          </a>
                        </li>
                        <li>
                          <a href="https://cirugiafetalmexico.com.mx/prensa">
                            Prensa
                          </a>
                        </li>
                        <li>
                          <a href="https://cirugiafetalmexico.com.mx/medicos-egresados">
                            Médicos egresados del Posgrado en Alta<br></br>
                            Especialidad en Cirugía <br></br>
                            Fetal
                          </a>
                        </li>
                        <li>
                          <a href="https://production.d34ndqfwbcb776.amplifyapp.com/contact">
                            Contacto
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <ul className="dropdown">
                    <li>
                      <a href="/courses">Educación</a>
                      <ul>
                        <li>
                          <a href="/courses">Cursos, Diplomados, Posgrados</a>
                        </li>
                        {/* 
                        <li>
                          <a href="/vc/courses/aTL2HT3ibBBFmm0xZ8Z2">
                            Congreso Internacional
                          </a>
                        </li>
                        */}
                        <li>
                          <a href="/rotations">Estancias de rotación</a>
                        </li>
                        <li>
                          <a href="/investigations">Investigación</a>
                        </li>
                        <li>
                          <a href="https://www.virtualcampusmfm.com/">
                            Campus Virtual
                          </a>
                        </li>
                        <li>
                          <a href="https://www.fuve.app/">FUVE</a>
                        </li>
                        <li>
                          <a href="http://www.orvo.com.mx/">ORVO</a>
                        </li>
                        <li>
                          <a href="/teachers">Profesorado</a>
                        </li>
                        <li>
                          <a href="/collaborations">
                            Colaboraciones académicas
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="dropdown">
                    <li>
                      <a href="https://fundacionmfm.com/">Fundación</a>
                    </li>
                  </ul>
                  <ul className="dropdown">
                    <li>
                      <a href="https://medicinafetalmexico.com/prensa">
                        Prensa
                      </a>
                    </li>
                  </ul>

                  <ul className="dropdown">
                    <div
                      className="btn-upperBar"
                      onClick={() => navigate("/contact")}
                    >
                      Contacto
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="NavBar" className={`header ${isSticky ? 'sticky' : ''}`}>
          <div className="header-data">
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
              <img style={{ width: "150px" }} src={process.env.PUBLIC_URL + logo} alt="" />
              <div className="header-contact">
                <div onClick={() => call(contact)}> <i /> <div className="fa fa-phone"></div> {tel} </div>
                <img onClick={() => window.open("https://wa.me/524424739204")} src={process.env.PUBLIC_URL + "/img/whats.png"} />
                <p className="separator"> | </p>
                <a href="https://www.instagram.com/medicinafetalmexico.educacion/" target="_blank">
                  <img src={process.env.PUBLIC_URL + "/img/instagram.jpg"} />
                </a>
                <a href="https://es-la.facebook.com/medicinafetalmexico.educacion/" target="_blank">
                  <img className="social-icon-bar" width="15px" src={process.env.PUBLIC_URL + "/img/facebook.png"} />
                </a>
              </div>
            </div>
            <br></br>
            <div className="header-categories">
              <a href="https://medicinafetalmexico.com/" className="nav-link"> Inicio </a>
              <a href="/courses" className="nav-link"> Cursos </a>
              {/* <a href="/vc/courses/aTL2HT3ibBBFmm0xZ8Z2" className="nav-link"> Congreso Internacional </a> */}
              <a href="/rotations" className="nav-link"> Rotación </a>
              <a href="/investigations" className="nav-link"> Investigación </a>
              <a href="https://www.virtualcampusmfm.com/" className="nav-link" > Campus Virtual </a>
              <a href="https://www.fuve.app/" className="nav-link"> FUVE </a>
              <a href="http://www.orvo.com.mx/" className="nav-link"> ORVO </a>
              <a href="/teachers" className="nav-link"> Profesorado </a>
              <a href="/collaborations" className="nav-link"> Colaboraciones </a>
              <div className="" onClick={() => navigate("/contact")} > {" "}Contacto{" "} </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};