import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Professor } from "../../backend/professors";
import "./styles/SpecialistsGrid.css"

type props = {
    professors: Professor[]
}

function SpecialistsGrid(props: props) {
    const navigate = useNavigate();
    useEffect(() => {
        window.document.title = "Especialistas"
    }, []);
    return (
        <div className="specialist-grid">
            {props.professors.filter(professor => professor.thumbnail && professor.thumbnail !== "").slice(0,8).map(professor => (
                <div className="specialist-grid-container" onClick={() => {navigate("/specialists/" + professor.id)}}>
                    <div className="specialist-grid-circle">
                        <img className="specialist-grid-photo" src={professor.thumbnail} alt="specialist"/>
                    </div>
                    <h2 className="specialist-grid-name">{professor.name}</h2>
                    <div className="specialist-grid-type">{professor.special}</div>
                    <div className="specialist-grid-social"></div>
                </div>
            ))}
        </div>
    );
}

export default SpecialistsGrid;