import { useEffect, useState } from "react";
import { Course } from "../../backend/courses";
import { currencies } from "../../backend/payment";
import PayPalCustomButton from "../paypal/PayPalCustomButton";


function CoursePhotos() {

    return (
        <>
            <br></br><br></br><br></br>
            <img src={process.env.PUBLIC_URL + "/img/orange-circle.png"}/>
        </>
    );
}

export default CoursePhotos;