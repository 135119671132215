import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Course, CourseShort, getCourse, getCourses } from "../backend/courses";
import CourseHQs from "../components/courses/CourseHQs";
import CourseInfo from "../components/courses/CourseInfo";
import CoursePayment from "../components/courses/CoursePayment";
import CourseSuggests from "../components/courses/CourseSuggests";
import CourseSummary from "../components/courses/CourseSummary";
import Loading from "../components/_main/Loading";
import BannerTemplate from "../templates/BannerTemplate";
import './styles/Course.css'

function VC_Course() {
    const { courseid } = useParams();
    const [course, setCourse] = useState<Course>();
    const [otherCourses, setOtherCourses] = useState<CourseShort[]>([]);
    const [done, setDone] = useState(false);
    const [additionalDone, setAdditionalDone] = useState(false);
    const downloadCourse = async (id: string) => {
        const downloadedCourse = await getCourse(id);
        window.document.title = downloadedCourse.title || "Curso Virtual Campus";
        setCourse(downloadedCourse);
        setDone(true);
    }
    const dowloadAdditionalData =async () => {
        setOtherCourses(await getCourses());
        setAdditionalDone(true);
    }
    useEffect(() => {
        if (courseid) {
            if (!done) downloadCourse(courseid);
            if (!additionalDone) dowloadAdditionalData();
        }
    }, [courseid]);
    return (
        <>
            <BannerTemplate src={course?.banner || "/img/banner-page-cursos.jpg"}/>
            {(done) ? (courseid) ? (<>
                <CourseSummary course={course}/>
                <CourseHQs course={course!}/>
                <CourseInfo course={course}/>
                {course && <CoursePayment course={course}/>}
                <CourseSuggests setCourse={setCourse} courses={otherCourses} done={additionalDone} setDone={setDone}/>
            </>) : <></>
            : <Loading/>}
        </>
    );
}

export default VC_Course;