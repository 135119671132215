import axios from "axios";
import { vcAPI } from "./endpoints";
import Firestore from "firebase/firestore";

export async function getCourses() : Promise<CourseShort[]>
{
  const response =
    await axios.get(vcAPI + "/api/courses")
  const courses = response.data;
  return courses;
}

export async function getAllCourses() : Promise<CourseShort[]>
{
  const response = await axios.get(vcAPI + "/api/courses")
  const courses = response.data;
  return courses;
}

export async function getCourse(id: string) : Promise<Course>
{
  const response =
    await axios.get(vcAPI + "/api/courses/" + id)
  const course = response.data;
  return course;
}

export const blockTitleOptions = [
  "Objetivos",
  "Dirigido a",
  "Competencias adquiridas",
  "Sede del curso",
  "Aval universitario",
  "Asistencia al diplomado",
  "Justificación",
  "Título obtenido",
  "Programa educativo",
  "Preinscripción"
]

export type CourseShort = {
  id: string;
  thumbnail: string;
  videoThumbnail: string;
  title: string;
  published: boolean;
  time: number;
  studentCount: number;
  start_date: Timestamp;
  end_date: Timestamp;
};

export type Course = {
  description: string;
  thumbnail: string;
  banner?: string;
  videoThumbnail: string;
  video: string;
  signupForm: string;
  title: string;
  subtitle: string;
  programUrl?: string;
  professors: string[];
  published: boolean;
  price: number;
  currency: number;
  time: number;
  teachersCount: number;
  studentCount: number;
  start_date: Timestamp;
  end_date: Timestamp;
  levels: Level[];
  headquarters?: HeadQuarter[],
  descriptionBlocks: TextBlock[] | undefined
};

interface HeadQuarter {
  name?: string,
  address?: string
  locality: string,
  entity: number,
  country: string,
  location?: string
  attendances: Attendance[]
}

interface Attendance {
  title?: string,
  start: Firestore.Timestamp,
  end: Firestore.Timestamp
}

export type TextBlock = {
  id: number;
  text: string
}

type Timestamp = {
  _seconds: number;
  _nanoseconds: number;
}

export type Level = {
  title: string;
  visible: boolean;
  cases: Case[];
} | null;

export type Case = {
  title: string;
  spawn: number;
} | null;