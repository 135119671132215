import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Course, CourseShort } from "../../backend/courses";
import { scrollToTop } from "../../tools/Scroll";
import Loading from "../_main/Loading";

type props = {
    courses: CourseShort[],
    setCourse: React.Dispatch<React.SetStateAction<Course | undefined>>,
    done: boolean,
    setDone: React.Dispatch<React.SetStateAction<boolean>>
}

function CourseSuggests(props: props) {
    const { courseid } = useParams();
    const navigate = useNavigate();
    const [shuffledCourses, setShuffledCourses] = useState<CourseShort[]>([]);
    useEffect(() => {
        if (props.courses.length > 0 && shuffledCourses.length === 0) setShuffledCourses(props.courses.filter(suggestedCourse => suggestedCourse.published && suggestedCourse.id !== courseid).sort(function(){return .5 - Math.random()}));
    }, [shuffledCourses, props.courses]);
    return (
        <>
            {props.done 
            ? 
                <div className="course-suggests">
                    <div className="suggest-title"> RELACIONADOS </div>
                    <div className="suggest-subtitle"> Otros cursos </div>
                    <div className="suggest-grid-container">
                        {shuffledCourses.slice(0, 6).map((suggestedCourse, index) => (
                            <div key={"suggest-" + index} className="suggest-img-container" onClick={() => {
                                navigate("/vc/courses/" + suggestedCourse.id)
                                props.setDone(false);
                                scrollToTop();
                                setShuffledCourses([]);
                            }}>
                                <img src={suggestedCourse.thumbnail} height="233px"/>
                                <div className="suggest-text-container">{suggestedCourse.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            : <Loading/>
            }
        </>
    );
}

export default CourseSuggests;