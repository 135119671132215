import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProfessors, Professor } from "../backend/professors";
import Specialist from "../components/specialists/Specialist";
import SpecialistsGrid from "../components/specialists/SpecialistsGrid";
import Loading from "../components/_main/Loading";

function Fellowship() {

    return (
        <>
            <section className="introRotacion">
                <div className="fullBgSection fitWidth">
                    <picture>
                        <source src="img/bg-rotacion.webp" type="image/webp" />
                        <source src="img/bg-rotacion.jpg" type="image/jpeg" /><img src="img/bg-rotacion.jpg" width="1200" height="900" alt="" />
                    </picture>
                </div>
                <div className="container">
                    <div className="introRotacionWrap">
                        <div data-wow-delay="0.25s" className="introRotacionLeft extraSpace wow fadeInUp">
                            <div className="introRotacionBoxText">
                                <h2 className="h5">Educación en Medicina Fetal</h2>
                                <ul className="dottedList">
                                    <li>Completar asistencia a todo el programa académico teórico </li>
                                    <li>Masterclass de ecografía fetal en el área de monitores </li>
                                    <li>Journal Club (reuniones para revisión y discusión de Artículos científicos)</li>
                                    <li>Sesión Prospectiva (Presentación de casos clínicos en curso)</li>
                                    <li>Asistencia con beca del 100% a todos los cursos organizados por nuestro Grupo, que tengan lugar durante el periodo de su rotación. </li>
                                </ul>
                            </div>
                            <div className="introRotacionBoxText">
                                <h2 className="h5">Práctica en ecografía fetal</h2>
                                <ul className="dottedList">
                                    <li>Horas de simulación en ecografía fetal </li>
                                    <li>Realización de ecografías fetales de tamizaje de primer, segundo y tercer trimestre </li>
                                    <li>Entrenamiento en Doppler fetal Avanzado</li>
                                </ul>
                            </div>
                            <div className="introRotacionBoxText">
                                <h2 className="h5">Procedimientos invasivos intrauterinos</h2>
                                <p className="justify">Entrenamiento en procedimientos invasivos intrauterinos (amniocentesis, biopsia de vellosidades coriales, cordocentesis) mediante el uso de simuladores quirúrgicos avanzados y posteriormente procedimientos supervisados en embarazadas con riesgo de anomalía cromosómica.</p>
                            </div>
                        </div>
                        <div data-wow-delay="0.5s" className="introRotacionRight wow fadeInUp">
                            <div className="introRotacionBoxText">
                                <h2 className="h3">2- FELLOWSHIP EN MEDICINA FETAL</h2>
                                <p className="justify">El Fellowship en Medicina Fetal tiene duración de 1 año y es una rotación presencial de tiempo completo para Especialistas en Ginecología y Obstetricia y/o Medicina Materno Fetal. Durante su estancia se participará en las siguientes actividades de investigación, educativas y clínicas:</p>
                            </div>
                            <div className="introRotacionMenu">
                                <ul>
                                    <li><a href="/observership">1. Observership</a></li>
                                    <li><a href="/fellowship" className="active">2. Fellowship en medicina fetal</a></li>
                                </ul>
                            </div>
                            <div className="introRotacionBoxText">
                                <h2 className="h5">Investigación</h2>
                                <ul className="dottedList">
                                    <li>Asistencia a la presentación y discusión de nuevos proyectos de Investigación.</li>
                                    <li>Participación en un proyecto de Investigación asignado por su tutor que le permitirá ser coautor de al menos un artículo científico en una revista internacional.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div data-wow-delay="0.25s" className="introRotacionAddInfo wow fadeInUp">
                        <dl>
                            <dt>Duración </dt>
                            <dd>El tiempo de estancia es presencial por 12 meses.</dd>
                        </dl>
                        <dl>
                            <dt>Disponibilidad </dt>
                            <dd>El cupo es limitado a 5 fellows por año.</dd>
                        </dl>
                        <dl>
                            <dt>Diploma </dt>
                            <dd> Se entregará una constancia de formación en Medicina Fetal avalada por nuestra institución</dd>
                        </dl>
                    </div>
                </div>
            </section>
            <section className="formRotacion">
                <form action="" className="formRotacionForm">
                    <div className="formRotacionFirst">
                        <div className="container">
                            <h2 className="h5">Formulario para solicitar rotación</h2>
                            <div className="fsTwoCols">
                                <fieldset>
                                    <input type="text" placeholder="Nombre:" name="name" />
                                </fieldset>
                                <fieldset>
                                    <input type="text" placeholder="Fecha de nacimiento" name="birthday" />
                                </fieldset>
                            </div>
                            <div className="fsTwoCols">
                                <fieldset>
                                    <input type="text" placeholder="Localidad:" name="location" />
                                </fieldset>
                                <fieldset>
                                    <input type="text" placeholder="País" name="country" />
                                </fieldset>
                            </div>
                            <div className="fsTwoCols">
                                <fieldset>
                                    <input type="email" placeholder="Email:" name="email" />
                                </fieldset>
                                <fieldset>
                                    <input type="text" placeholder="Especialidad médica:" name="doctor" />
                                </fieldset>
                            </div>
                            <div className="fsTwoCols">
                                <fieldset>
                                    <input type="text" placeholder="Cargo actual:" name="job" />
                                </fieldset>
                                <fieldset>
                                    <input type="text" placeholder="Centro de trabajo:" name="center" />
                                </fieldset>
                            </div>
                            <div className="fsTwoCols">
                                <fieldset>
                                    <input type="text" placeholder="Periodo de rotación:" name="period" />
                                </fieldset>
                                <fieldset>
                                    <input type="text" placeholder="Duración:" name="duration" />
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="formRotacionSecond">
                        <div className="container">
                            <h2 className="h5 hCenter">Rotación solicitada</h2>
                            <div className="fsRadioGroup">
                                <div className="customRadio">
                                    <input type="radio" name="rotation" />
                                    <div className="crBox">
                                        <div className="crBoxBG">
                                            <picture>
                                                <source src="img/bg-card-box.png" type="image/webp" />
                                                <source src="img/bg-card-box.png" type="image/png" /><img src="img/bg-card-box.png" width="317" height="231" alt="" />
                                            </picture>
                                        </div>
                                        <p><strong>Observership</strong><span>El tiempo mínimo de estancia es de 1 mes, tiempo máximo de 3 meses.</span></p>
                                    </div>
                                </div>
                                <div className="customRadio">
                                    <input type="radio" name="rotation" />
                                    <div className="crBox">
                                        <div className="crBoxBG">
                                            <picture>
                                                <source src="img/bg-card-box.png" type="image/webp" />
                                                <source src="img/bg-card-box.png" type="image/png" /><img src="img/bg-card-box.png" width="317" height="231" alt="" />
                                            </picture>
                                        </div>
                                        <p><strong>Fellowship en medicina fetal</strong><span>El tiempo de estancia es presencial por 12 meses.</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="formRotacionThird">
                        <div className="container">
                            <p className="lighted">Es importante adjuntar la siguiente documentación:</p>
                            <div className="formRotationSend">
                                <div className="uploadFiles">
                                    <div className="uploadFilesList">
                                        <p>Carta de presentación y motivos de solicitud de rotación</p>
                                        <div className="customUpload">
                                            <input type="file" id="upBtn01" />
                                        </div>
                                    </div>
                                    <div className="uploadFilesList">
                                        <p>Curriculum vitae</p>
                                        <div className="customUpload">
                                            <input type="file" id="upBtn02" />
                                        </div>
                                    </div>
                                    <div className="uploadFilesList">
                                        <p>2 Cartas de recomendación</p>
                                        <div className="customUpload">
                                            <input type="file" id="upBtn03" />
                                        </div>
                                    </div>
                                </div>
                                <div className="sendBtn">
                                    <button type="submit" className="btn">Enviar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
}

export default Fellowship;