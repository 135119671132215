import { Course } from "../../backend/courses";
import { useEffect, useState } from "react";


type props = {
    course?: Course;
}

function CourseSummary(props: props) {

    const [image, setImage] = useState(false);
    const [button, setButton] = useState(false);

    useEffect(() => {
        if (
            window.location.href == "https://new.medicinafetalmexico.com/vc/courses/FEat48AooOnuskFGwA85" ||
            window.location.href == "http://localhost:3000/vc/courses/FEat48AooOnuskFGwA85"
        ) {
            setImage(true);
        } else {
            setImage(false);
        }

        if (
            window.location.href == "https://new.medicinafetalmexico.com/vc/courses/aTL2HT3ibBBFmm0xZ8Z2" ||
            window.location.href == "http://localhost:3000/vc/courses/aTL2HT3ibBBFmm0xZ8Z2"
        ) {
            setButton(true);
        } else {
            setButton(false);
        }


    }, [window.location.href]);

    return (
        <div className="course-summary">
            <div className="img-course-container">
                {props.course?.video
                    ?
                    <video className="img-course" autoPlay muted controls>
                        <source src={props.course.video} />
                    </video>
                    :
                    <>
                        {
                            image
                                ?
                                <img className="img-course" src={process.env.PUBLIC_URL + "/img/posgradoo.jpg"} />
                                :
                                <img className="img-course" src={props.course?.thumbnail} />
                        }
                    </>

                }
            </div>
            <div className="course-summary-right">
                <div className="course-title">{props.course?.title}</div>
                <div className="course-description">
                    {props.course?.description}
                    <br></br>
                    {
                        button
                            ?                            
                            <a href="https://drive.google.com/uc?export=download&id=1wVWe57WPvovysNlOR5-YQE1c2XLg2fBm" download="programa">                                
                                <div style={{ backgroundColor: "#4998f4", borderRadius: "35px", maxWidth: "150px", color: "white", fontWeight: "700", padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                Información
                            </div>
                            </a>
                            :
                            <></>
                    }
                </div>

            </div>
        </div>
    );
}

export default CourseSummary;