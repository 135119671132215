import * as React from 'react';

interface MFMProps {
    
}
 
interface MFMState {
    
}
 
class MFM extends React.Component<MFMProps, MFMState> {
    render() { 
        return ( 
            <main className='wrapper'>
                <section className='mainSlider'>
                </section>
            </main>
         );
    }
}
 
export default MFM;