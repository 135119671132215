import Footer from "../components/_main/Footer";
import UpperBar from "../components/_main/UpperBar";

type props = {
    banner?: React.ReactNode;
    children: React.ReactNode
}
function MainTemplate(props: props) {
    return ( 
        <>
            <UpperBar/>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                {props.banner}
                {props.children}
            </div>
            <Footer/>
        </>
     );
}

export default MainTemplate;