import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProfessors, Professor } from "../backend/professors";
import Specialist from "../components/specialists/Specialist";
import SpecialistsGrid from "../components/specialists/SpecialistsGrid";
import Loading from "../components/_main/Loading";

function Rotations() {
    return (
        <>
            <section className="introRotacion">
                <div className="fullBgSection fitWidth">
                    <picture>
                        <source src="img/bg-rotacion.webp" type="image/webp" />
                        <source src="img/bg-rotacion.jpg" type="image/jpeg" /><img src="img/bg-rotacion.jpg" width="1200" height="900" alt="" />
                    </picture>
                </div>
                <div className="container">
                    <div className="introRotacionWrap">
                        <div data-wow-delay="0.25s" className="introRotacionLeft extraSpace wow fadeInUp">
                            <div className="introRotacionBoxText">
                                <h2 className="h3">Criterios de selección</h2>
                                <p>Al recibir la documentación se revisará y aprobará por parte de la Secretara Académica de Medicina Fetal México. </p>
                                <p>Enviar documentos de postulación al correo electrónico: <a href="mailto:educacion@medicinafetalmexico.com" className="textLink">educacion@medicinafetalmexico.com</a></p>
                                <p>Los documentos son revisados y evaluados por la Secretaria Académica. Si la solicitud de rotación es aceptada, se notificará al estudiante mendiante una carta de aceptación.</p>
                                <p>Una vez el estudiante es admitido, la secretaria académica de Medicina fetal México entrará en contacto con el candidato para entregar información relevante de su rotación. En este punto el estudiante, deberá sin falta alguna entregar los siguientes documentos:</p>
                                <ul className="dottedList">
                                    <li>Seguro Médico Internacional que cubra el tiempo de estadía.</li>
                                    <li>Tiquete aéreo con información de llegada al país.</li>
                                    <li>Documentos migratorios</li>
                                </ul>
                            </div>
                        </div>
                        <div data-wow-delay="0.5s" className="introRotacionRight wow fadeInUp">
                            <div className="introRotacionBoxText">
                                <h2 className="h3">Requisitos para postulación:</h2>
                                <ul className="dottedList">
                                    <li>Ser médico especialista en Ecografistas, Radiólogos, especialistas en Ginecología/Obstetricia y/o en Medicina Materno Fetal)</li>
                                    <li>Rellenar el formulario de solicitud</li>
                                    <li>Carta de motivación.</li>
                                    <li>Curriculum vitae </li>
                                    <li>Fotocopia de la página principal del pasaporte (página en la que está la fotografía).</li>
                                </ul>
                            </div>
                            <div className="introRotacionMenu">
                                <ul>
                                    <li><a href="/observership">1. Observership</a></li>
                                    <li><a href="/fellowship">2. Fellowship en medicina fetal</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="formRotacion">
                <form action="" className="formRotacionForm">
                    <div className="formRotacionFirst">
                        <div className="container">
                            <h2 className="h5">Formulario para solicitar rotación</h2>
                            <div className="fsTwoCols">
                                <fieldset>
                                    <input type="text" placeholder="Nombre:" name="name" />
                                </fieldset>
                                <fieldset>
                                    <input type="text" placeholder="Fecha de nacimiento" name="birthday" />
                                </fieldset>
                            </div>
                            <div className="fsTwoCols">
                                <fieldset>
                                    <input type="text" placeholder="Localidad:" name="location" />
                                </fieldset>
                                <fieldset>
                                    <input type="text" placeholder="País" name="country" />
                                </fieldset>
                            </div>
                            <div className="fsTwoCols">
                                <fieldset>
                                    <input type="email" placeholder="Email:" name="email" />
                                </fieldset>
                                <fieldset>
                                    <input type="text" placeholder="Especialidad médica:" name="doctor" />
                                </fieldset>
                            </div>
                            <div className="fsTwoCols">
                                <fieldset>
                                    <input type="text" placeholder="Cargo actual:" name="job" />
                                </fieldset>
                                <fieldset>
                                    <input type="text" placeholder="Centro de trabajo:" name="center" />
                                </fieldset>
                            </div>
                            <div className="fsTwoCols">
                                <fieldset>
                                    <input type="text" placeholder="Periodo de rotación:" name="period" />
                                </fieldset>
                                <fieldset>
                                    <input type="text" placeholder="Duración:" name="duration" />
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="formRotacionSecond">
                        <div className="container">
                            <h2 className="h5 hCenter">Rotación solicitada</h2>
                            <div className="fsRadioGroup">
                                <div className="customRadio">
                                    <input type="radio" name="rotation" />
                                    <div className="crBox">
                                        <div className="crBoxBG">
                                            <picture>
                                                <source src="img/bg-card-box.png" type="image/webp" />
                                                <source src="img/bg-card-box.png" type="image/png" /><img src="img/bg-card-box.png" width="317" height="231" alt="" />
                                            </picture>
                                        </div>
                                        <p><strong>Observership</strong><span>El tiempo mínimo de estancia es de 1 mes, tiempo máximo de 3 meses.</span></p>
                                    </div>
                                </div>

                                <div className="customRadio">
                                    <input type="radio" name="rotation" />
                                    <div className="crBox">
                                        <div className="crBoxBG">
                                            <picture>
                                                <source src="img/bg-card-box.png" type="image/webp" />
                                                <source src="img/bg-card-box.png" type="image/png" /><img src="img/bg-card-box.png" width="317" height="231" alt="" />
                                            </picture>
                                        </div>
                                        <p><strong>Fellowship en medicina fetal</strong><span>El tiempo de estancia es presencial por 12 meses.</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="formRotacionThird">
                        <div className="container">
                            <p className="lighted">Es importante adjuntar la siguiente documentación:</p>
                            <div className="formRotationSend">
                                <div className="uploadFiles">
                                    <div className="uploadFilesList">
                                        <p>Carta de presentación y motivos de solicitud de rotación</p>
                                        <div className="customUpload">
                                            <input type="file"  id="upBtn01" />
                                        </div>
                                    </div>
                                    <div className="uploadFilesList">
                                        <p>Curriculum vitae</p>
                                        <div className="customUpload">
                                            <input type="file"  id="upBtn02"/>
                                        </div>
                                    </div>
                                    <div className="uploadFilesList">
                                        <p>2 Cartas de recomendación</p>
                                        <div className="customUpload">
                                            <input type="file"  id="upBtn03" />
                                        </div>
                                    </div>
                                </div>
                                <div className="sendBtn">
                                    <button type="submit" className="btn">Enviar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
}

export default Rotations;