import axios from "axios";
import { vcAPI } from "./endpoints";

export type Professor = {
    id: string,
    name: string,
    special: string,
    sinapsis: string,
    thumbnail: string
}

export async function getProfessors() : Promise<Professor[]>
{
  const response =
    await axios.get(vcAPI + "/api/professors")
  const professors = response.data;
  return professors;
}