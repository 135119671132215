import { useEffect, useState } from "react";
import { blockTitleOptions, Course, TextBlock } from "../../backend/courses";
import { getTotalCharCount } from "../../tools/StringTools";

type props = {
    course?: Course
}

function CourseBlocks(props: props) {
    const [leftBlocks, setLeftBlocks] = useState<TextBlock[]>([]);
    const [rightBlocks, setRightBlocks] = useState<TextBlock[]>([]);

    useEffect(() => {
        if (props.course){
            const charCount = getTotalCharCount(props.course.descriptionBlocks?.flatMap(block => block.text) || []);
            const tempLeft: TextBlock[] = [];
            const tempRight: TextBlock[] = [];
            if (charCount > 0)
            {
                props.course.descriptionBlocks?.forEach(block => {
                    if (getTotalCharCount(tempLeft.flatMap(block => block.text)) <= getTotalCharCount(tempRight.flatMap(block => block.text))) tempLeft.push(block);
                    else tempRight.push(block);
                })
            }
            setLeftBlocks(tempLeft);
            setRightBlocks(tempRight);
        }
    }, [props.course?.descriptionBlocks]);

    return (
        <div className='details'>
            <div className='details-left'>
                {leftBlocks.map((block, index) => (
                    <div key={"left-block-" + index}>
                        <div key={"left-title-" + index} className="title">
                            {blockTitleOptions[block.id]}
                        </div>
                        <div key={"left-desc-" + index} className='description'>
                            {block.text}
                        </div>
                    </div>
                ))}
            </div>
            <div className='details-right'>
                {rightBlocks.map((block, index) => (
                    <div key={"right-block-" + index}>
                        <div key={"right-title-" + index} className="title">
                            {blockTitleOptions[block.id]}
                        </div>
                        <div key={"right-desc-" + index} className='description'>
                            {block.text}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CourseBlocks;