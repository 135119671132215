import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProfessors, Professor } from "../backend/professors";
import Specialist from "../components/specialists/Specialist";
import SpecialistsGrid from "../components/specialists/SpecialistsGrid";
import Loading from "../components/_main/Loading";

function Collaborations() {
    return (
        <>
            <section className="introSimpleText minWidth noPadBottom">
                <div className="fullBgSection">
                    <picture>
                        <source src="img/bg-carousel-ipregnancy2.webp" type="image/webp" />
                        <source src="img/bg-carousel-ipregnancy2.jpg" type="image/jpeg" /><img src="img/bg-carousel-ipregnancy2.jpg" width="1210" height="782" alt="" />
                    </picture>
                </div>
                <div className="container">
                    <div className="introSimpleTextCont">
                        <h2 data-wow-delay="0.25s" className="h3 hCenter headerComp wow fadeInDown"><span>Cursos de Formación</span><strong>Medicina Fetal México, como parte de su programa docente, ofrece una serie de cursos  online y presenciales de formación básica y avanzada en el área de la Medicina Fetal. </strong></h2>
                        <p data-wow-delay="0.5s" className="wow fadeInUp">Todos nuestros cursos se orientan hacia un enfoque clínico: partiendo de una base teórica se sigue un guión lógico para encadenar conceptos y entender la sistemática de exploración y de clasificación de los posibles defectos.</p>
                        <p data-wow-delay="0.5s" className="wow fadeInUp">Además de los cursos tienen un componente intensivo de casos prácticos con autoevaluación para que los alumnos  consoliden conceptos y su capacidad para identificar imágenes hasta el nivel marcado en los objetivos. Nuestra oferta educativa va dirigida a ginecoobstetras, médicos materno fetales, radiologos médicos ultrasonografistas, residentes de ginecoobstetricia, residentes de medicina materno fetal y todo aquel interesado en formarse y actualizarse en la Medicina Materno fetal.</p>
                    </div>
                    <div className="threeBoxMenu">
                        <div className="threeBoxMenuWrap"><a href="/courses" data-wow-delay="0.25s" className="threeBox wow fadeInUp">
                            <div className="threeBoxBG">
                                <picture>
                                    <source src="img/bg-card-box.png" type="image/webp" />
                                    <source src="img/bg-card-box.png" type="image/png" /><img src="img/bg-card-box.png" width="317" height="231" alt="" />
                                </picture>
                            </div>
                            <div className="threeBoxText">
                                <div className="threeBoxTextTitle"><i className="icon-mfm10"></i>
                                    <h3 className="h5">CURSOS, DIPLOMADOS Y POSGRADOS</h3>
                                </div>
                            </div></a><a href="/collaborations" data-wow-delay="0.45s" className="threeBox wow fadeInUp">
                                <div className="threeBoxBG">
                                    <picture>
                                        <source src="img/bg-card-box.png" type="image/webp" />
                                        <source src="img/bg-card-box.png" type="image/png" /><img src="img/bg-card-box.png" width="317" height="231" alt="" />
                                    </picture>
                                </div>
                                <div className="threeBoxText">
                                    <div className="threeBoxTextTitle"><i className="icon-mfm13"></i>
                                        <h3 className="h5">COLABORACIONES ACADÉMICAS</h3>
                                    </div>
                                </div></a><a href="/rotations" data-wow-delay="0.65s" className="threeBox wow fadeInUp">
                                <div className="threeBoxBG">
                                    <picture>
                                        <source src="img/bg-card-box.png" type="image/webp" />
                                        <source src="img/bg-card-box.png" type="image/png" /><img src="img/bg-card-box.png" width="317" height="231" alt="" />
                                    </picture>
                                </div>
                                <div className="threeBoxText">
                                    <div className="threeBoxTextTitle"><i className="icon-mfm11"></i>
                                        <h3 className="h5">ESTANCIAS DE ROTACIÓN</h3>
                                    </div>
                                </div></a><a href="/investigations" data-wow-delay="0.65s" className="threeBox wow fadeInUp">
                                <div className="threeBoxBG">
                                    <picture>
                                        <source src="img/bg-card-box.png" type="image/webp" />
                                        <source src="img/bg-card-box.png" type="image/png" /><img src="img/bg-card-box.png" width="317" height="231" alt="" />
                                    </picture>
                                </div>
                                <div className="threeBoxText">
                                    <div className="threeBoxTextTitle"><i className="icon-mfm92"></i>
                                        <h3 className="h5">INVESTIGACIÓN</h3>
                                    </div>
                                </div></a></div>
                    </div>
                </div>
            </section>            
            <section className="webApp light">
                <div className="container">
                    <div className="webAppWrap">
                        <div className="webAppLeft">
                            <h2 data-wow-delay="0.25s" className="h3 headerComp wow fadeInDown"><span>Visita nuestra web de</span><strong>Campus Virtual</strong></h2>
                            <p data-wow-delay="0.5s" className="wow fadeInUp">Adaptando nuestros métodos de enseñanza, logramos posicionar nuestros cursos en una nueva plataforma de Campus Virtual diseñada principalmente para la educación continua a distancia. Prevenir, detectar y corregir posibles Afecciones.</p><a href="https://www.virtualcampusmfm.com/" target="_blank" data-wow-delay="0.75s" className="btn wow fadeInUp">Da click para ingresar</a>
                        </div>
                        <div data-wow-delay="0.25s" className="webAppRight wow fadeIn">
                            <div className="webAppImg">
                                <picture>
                                    <source src="img/img-campus-virtual.webp" type="image/webp" />
                                    <source src="img/img-campus-virtual.jpg" type="image/png" /><img src="img/img-campus-virtual.jpg" width="800" height="416" alt="Web App" />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="fuve" className="downloadApp">
                <div className="fullBgSection">
                    <picture>
                        <source src="img/bg-download-app.webp" type="image/webp" />
                        <source src="img/bg-download-app.jpg" type="image/jpeg" /><img src="img/bg-download-app.jpg" width="1951" height="565" alt="Ventajas y Beneficios" />
                    </picture>
                </div>
                <div className="container">
                    <div className="downAppWrap">
                        <div className="downAppImg">
                            <picture>
                                <source src="img/img-phone-app2.webp" type="image/webp" />
                                <source src="img/img-phone-app2.png" type="image/png" /><img src="img/img-phone-app2.png" width="278" height="451" alt="" />
                            </picture>
                        </div>
                        <div className="downAppText">
                            <h2 data-wow-delay="0.25s" className="headerComp wow fadeInDown"><span>&nbsp;</span><strong>FUVE APP</strong>
                            </h2>
                            <p data-wow-delay="0.25s" className="wow fadeInUp">FUVE es una nueva herramienta que permitirá reducir la curva de aprendizaje maximizando tus conocimientos en ultrasonido y emitir así un diagnostico preciso para tus pacientes.</p>
                            <ul>
                            <a href="https://www.fuve.app/" target="_blank" data-wow-delay="0.75s" className="btn wow fadeInUp"
                                    style={{ "visibility": "visible", "animationDelay": "0.75s", "animationName": "fadeInUp"}}>FUVE</a>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Collaborations;