import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProfessors, Professor } from "../backend/professors";
import Specialist from "../components/specialists/Specialist";
import SpecialistsGrid from "../components/specialists/SpecialistsGrid";
import Loading from "../components/_main/Loading";

function Information() {
    return (
        <div style={{ "display": "flex", "gap": "15px", "textAlign": "left" }}>
            <section className="pageForm">
                <div className="container">
                    <div className="pageFormText">
                        <div data-wow-delay="0.25s" className="pageFormTextIntro wow fadeInDown">
                            <p>En Educación Medicina Fetal México estamos para convertir a nuestros doctores en los expertos del futuro. Mándanos un correo si estás interesado en aprender con nosotros o estamos también para resolver tus dudas con respecto a nuestros cursos.</p>
                        </div>
                        <br></br>
                        <div className="pageFormTextForm">
                            <h2 data-wow-delay="0.5s" className="h4 wow fadeInDown">Envíanos un mensaje</h2>
                            <p data-wow-delay="0.5s" className="wow fadeInUp">Un especialista se pondrá en contacto contigo.</p>
                            <br></br>
                            <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "gap": "15px"}}>
                                <div className="btn" style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }}><a href="mailto:educacion@medicinafetalmexico.com?Subject=Nueva%20Pregunta">Enviar Correo</a></div>
                                <div className="btn" style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }}><a href="tel:3328152200">LLamar vía teléfono</a></div>
                                <div className="btn" style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }}><a href="https://api.whatsapp.com/send/?phone=3328152200">Enviar Whatsapp</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Information;