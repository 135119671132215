import { useEffect, useRef, useState } from "react";
import * as React from "react";
import MarkerClusterer from "@googlemaps/markerclustererplus";

interface MapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
}

const GoogleMap: React.FC<MapProps> = ({
    onClick,
    onIdle,
    style,
    ...options
  }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();

    const locations = [
        { lat: 19.668298750244656, lng: -101.166051},
        { lat: 20.56570332168854, lng: -100.41266553862377 },
        { lat: 20.666032088815534, lng: -103.39668514232798 }
    ];
    
    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {center: { lat: 19.668298750244656, lng: -101.166051},
                zoom: 7}));
        }
    }, [ref, map]);

    useEffect(() => {
        if (map) {
          ["click", "idle"].forEach((eventName) =>
            google.maps.event.clearListeners(map, eventName)
          );
      
          if (onClick) {
            map.addListener("click", onClick);
          }
      
          if (onIdle) {
            map.addListener("idle", () => onIdle(map));
          }
        }
    }, [map, onClick, onIdle]);
    
    const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const markers = locations.map((location, i) => {
        return new google.maps.Marker({
        position: location,
        label: labels[i % labels.length]
        });
    });

    if (map) new MarkerClusterer(map, markers, {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
    });

    return <div ref={ref} style={style} />;
  }

export default GoogleMap;